class token {
  constructor(obj) {
    if (!obj) obj = {};
    this.id = obj.id ? obj.id : undefined;
    this.token = obj.token ? obj.token : '';
    this.userName = obj.userName ? obj.userName : '';
    this.email = obj.email ? obj.email : '';
    this.displayName = obj.displayName ? obj.displayName : '';
    this.needChangePassword = obj.needChangePassword;
    this.email = obj.email ? obj.email : '';
    this.roleId = obj.roleId ? obj.roleId : undefined;
    this.entityTypeId = obj.entityTypeId ? obj.entityTypeId : undefined;
    this.companyLocationId = obj.companyLocationId
      ? obj.companyLocationId
      : undefined;
  }
}

export default token;
