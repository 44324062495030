import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    deparmentListRequest: [],
    deparmentListSuccess: ['deparments'],
    deparmentListError: ['error'],
    setLoading: ['isLoading']
  },
  {}
);

export const DeparmentTypes = Types;
export const DeparmentCreators = Creators;

/**
 * Redux
 */
// the initial deparment of this reducer
export const INITIAL_STATE = Immutable({
  deparments: [],
  isLoading: false
});

export const onDeparmentListSuccess = (state, { deparments }) =>
  state.merge({ deparments });
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });

//map our actions types to our reducer functions
export const HANDLERS = {
  [DeparmentTypes.DEPARMENT_LIST_SUCCESS]: onDeparmentListSuccess,
  [DeparmentTypes.SET_LOADING]: onSetLoading
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectDeparments = state =>
  Immutable.asMutable(state.deparmentReducer.deparments, { deep: true });
export const selectDeparmentsOptions = state =>
  Immutable.asMutable(
    state.deparmentReducer.deparments.filter(x => x.isActive),
    {
      deep: true
    }
  );
