import Api from './Api';

class ChartWorkItemService extends Api {
  constructor(controller) {
    super(controller);
  }

  getChartWorkItemCategories = () => {
    return this.API.get(`${this.controller}/getchartworkitemcategories`);
  };
  getChartWorkItemDepartments = () => {
    return this.API.get(`${this.controller}/getchartworkitemdepartments`);
  };
  getChartWorkItemLocations = () => {
    return this.API.get(`${this.controller}/getchartworkitemlocations`);
  };
}

let service = new ChartWorkItemService('chartworkitem');

export default service;
