import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    setLoading: ['isLoading'],
    priorityLevelGetRequest: ['id'],
    priorityLevelGetSuccess: ['items'],
    priorityLevelGetFailure: ['error'],
    priorityLevelCreateRequest: ['items'],
    priorityLevelCreateSuccess: ['messageOperation'],
    priorityLevelCreateFailure: ['error'],
    priorityLevelUpdateRequest: ['items'],
    priorityLevelUpdateSuccess: ['items', 'messageOperation'],
    priorityLevelUpdateFailure: ['error'],
    priorityLevelSetMessage: ['messageOperation']
  },
  {}
);

export const PriorityLevelTypes = Types;
export const PriorityLevelCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  priorityLevels: [],
  isLoading: false,
  messageOperation: null
});

export const onPriorityLevelGetSuccess = (state, { items }) =>
  state.merge({ priorityLevels: items });
export const onPriorityLevelCreateSuccess = (state, { messageOperation }) =>
  state.merge({ messageOperation });
export const onPriorityLevelUpdateSuccess = (
  state,
  { items, messageOperation }
) => state.merge({ priorityLevels: items, messageOperation });
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });
export const onPriorityLevelSetMessage = (state, { messageOperation }) =>
  state.merge({ messageOperation });

//map our actions types to our reducer functions
export const HANDLERS = {
  [PriorityLevelTypes.PRIORITY_LEVEL_GET_SUCCESS]: onPriorityLevelGetSuccess,
  [PriorityLevelTypes.PRIORITY_LEVEL_CREATE_SUCCESS]: onPriorityLevelCreateSuccess,
  [PriorityLevelTypes.PRIORITY_LEVEL_UPDATE_SUCCESS]: onPriorityLevelUpdateSuccess,
  [PriorityLevelTypes.SET_LOADING]: onSetLoading,
  [PriorityLevelTypes.PRIORITY_LEVEL_SET_MESSAGE]: onPriorityLevelSetMessage
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectIsLoading = state => state.priorityLevelReducer.isLoading;
export const selectMessageOperation = state =>
  state.priorityLevelReducer.messageOperation;

export const selectSubcategoryPriorityLevels = state => {
  let priorities = Immutable.asMutable(state.priorityReducer.priorities, {
    deep: true
  });
  let priorityLevels = priorities.map(item => ({
    id: 0,
    subCategoryId: 0,
    priorityId: item.id,
    code: item.code,
    responseTime: 0,
    responseTimeSetting: 0,
    isActive: true
  }));

  let foundItem;
  if (state.priorityLevelReducer.priorityLevels)
    state.priorityLevelReducer.priorityLevels.forEach(item => {
      foundItem = priorityLevels.find(x => x.priorityId === item.priorityId);
      if (foundItem) foundItem.id = item.id;
      foundItem.subCategoryId = item.subCategoryId;
      foundItem.responseTimeSetting = item.responseTimeSetting;
      foundItem.responseTimeTypeId = item.responseTimeTypeId;
      foundItem.isActive = true;
    });

  return priorityLevels;
};
