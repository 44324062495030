import API from './Api';

class BusinessFunctionsService extends API {
	constructor(controller) {
		super(controller);
	}

	getAssignedRoles = (businessFunctionId) => {
		return this.API.get(`business-functions/${businessFunctionId}/roles`);
	}

	getAssignedBusinessFunctions = (roleId) => {
		return this.API.get(`roles/${roleId}/business-functions`);
	}

	postBusinessFunctionRoles = (model) => {
		return this.API.post(`${this.controller}/massive`, model);
	}
}

let service = new BusinessFunctionsService('businessFunctionRoles');

export default service;
