import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    setLoading: ['isLoading'],
    subCategoryAttachmentConfigGetByAttachmentConfigIdRequest: [
      'attachmentConfigId'
    ],
    subCategoryAttachmentConfigGetByAttachmentConfigIdSuccess: [
      'subCategoryAttachmentConfigs',
      'messageOperation'
    ],
    subCategoryAttachmentConfigGetByAttachmentConfigIdFailure: [
      'messageOperation'
    ]
  },
  {}
);

export const subCategoryAttachmentConfigTypes = Types;
export const subCategoryAttachmentConfigCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  subCategoryAttachmentConfigs: [],
  isLoading: false,
  messageOperation: null
});

export const onSubCategoryAttachmentConfigGetByAttachmentConfigId = (
  state,
  { subCategoryAttachmentConfigs }
) => state.merge({ subCategoryAttachmentConfigs });
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });

// // export const onsubCategoryAttachmentConfigCreate = (
// //   state,
// //   { messageOperation }
// // ) => state.merge({ messageOperation: messageOperation });
// // export const onsubCategoryAttachmentConfigGetByOwnerId = (
// //   state,
// //   { ownersubCategoryAttachmentConfigs, ownerId }
// // ) => state.merge({ ownersubCategoryAttachmentConfigs, ownerId });

//map our actions types to our reducer functions
export const HANDLERS = {
  // [subCategoryAttachmentConfigTypes.SUB_CATEGORY_LOCATION_CREATE_SUCCESS]: onsubCategoryAttachmentConfigCreate,
  // [subCategoryAttachmentConfigTypes.SUB_CATEGORY_LOCATION_GET_BY_OWNER_ID_SUCCESS]: onsubCategoryAttachmentConfigGetByOwnerId,
  [subCategoryAttachmentConfigTypes.SUB_CATEGORY_ATTACHMENT_CONFIG_GET_BY_ATTACHMENT_CONFIG_ID_SUCCESS]: onSubCategoryAttachmentConfigGetByAttachmentConfigId,
  [subCategoryAttachmentConfigTypes.SET_LOADING]: onSetLoading
  // [subCategoryAttachmentConfigTypes.SET_OWNER_LOADING]: onSetOwnerLoading
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectLoading = state =>
  state.subCategoryAttachmentConfigReducer.isLoading;
export const selectSubCategoryAttachmentConfigs = state => {
  const {
    subCategoryAttachmentConfigs
  } = state.subCategoryAttachmentConfigReducer;
  let subCategories = Immutable.asMutable(
    state.subCategoryReducer.subCategories,
    { deep: true }
  ).map(item => ({ ...item, isActive: false }));

  let foundItem;
  if (subCategoryAttachmentConfigs)
    subCategoryAttachmentConfigs.forEach(item => {
      foundItem = subCategories.find(x => x.id == item.subCategoryId);
      foundItem.isActive = true;
    });
  return subCategories;
};

// export const selectOwnerLoading = state =>
//   state.subCategoryAttachmentConfigReducer.isOwnerLoading;
// export const selectOwnerId = state =>
//   state.subCategoryAttachmentConfigReducer.ownerId;

// export const selectMessageOperation = state =>
//   state.subCategoryAttachmentConfigReducer.messageOperation;
// export const selectsubCategoryAttachmentConfigs = state =>
//   Immutable.asMutable(
//     state.subCategoryAttachmentConfigReducer.subCategoryAttachmentConfigs || [],
//     { deep: true }
//   );
