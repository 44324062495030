import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions({
	setLoading: ["isLoading"],
    subCategoryListRequest: ["id"],
    subCategoryListSuccess: ['subCategories'],
    subCategoryListFailure: ['error'],
	allSubCategoriesRequest: [],
	responsibleSubCategoriesRequest: [],
	allSubCategoriesSuccess: ['subCategories'],	
	subCategoryGetByCategoryIdRequest: ["id"],
	subCategoryGetByCategoryIdSuccess: ['subCategories'],
	subCategoryGetByCategoryIdFailure: ['error'],
	subCategoryGetRequest: ['id'],
	subCategoryGetSuccess: ['subCategory'],
	subCategoryGetFailure: ['error'],
	subCategoryCreateRequest: ['item'],
	subCategoryCreateSuccess: ['item','messageOperation'],
	subCategoryCreateFailure: ['error'],
	subCategoryUpdateRequest: ['item'],
	subCategoryUpdateSuccess: ['item','messageOperation'],
	subCategoryUpdateFailure: ['error'],
	subCategoryDeleteRequest: ['id'],
	subCategoryDeleteSuccess: ['id','messageOperation'],
	subCategoryDeleteFailure: ['error'],
    subCategorySetMessage: ['messageOperation']
}, {});

export const SubCategoryTypes = Types;
export const SubCategoryCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
	subCategory: null,
	subCategories: [],
	isLoading: false,
	messageOperation: null
});

export const onSubCategoriesGetSuccess = (state, { subCategory }) => state.merge({ subCategory });
export const onSubCategoriesListSuccess = (state, { subCategories }) => state.merge({ subCategories });
export const onSubCategoryCreateSuccess = (state, { item, messageOperation }) => state.merge({ subCategories: state.subCategories.concat(item), subCategory: item, messageOperation });
export const onSubCategoryUpdateSuccess = (state, { item, messageOperation }) => state.merge({ subCategories: state.subCategories.map(i => i.id === item.id ? item : i), subCategory: item, messageOperation });
export const onSubCategoryDeleteSuccess = (state, { id, messageOperation }) => state.merge({ subCategories: state.subCategories.filter(x => x.id != id), messageOperation });
export const onSetLoading = (state, { isLoading }) => state.merge({ isLoading });
export const onSubCategorySetMessage = (state, { messageOperation }) => state.merge({ messageOperation });

//map our actions types to our reducer functions
export const HANDLERS = {
	[SubCategoryTypes.ALL_SUB_CATEGORIES_SUCCESS]: onSubCategoriesListSuccess,
	[SubCategoryTypes.SUB_CATEGORY_GET_SUCCESS]: onSubCategoriesGetSuccess,
	[SubCategoryTypes.SUB_CATEGORY_GET_BY_CATEGORY_ID_SUCCESS]: onSubCategoriesListSuccess,
	[SubCategoryTypes.SUB_CATEGORY_LIST_SUCCESS]: onSubCategoriesListSuccess,
	[SubCategoryTypes.SUB_CATEGORY_CREATE_SUCCESS]: onSubCategoryCreateSuccess,
	[SubCategoryTypes.SUB_CATEGORY_UPDATE_SUCCESS]: onSubCategoryUpdateSuccess,
	[SubCategoryTypes.SUB_CATEGORY_DELETE_SUCCESS]: onSubCategoryDeleteSuccess,
	[SubCategoryTypes.SET_LOADING]: onSetLoading,
    [SubCategoryTypes.SUB_CATEGORY_SET_MESSAGE]: onSubCategorySetMessage
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectIsLoading = state => state.subCategoryReducer.isLoading;
export const selectSubCategory = state => state.subCategoryReducer.subCategory;
export const selectSubCategories = state => Immutable.asMutable(state.subCategoryReducer.subCategories, { deep: true });
export const selectMessageOperation = state => state.subCategoryReducer.messageOperation;