import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions({
    setLoading: ["isLoading"],
    setOwnerLoading: ["isOwnerLoading"],
    subCategoryLocationCreateRequest: ['items'],
    subCategoryLocationCreateSuccess: ['messageOperation'],
    subCategoryLocationCreateFailure: ['error'],
    subCategoryLocationGetBySubCategoryIdRequest: ['subCategoryId'],
    subCategoryLocationGetBySubCategoryIdSuccess: ['subCategoryLocations'],
    subCategoryLocationGetBySubCategoryIdFailure: ['error'],
    subCategoryLocationGetByOwnerIdRequest: ['subCategoryId', 'ownerId'],
    subCategoryLocationGetByOwnerIdSuccess: ['ownerSubCategoryLocations', 'ownerId'],
    subCategoryLocationGetByOwnerIdFailure: ['error'],
    subCategoryLocationCatalogsRequest: []
}, {});

export const SubCategoryLocationTypes = Types;
export const SubCategoryLocationCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
    subCategories: [],
    ownerSubCategoryLocations: [],
    subCategoryLocations: [],
    subCategoryLocationOwnerId: null,
    isLoading: false,
    isOwnerLoading: false
});

export const onSubCategoryLocationCreate = (state, { messageOperation }) => state.merge({ messageOperation: messageOperation });
export const onSubCategoryLocationGetByOwnerId = (state, { ownerSubCategoryLocations, ownerId }) => state.merge({ ownerSubCategoryLocations, ownerId });
export const onSubCategoryLocationGetBySubCategoryId = (state, { subCategoryLocations }) => state.merge({ subCategoryLocations });
export const onSetLoading = (state, { isLoading }) => state.merge({ isLoading });
export const onSetOwnerLoading = (state, { isOwnerLoading }) => state.merge({ isOwnerLoading });

//map our actions types to our reducer functions
export const HANDLERS = {
    [SubCategoryLocationTypes.SUB_CATEGORY_LOCATION_CREATE_SUCCESS]: onSubCategoryLocationCreate,
    [SubCategoryLocationTypes.SUB_CATEGORY_LOCATION_GET_BY_OWNER_ID_SUCCESS]: onSubCategoryLocationGetByOwnerId,
    [SubCategoryLocationTypes.SUB_CATEGORY_LOCATION_GET_BY_SUB_CATEGORY_ID_SUCCESS]: onSubCategoryLocationGetBySubCategoryId,
    [SubCategoryLocationTypes.SET_LOADING]: onSetLoading,
    [SubCategoryLocationTypes.SET_OWNER_LOADING]: onSetOwnerLoading,
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectLoading = state => state.subCategoryLocationReducer.isLoading;
export const selectOwnerLoading = state => state.subCategoryLocationReducer.isOwnerLoading;
export const selectOwnerId = state => state.subCategoryLocationReducer.ownerId;
export const selectOwnerSubCategoryLocations = state => {
    let companyLocations = Immutable.asMutable(state.companyLocationReducer.companyLocations, { deep: true });
    companyLocations = companyLocations.map(item => ({ ...item, isActive: false }));

    let foundItem;
    if (state.subCategoryLocationReducer.ownerSubCategoryLocations)
        state.subCategoryLocationReducer.ownerSubCategoryLocations.forEach(item => {
            foundItem = companyLocations.find(x => x.id == item.companyLocationId);
            foundItem.isActive = true;
        });

    return companyLocations;
};
export const selectMessageOperation = state => state.subCategoryLocationReducer.messageOperation;
export const selectSubCategoryLocations = state => Immutable.asMutable(state.subCategoryLocationReducer.subCategoryLocations || [], { deep: true });
