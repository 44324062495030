export default theme => ({
    root: {
        // paddingTop: theme.spacing.unit * 1,
        // padding: theme.spacing.unit * 3
    },
    content: {
        marginTop: theme.spacing.unit * 3
    },
    row: {

    },
    columnGrid: {
        height: '500px'
    },
    button: {
        margin: theme.spacing.unit,
    },
    input: {
        display: 'none',
    },
    first: {
        borderRight: '1px solid #eee',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    second: {
        paddingLeft: '20px',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    item: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit
    },
    giantIcon: {
        width: '70px',
        height: '70px'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
});
