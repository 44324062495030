import API from "./Api";

class CategoriesService extends API {
    constructor(controller){
        super(controller);
    }
}

let service = new CategoriesService("categories");

export default service;