import API from "./Api";

class SubCategoriesService extends API {

    constructor(controller){
        super(controller);
    }

    getByCategoryId = (id) => {
        return this.API.get(`categories/${id}/subcategories`);
    }

    getByResponsible = () => {
        return this.API.get(`subcategories/responsible`);
    }

    getSimpleByCategoryId = (id) => {
        return this.API.get(`categories/${id}/subcategories/all`);
    }
}

let service = new SubCategoriesService("subcategories");

export default service;