import { takeLatest, call, put, all } from "redux-saga/effects";
import { ResponseTimeTypes, ResponseTimeTypesCreators } from "redux/ResponseTimeTypeRedux";
import responseTimeTypeService from "services/responseTimeTypeService";
import ResponseTimeType from "contract/responses/ResponseTimeType";

export function* getAll(showLoading) {
    if (showLoading)
	yield put(ResponseTimeTypesCreators.setLoading(true));

	const response = yield call(responseTimeTypeService.getAll);

	if (response.ok) {
		yield put(ResponseTimeTypesCreators.responseTimeTypeListSuccess(response.data.data.map(item => new ResponseTimeType(item))));
	} else {
		yield put(ResponseTimeTypesCreators.responseTimeTypeListFailure(response.problem));
	}

    if (showLoading)
	yield put(ResponseTimeTypesCreators.setLoading(false));
}

/**
 * sagas 
 */
function* citySagas() {
	yield all([
		takeLatest(ResponseTimeTypes.RESPONSE_TIME_TYPE_LIST_REQUEST   , getAll)
	]);
}

export default citySagas;