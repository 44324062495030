import API from './Api';

class DriversService extends API {
    constructor(controller) {
        super(controller);
    }

    postReturn = model => {
        return this.API.post(`${this.controller}/return`, model);
    };

    getAllUsers = () => {
        return this.API.get(`${this.controller}/users`);
    }
}

let service = new DriversService('drivers');

export default service;
