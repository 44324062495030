import { takeLatest, call, put, all } from 'redux-saga/effects';
import { RoleTypes, RoleCreators } from '../redux/RoleRedux';
import rolesService from '../services/rolesService';
import { MessageOperation } from '../constants';

export function* getAll(showLoading) {
  if (showLoading) yield put(RoleCreators.setLoading(true));

  const response = yield call(rolesService.getAll);

  if (response.ok) {
    yield put(RoleCreators.getRolesSuccessfull(response.data.data));
  } else {
    yield put(RoleCreators.getRolesError(response.problem));
  }

  if (showLoading) yield put(RoleCreators.setLoading(false));
}

export function* create({ role }) {
  yield put(RoleCreators.setLoading(true));

  const response = yield call(rolesService.create, role);

  if (response.ok) {
    yield put(
      RoleCreators.createRoleOK(response.data.data, MessageOperation.success)
    );
  } else {
    yield put(RoleCreators.createRoleOK(MessageOperation.error));
  }

  yield put(RoleCreators.setLoading(false));
}

export function* update({ role }) {
  yield put(RoleCreators.setLoading(true));

  const response = yield call(rolesService.update, role);

  if (response.ok) {
    yield put(RoleCreators.updateRoleOK(role, MessageOperation.success));
  } else {
    yield put(RoleCreators.updateRoleOK(MessageOperation.error));
  }

  yield put(RoleCreators.setLoading(false));
}

export function* deleteItem({ id }) {
  yield put(RoleCreators.setLoading(true));

  const response = yield call(rolesService.delete, id);

  if (response.ok) {
    yield put(RoleCreators.deleteRoleOK(id, MessageOperation.success));
  } else {
    if (response.status == 409) {
      yield put(RoleCreators.rolesSetMessage(MessageOperation.conflict));
    } else {
      yield put(RoleCreators.rolesSetMessage(MessageOperation.error));
    }
  }

  yield put(RoleCreators.setLoading(false));
}

/**
 * RolesSagas
 */
function* rolesSagas() {
  yield all([
    takeLatest(RoleTypes.GET_ROLES_REQUEST, getAll),
    takeLatest(RoleTypes.CREATE_ROLE_REQUEST, create),
    takeLatest(RoleTypes.UPDATE_ROLE_REQUEST, update),
    takeLatest(RoleTypes.DELETE_ROLE_REQUEST, deleteItem)
  ]);
}

export default rolesSagas;
