import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
  ChartWorkItemTypes,
  ChartWorkItemCreators
} from '../redux/ChartWorkItemRedux';
import chartWorkItemService from '../services/chartWorkItemService';

export function* getChartWorkItemCategories(displayLoading = false) {
  if (displayLoading) yield put(ChartWorkItemCreators.setLoading(true));

  const response = yield call(chartWorkItemService.getChartWorkItemCategories);

  if (response.ok) {
    yield put(
      ChartWorkItemCreators.chartWorkItemCategoriesSuccess(response.data.data)
    );
  } else {
    yield put(
      ChartWorkItemCreators.chartWorkItemCategoriesError(response.problem)
    );
  }

  if (displayLoading) yield put(ChartWorkItemCreators.setLoading(false));
}

export function* getChartWorkItemDepartments(displayLoading = false) {
  if (displayLoading) yield put(ChartWorkItemCreators.setLoading(true));

  const response = yield call(chartWorkItemService.getChartWorkItemDepartments);

  if (response.ok) {
    yield put(
      ChartWorkItemCreators.chartWorkItemDepartmentsSuccess(response.data.data)
    );
  } else {
    yield put(
      ChartWorkItemCreators.chartWorkItemDepartmentsError(response.problem)
    );
  }

  if (displayLoading) yield put(ChartWorkItemCreators.setLoading(false));
}

export function* getChartWorkItemLocations(displayLoading = false) {
  if (displayLoading) yield put(ChartWorkItemCreators.setLoading(true));

  const response = yield call(chartWorkItemService.getChartWorkItemLocations);

  if (response.ok) {
    yield put(
      ChartWorkItemCreators.chartWorkItemLocationsSuccess(response.data.data)
    );
  } else {
    yield put(
      ChartWorkItemCreators.chartWorkItemLocationsError(response.problem)
    );
  }

  if (displayLoading) yield put(ChartWorkItemCreators.setLoading(false));
}

export function* getChartsWorkItemsData() {
  yield* getChartWorkItemCategories();
  yield* getChartWorkItemDepartments();
  yield* getChartWorkItemLocations();
}

/**
 * sagas
 */
function* chartWorkItemSagas() {
  yield all([
    takeLatest(
      ChartWorkItemTypes.CHART_WORK_ITEM_DATA_REQUEST,
      getChartsWorkItemsData
    ),
    takeLatest(
      ChartWorkItemTypes.CHART_WORK_ITEM_CATEGORIES_REQUEST,
      getChartWorkItemCategories
    ),
    takeLatest(
      ChartWorkItemTypes.CHART_WORK_ITEM_DEPARTMENTS_REQUEST,
      getChartWorkItemDepartments
    ),
    takeLatest(
      ChartWorkItemTypes.CHART_WORK_ITEM_LOCATIONS_REQUEST,
      getChartWorkItemLocations
    )
  ]);
}

export default chartWorkItemSagas;
