import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

export const mapStateToProps = () => ({
});

export const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );

export const hocConector = WrappedComponent => {
    const hoc = ({ ...props }) => <WrappedComponent {...props} />;

    hoc.propTypes = {
    };

    return hoc;
};

export default Component =>
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(hocConector(Component));
