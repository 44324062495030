import API from './Api';

class TaskService extends API {
  constructor(controller) {
    super(controller);
  }

  getByTaskTypeId = taskTypeId => {
    return this.API.get(`${this.controller}/tasktype/${taskTypeId}`);
  };

  getBySubCategoryId = id => {
    return this.API.get(`${this.controller}/subcategory/${id}`);
  };

  getByCategoryId = ({ id, categoryId }) => {
    return this.API.get(`${this.controller}/${id}/category/${categoryId}`);
  };

  saveMultiple = taskSubcategory => {
    return this.API.post(`${this.controller}/savemultiple`, taskSubcategory);
  };
}

let service = new TaskService('tasks');

export default service;
