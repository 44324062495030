import Api from "./Api";

class StateService extends Api {
    
    constructor(controller){
        super(controller);
    }
    
}

let service = new StateService("states");

export default service;