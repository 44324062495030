import API from './Api';

class CfdiService extends API {
    constructor(controller) {
        super(controller);
    }

    signDelivery = id => {
        return this.API.post(`${this.controller}/${id}`);
    };
}

let service = new CfdiService('cfdi');

export default service;
