import EntityBase from "../EntityBase";
import Address from "./Address";

class CompanyLocation extends EntityBase {
	constructor(obj) {
		if (!obj) obj = {};
		super(obj);
		this.companyId = obj.companyId ? obj.companyId : undefined;
		this.code = obj.code ? obj.code : "";
		this.detail = obj.detail ? obj.detail : "";
		this.ownerId = obj.ownerId ? obj.ownerId : undefined;
		this.addressId = obj.addressId ? obj.addressId : undefined;
		this.address = obj.address ? new Address(obj.address) : undefined;
		this.ownerName = obj.ownerName ? obj.ownerName : "";
	}
}

export default CompanyLocation;