import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions({
	cityListRequest: [],
	cityListSuccess: ['cities'],
	cityListFailure: ['error'],
	cityListStateIdRequest: ['id'],
	cityListStateIdSuccess: ['cities'],
	cityListStateIdFailure: ['error'],
	setLoading: ["isLoading"]
}, {});

export const CityTypes = Types;
export const CityCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
	cities: [],
	isLoading: false
});

export const onCityListSuccess = (state, { cities }) => state.merge({ cities });
export const onSetLoading = (state, { isLoading }) => state.merge({ isLoading });

//map our actions types to our reducer functions
export const HANDLERS = {
	[CityTypes.CITY_LIST_SUCCESS]: onCityListSuccess,
	[CityTypes.CITY_LIST_STATE_ID_SUCCESS]: onCityListSuccess,
	[CityTypes.SET_LOADING]: onSetLoading
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectCities = state => Immutable.asMutable(state.cityReducer.cities, { deep: true });
// export const selectRolesLookup = state => {
//   const { roles } =  state.rolesReducer;
//     return roles ? roles.filter(x => x.isActive).reduce((obj, item) => {
//         obj[item.id] = item.code
//         return obj
//       }, {}) : [];   
// }

export const getLoading = state => state.stateReducer.isLoading;