import { takeLatest, call, put } from 'redux-saga/effects';
import {
  WorkItemTaskTypes,
  WorkItemTaskCreators
} from 'redux/WorkItemTaskRedux';
import workItemTaskService from 'services/workItemTaskService';
import TaskType from 'contract/responses/TaskType';
import { MessageOperation } from '../constants';
import { getAll as getUsers } from 'sagas/userSagas';
import { getBySubCategoryId as getAllTasks } from 'sagas/taskSagas';

export function* getAll(showLoading) {
  if (showLoading) yield put(WorkItemTaskCreators.setLoading(true));

  const response = yield call(workItemTaskService.getAll);

  if (response.ok) {
    yield put(
      WorkItemTaskCreators.workItemTaskGetListSuccess(
        response.data.data.map(item => new TaskType(item))
      )
    );
  } else {
    yield put(
      WorkItemTaskCreators.workItemTaskSetMessage(MessageOperation.error)
    );
  }

  if (showLoading) yield put(WorkItemTaskCreators.setLoading(false));
}

export function* getById({ id }) {
  yield put(WorkItemTaskCreators.setLoading(true));

  const response = yield call(workItemTaskService.getById, id);
  if (response.ok) {
    let item = new TaskType(response.data.data);
    yield put(WorkItemTaskCreators.workItemTaskGetSuccess(item));
  } else {
    yield put(
      WorkItemTaskCreators.workItemTaskSetMessage(MessageOperation.error)
    );
  }

  yield put(WorkItemTaskCreators.setLoading(false));
}

export function* getTasks({ id, subCategoryId }) {
  yield put(WorkItemTaskCreators.setLoading(true));
  yield* getUsers(false);
  yield* getAllTasks({ id: subCategoryId }, false);
  if (id) yield* getByWorkItemId({ id }, false);
  yield put(WorkItemTaskCreators.setLoading(false));
}

export function* getByWorkItemId({ id }, showLoading = true) {
  if (showLoading) yield put(WorkItemTaskCreators.setLoading(true));
  const response = yield call(workItemTaskService.getByWorkItemId, id);
  if (response.ok) {
    yield put(
      WorkItemTaskCreators.workItemTaskGetByWorkItemIdSuccess(
        response.data.data
      )
    );
  } else {
    yield put(
      WorkItemTaskCreators.workItemTaskSetMessage(MessageOperation.error)
    );
  }

  if (showLoading) yield put(WorkItemTaskCreators.setLoading(false));
}

export function* create({ taskType }) {
  yield put(WorkItemTaskCreators.setLoading(true));
  const response = yield call(workItemTaskService.create, taskType);
  if (response.ok) {
    let newTaskType = new TaskType(response.data.data);
    yield put(
      WorkItemTaskCreators.workItemTaskCreateSuccess(
        newTaskType,
        MessageOperation.success
      )
    );
    yield* getById({ id: newTaskType.id });
  } else {
    yield put(
      WorkItemTaskCreators.workItemTaskSetMessage(MessageOperation.error)
    );
  }

  yield put(WorkItemTaskCreators.setLoading(false));
}

export function* update({ taskType }) {
  yield put(WorkItemTaskCreators.setLoading(true));

  const response = yield call(workItemTaskService.update, taskType);

  if (response.ok) {
    yield put(
      WorkItemTaskCreators.workItemTaskUpdateSuccess(
        taskType,
        MessageOperation.success
      )
    );
    yield* getById({ id: taskType.id });
  } else {
    yield put(
      WorkItemTaskCreators.workItemTaskSetMessage(MessageOperation.error)
    );
  }

  yield put(WorkItemTaskCreators.setLoading(false));
}

export function* deleteItem({ id }) {
  yield put(WorkItemTaskCreators.setLoading(true));

  const response = yield call(workItemTaskService.delete, id);

  if (response.ok) {
    yield put(
      WorkItemTaskCreators.workItemTaskDeleteSuccess(
        id,
        MessageOperation.success
      )
    );
  } else {
    yield put(
      WorkItemTaskCreators.workItemTaskSetMessage(MessageOperation.error)
    );
  }

  yield put(WorkItemTaskCreators.setLoading(false));
}

/**
 * sagas
 */
function* workItemTaskSagas() {
  yield takeLatest(WorkItemTaskTypes.WORK_ITEM_TASK_GET_LIST_REQUEST, getAll);
  yield takeLatest(WorkItemTaskTypes.WORK_ITEM_TASK_GET_REQUEST, getById);
  yield takeLatest(
    WorkItemTaskTypes.WORK_ITEM_TASK_GET_BY_WORK_ITEM_ID_REQUEST,
    getTasks
  );
  yield takeLatest(WorkItemTaskTypes.WORK_ITEM_TASK_CREATE_REQUEST, create);
  yield takeLatest(WorkItemTaskTypes.WORK_ITEM_TASK_UPDATE_REQUEST, update);
  yield takeLatest(WorkItemTaskTypes.WORK_ITEM_TASK_DELETE_REQUEST, deleteItem);
}

export default workItemTaskSagas;
