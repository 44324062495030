import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions({
	getCategoryRequest: ['id'],
	getCategorySuccessfull: ['category'],
	getCategoriesRequest: [],
	getCategoriesSuccessfull: ['categories'],
	getCategoriesError: ['error'],
	createCategoryRequest: ['item'],
	createCategoryOK: ['item', 'messageOperation'],
	createCategoryKO: ['error'],
	updateCategoryRequest: ['item'],
	updateCategoryOK: ['item', 'messageOperation'],
	updateCategoryKO: ['error'],
	deleteCategoryRequest: ['id'],
	deleteCategoryOK: ['id', 'messageOperation'],
	deleteCategoryKO: ['error'],
	createSubCategoryRequest: ['item'],
	createSubCategoryOK: ['item', 'messageOperation'],
	createSubCategoryKO: ['error'],
	updateSubCategoryRequest: ['item'],
	updateSubCategoryOK: ['item', 'messageOperation'],
	updateSubCategoryKO: ['error'],
	deleteSubCategoryRequest: ['id'],
	deleteSubCategoryOK: ['id', 'messageOperation'],
	deleteSubCategoryKO: ['error'],
	setLoading: ["isLoading"],
	setOwnerLoading: ["isOwnerLoading"],
	categoryLocationCreateRequest: ['items'],
	categoryLocationCreateSuccess: ['messageOperation'],
	categoryLocationCreateFailure: ['error'],
	categoryLocationGetByOwnerIdRequest: ['categoryId', 'ownerId'],
	categoryLocationGetByOwnerIdSuccess: ['ownerCategoryLocations', 'ownerId'],
    categoryLocationGetByOwnerIdFailure: ['error'],
    categoryLocationGetByCategoryIdRequest: ['categoryId'],
    categoryLocationGetByCategoryIdSuccess: ['categoryLocations'],
    categoryLocationGetByCategoryIdFailure: ['error'],
	categoryLocationCatalogsRequest: [],
	categorySetMessage: ['messageOperation']
}, {});

export const CategoryTypes = Types;
export const CategoryCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
	category: null,
	categories: [],
	subCategories: [],
	ownerCategoryLocations: [],
	categoryLocations: [],
	categoryLocationOwnerId: null,
	isLoading: false,
	isOwnerLoading: false
});

export const onSetCategory = (state, { category }) => state.merge({ category });
export const onSetCategories = (state, { categories }) => state.merge({ categories });
export const onCreateCategory = (state, { item, messageOperation }) => state.merge({ categories: state.categories.concat(item), messageOperation, category: item });
export const onUpdateCategory = (state, { item, messageOperation }) => state.merge({ categories: state.categories.map(i => i.id === item.id ? item : i), category: item, messageOperation });
export const onDeleteCategory = (state, { id, messageOperation }) => state.merge({ categories: state.categories.filter(x => x.id != id), messageOperation });
export const onCategoryLocationCreate = (state, { messageOperation }) => state.merge({ messageOperation: messageOperation });
export const onCategoryLocationGetByOwnerId = (state, { ownerCategoryLocations, ownerId }) => state.merge({ ownerCategoryLocations, ownerId });
export const onCategoryLocationGetByCategoryId = (state, { categoryLocations }) => state.merge({ categoryLocations });
export const onSetLoading = (state, { isLoading }) => state.merge({ isLoading });
export const onSetOwnerLoading = (state, { isOwnerLoading }) => state.merge({ isOwnerLoading });
export const onCategorySetMessage = (state, { messageOperation }) => state.merge({ messageOperation });

//map our actions types to our reducer functions
export const HANDLERS = {
	[CategoryTypes.GET_CATEGORY_SUCCESSFULL]: onSetCategory,
	[CategoryTypes.GET_CATEGORIES_SUCCESSFULL]: onSetCategories,
	[CategoryTypes.CREATE_CATEGORY_OK]: onCreateCategory,
	[CategoryTypes.UPDATE_CATEGORY_OK]: onUpdateCategory,
	[CategoryTypes.DELETE_CATEGORY_OK]: onDeleteCategory,
	[CategoryTypes.CATEGORY_LOCATION_CREATE_SUCCESS]: onCategoryLocationCreate,
	[CategoryTypes.CATEGORY_LOCATION_GET_BY_OWNER_ID_SUCCESS]: onCategoryLocationGetByOwnerId,
	[CategoryTypes.CATEGORY_LOCATION_GET_BY_CATEGORY_ID_SUCCESS]: onCategoryLocationGetByCategoryId,
	[CategoryTypes.SET_LOADING]: onSetLoading,
	[CategoryTypes.SET_OWNER_LOADING]: onSetOwnerLoading,
	[CategoryTypes.CATEGORY_SET_MESSAGE]: onCategorySetMessage
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectCategory = state => state.categoriesReducer.category;
export const selectCategories = state => Immutable.asMutable(state.categoriesReducer.categories, { deep: true });
export const selectSubCategories = state => Immutable.asMutable(state.categoriesReducer.subCategories, { deep: true });
export const selectIsLoading = state => state.categoriesReducer.isLoading;
export const selectOwnerLoading = state => state.categoriesReducer.isOwnerLoading;
export const selectOwnerId = state => state.categoriesReducer.ownerId;
export const selectOwnerCategoryLocations = state => {
	let companyLocations = Immutable.asMutable(state.companyLocationReducer.companyLocations, { deep: true });
	companyLocations = companyLocations.map(item => ({ ...item, isActive: false }));

	let foundItem;
	if (state.categoriesReducer.ownerCategoryLocations)
		state.categoriesReducer.ownerCategoryLocations.forEach(item => {
			foundItem = companyLocations.find(x => x.id == item.companyLocationId);
			foundItem.isActive = true;
		});

	return companyLocations;
};
export const selectCategoryLocations = state => Immutable.asMutable(state.categoriesReducer.categoryLocations, { deep: true });
export const selectMessageOperation = state => state.categoriesReducer.messageOperation;