import { takeLatest, call, put, all } from "redux-saga/effects";
import { PriorityCreators, PriorityTypes } from "redux/PriorityRedux";
import priorityService from "services/priorityService";
import { MessageOperation } from "../constants";

export function* getAll(showLoading) {
	if(showLoading)
		yield put(PriorityCreators.setLoading(true));

	const response = yield call(priorityService.getAll);

	if (response.ok) {
		yield put(PriorityCreators.priorityGetAllSuccess(response.data.data));
	} else {
		yield put(PriorityCreators.priorityGetAllFailure(MessageOperation.error));
	}

	if(showLoading)
		yield put(PriorityCreators.setLoading(false));
}

function* prioritySagas(){
	yield all([
		takeLatest(PriorityTypes.PRIORITY_GET_ALL_REQUEST, getAll)
	]);
}

export default prioritySagas;