import Api from "./Api";

class RoutesService extends Api {
	constructor(controller) {
		super(controller);
	}

	saveRoutes = model => {
		return this.API.post(`${this.controller}/massive`, model);
	};
}

let service = new RoutesService("routes");

export default service;