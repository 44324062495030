import Api from "./Api";

class RoutesService extends Api {
	constructor(controller) {
		super(controller);
	}

	save = model => {
		return this.API.post(`${this.controller}`, model);
	};
}

let service = new RoutesService("vehicles");

export default service;