import API from "./ApiFiles";

class ClientsService extends API {

    constructor(controller) {
        super(controller);
    }

    downloadFile = id => {
        return this.API.get(`${this.controller}/${id}/download`);
    }
}

let documentsService = new ClientsService("ClientContract");

export default documentsService;