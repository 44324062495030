import EntityBase from "../EntityBase";

class City extends EntityBase {
	constructor(obj) {
		if (!obj) obj = {};
		super(obj);
		this.code = obj.code ? obj.code : "";
		this.detail = obj.detail ? obj.detail : "";
		this.abbr = obj.abbr ? obj.abbr : "";
	}
}

export default City;