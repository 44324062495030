import React from 'react';
import PropTypes from "prop-types";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SwitchList = ({
	onHandleChange,
	items,
	propChecked,
	propLabel,
	propValue
}) => {
	const handleChange = value => event => {
		let item = items.find(x => x.id === value.id);
		item[propChecked] = event.target.checked;
		onHandleChange(item);
	};

	const mappedItems = items.map((value, index) => (
		<FormControlLabel
			key={`formControlLabel-${index}`}
			control={
				<Switch
					key={`switch-${index}`}
					checked={value[propChecked]}
					onChange={handleChange(value)}
					value={value[propValue]}
					color="primary"
				/>
			}
			label={value[propLabel]}
		/>
	));

	return <FormGroup>{mappedItems}</FormGroup>;
};

SwitchList.propTypes = {
	handleChange: PropTypes.func,
	items: PropTypes.array,
	propChecked: PropTypes.string,
	propLabel: PropTypes.string,
	propValue: PropTypes.string
};

SwitchList.defaultProps = {
	items: []
};

export default SwitchList;