import EntityBase from "../EntityBase";

class Address extends EntityBase {
	constructor(obj) {
		if (!obj) obj = {};
		super(obj);
		// this.addressId = obj.addressId ? obj.addressId : undefined;
		this.address1 = obj.address1 ? obj.address1 : "";
		this.address2 = obj.address2 ? obj.address2 : "";
		this.stateId = obj.stateId ? obj.stateId : undefined;
		this.cityId = obj.cityId ? obj.cityId : undefined;
		this.zipCode = obj.zipCode ? obj.zipCode : "";
		this.latitude = obj.latitude ? obj.latitude : "";
		this.longitude = obj.longitude ? obj.longitude : "";
		this.businessName = obj.businessName ? obj.businessName : "";
		this.taxId = obj.taxId ? obj.taxId : undefined;
		this.phoneNumber = obj.phoneNumber ? obj.phoneNumber : "";
		this.email = obj.email ? obj.email : "";
	}
}

export default Address;
