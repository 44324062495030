import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    setLoading: ['isLoading'],
    taskGetListRequest: [],
    taskGetListSuccess: ['tasks'],
    taskGetListFailure: ['error'],
    taskGetRequest: ['id'],
    taskGetSuccess: ['task'],
    taskGetFailure: ['error'],
    taskGetByTaskTypeIdRequest: ['id'],
    taskGetByTaskTypeIdSuccess: ['taskSubCategories'],
    taskGetByTaskTypeIdFailure: ['error'],
    taskGetBySubCategoryIdRequest: ['id'],
    taskGetBySubCategoryIdSuccess: ['taskSubCategories'],
    taskGetBySubCategoryIdFailure: ['error'],
    taskGetByCategoryIdRequest: ['id', 'categoryId'],
    taskGetByCategoryIdSuccess: ['taskSubCategories'],
    taskGetByCategoryIdFailure: ['error'],
    taskCreateRequest: ['task'],
    taskCreateSuccess: ['task', 'messageOperation'],
    taskCreateFailure: ['error'],
    taskUpdateRequest: ['task'],
    taskUpdateSuccess: ['task', 'messageOperation'],
    taskUpdateFailure: ['error'],
    taskDeleteRequest: ['id'],
    taskDeleteSuccess: ['id', 'messageOperation'],
    taskDeleteFailure: ['error'],
    taskSetMessage: ['messageOperation'],
    taskSaveMultipleRequest: ['task'],
    taskSaveMultipleSuccess: ['task', 'messageOperation'],
    taskSaveMultipleFailure: ['error']
  },
  {}
);

export const TaskTypes = Types;
export const TaskCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  tasks: [],
  taskSubCategories: [],
  isLoading: false,
  messageOperation: null
});

export const onTaskGetListSuccess = (state, { tasks }) =>
  state.merge({ tasks: tasks });
export const onTaskGetSuccess = (state, { task }) =>
  state.merge({ task: task });
export const onTaskGetByTaskTypeSuccess = (
  state,
  { taskSubCategories, messageOperation }
) =>
  state.merge({
    taskSubCategories: taskSubCategories,
    messageOperation: messageOperation
  });
export const onTaskGetBySubCategoryIdSuccess = (state, { taskSubCategories }) =>
  state.merge({
    taskSubCategories: taskSubCategories
  });
export const onTaskGetByCategoryIdSuccess = (state, { taskSubCategories }) =>
  state.merge({
    taskSubCategories: taskSubCategories
  });
export const onTaskCreateSuccess = (state, { task, messageOperation }) =>
  state.merge({
    tasks: state.taskReducer.concat(task),
    messageOperation,
    task: task
  });
export const onTaskUpdateSuccess = (state, { task, messageOperation }) =>
  state.merge({
    tasks: state.taskReducer.map(i => (i.id === task.id ? task : i)),
    task: task,
    messageOperation
  });
export const onTaskDeleteSuccess = (state, { id, messageOperation }) => {
  let item = state.taskReducer.find(i => i.id == id);
  return state.merge({
    tasks: state.taskReducer.map(i =>
      i.id == item.id ? { ...item, isActive: false } : i
    ),
    messageOperation
  });
};
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });
export const onTaskSetMessage = (state, { messageOperation }) =>
  state.merge({ messageOperation });
export const onTaskSaveMultipleSuccess = (
  state,
  { task, messageOperation }
) => {
  if (state.tasks.find(i => i.taskTypeId == task.taskTypeId))
    return state.merge({
      tasks: state.tasks.map(i => (i.taskTypeId == task.taskTypeId ? task : i)),
      messageOperation
    });
  else
    return state.merge({
      tasks: state.tasks.concat(task),
      messageOperation
    });
};

//map our actions types to our reducer functions
export const HANDLERS = {
  [TaskTypes.TASK_GET_LIST_SUCCESS]: onTaskGetListSuccess,
  [TaskTypes.TASK_GET_SUCCESS]: onTaskGetSuccess,
  [TaskTypes.TASK_GET_BY_TASK_TYPE_ID_SUCCESS]: onTaskGetByTaskTypeSuccess,
  [TaskTypes.TASK_GET_BY_SUB_CATEGORY_ID_SUCCESS]: onTaskGetBySubCategoryIdSuccess,
  [TaskTypes.TASK_GET_BY_CATEGORY_ID_SUCCESS]: onTaskGetByCategoryIdSuccess,
  [TaskTypes.TASK_CREATE_SUCCESS]: onTaskCreateSuccess,
  [TaskTypes.TASK_SAVE_MULTIPLE_SUCCESS]: onTaskSaveMultipleSuccess,
  [TaskTypes.TASK_UPDATE_SUCCESS]: onTaskUpdateSuccess,
  [TaskTypes.TASK_DELETE_SUCCESS]: onTaskDeleteSuccess,
  [TaskTypes.SET_LOADING]: onSetLoading,
  [TaskTypes.TASK_SET_MESSAGE]: onTaskSetMessage
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectIsLoading = state => state.taskReducer.isLoading;
export const selectMessageOperation = state =>
  state.taskReducer.messageOperation;
export const selectTasks = state => {
  let collection = Immutable.asMutable(state.taskReducer.tasks, {
    deep: true
  }).map(item => ({
    subCategory: item.subCategoryCode,
    taskType: item.taskTypeCode,
    taskTypeId: item.taskTypeId,
    isRequired: item.isRequired,
    isActive: item.isActive
  }));

  let results = [];

  collection.forEach(item => {
    if (!results.find(x => x.taskTypeId == item.taskTypeId)) results.push(item);
  });

  return results;
};
export const selectWorkItemtaskSubCategories = state =>
  Immutable.asMutable(state.TaskReducer.workItemTasksSubcategories, {
    deep: true
  });

export const selectTask = state => state.taskReducer.task;
export const selecttaskSubCategories = state => {
  const { taskSubCategories } = state.taskReducer;

  let subCategories = Immutable.asMutable(
    state.subCategoryReducer.subCategories,
    { deep: true }
  )
    .filter(x => x.isActive)
    .map(item => ({
      ...item,
      isActive: false,
      isRequired: false
    }));

  let foundItem;
  if (taskSubCategories)
    taskSubCategories.forEach(item => {
      foundItem = subCategories.find(x => x.id === item.subCategoryId);
      if (foundItem) {
        foundItem.isActive = item.isActive;
        foundItem.isRequired = item.isRequired;
      }
    });

  return subCategories;
};
