import { takeLatest, call, put } from 'redux-saga/effects';
import { TaskTypeTypes, TaskTypeCreators } from 'redux/TaskTypeRedux';
import taskTypeService from 'services/taskTypeService';
import TaskType from 'contract/responses/TaskType';
import { MessageOperation } from '../constants';

export function* getAll(showLoading) {
  if (showLoading) yield put(TaskTypeCreators.setLoading(true));

  const response = yield call(taskTypeService.getAll);

  if (response.ok) {
    yield put(
      TaskTypeCreators.taskTypeGetListSuccess(
        response.data.data.map(item => new TaskType(item))
      )
    );
  } else {
    yield put(TaskTypeCreators.taskTypeSetMessage(MessageOperation.error));
  }

  if (showLoading) yield put(TaskTypeCreators.setLoading(false));
}

export function* getById({ id }) {
  yield put(TaskTypeCreators.setLoading(true));

  const response = yield call(taskTypeService.getById, id);
  if (response.ok) {
    let item = new TaskType(response.data.data);
    yield put(TaskTypeCreators.taskTypeGetSuccess(item));
  } else {
    yield put(TaskTypeCreators.taskTypeSetMessage(MessageOperation.error));
  }

  yield put(TaskTypeCreators.setLoading(false));
}

export function* create({ taskType }) {
  yield put(TaskTypeCreators.setLoading(true));
  const response = yield call(taskTypeService.create, taskType);
  if (response.ok) {
    let newTaskType = new TaskType(response.data.data);
    yield put(
      TaskTypeCreators.taskTypeCreateSuccess(
        newTaskType,
        MessageOperation.success
      )
    );
    yield* getById({ id: newTaskType.id });
  } else {
    yield put(TaskTypeCreators.taskTypeSetMessage(MessageOperation.error));
  }

  yield put(TaskTypeCreators.setLoading(false));
}

export function* update({ taskType }) {
  yield put(TaskTypeCreators.setLoading(true));

  const response = yield call(taskTypeService.update, taskType);

  if (response.ok) {
    yield put(
      TaskTypeCreators.taskTypeUpdateSuccess(taskType, MessageOperation.success)
    );
    yield* getById({ id: taskType.id });
  } else {
    yield put(TaskTypeCreators.taskTypeSetMessage(MessageOperation.error));
  }

  yield put(TaskTypeCreators.setLoading(false));
}

export function* deleteItem({ id }) {
  yield put(TaskTypeCreators.setLoading(true));

  const response = yield call(taskTypeService.delete, id);

  if (response.ok) {
    yield put(
      TaskTypeCreators.taskTypeDeleteSuccess(id, MessageOperation.success)
    );
  } else {
    yield put(TaskTypeCreators.taskTypeSetMessage(MessageOperation.error));
  }

  yield put(TaskTypeCreators.setLoading(false));
}

/**
 * sagas
 */
function* taskTypesSagas() {
  yield takeLatest(TaskTypeTypes.TASK_TYPE_GET_LIST_REQUEST, getAll);
  yield takeLatest(TaskTypeTypes.TASK_TYPE_GET_REQUEST, getById);
  yield takeLatest(TaskTypeTypes.TASK_TYPE_CREATE_REQUEST, create);
  yield takeLatest(TaskTypeTypes.TASK_TYPE_UPDATE_REQUEST, update);
  yield takeLatest(TaskTypeTypes.TASK_TYPE_DELETE_REQUEST, deleteItem);
}

export default taskTypesSagas;
