import API from "./Api";

class SecurityService extends API {

    constructor(controller){
        super(controller);
    }

	login = credential => {
		return this.API.post(`${this.controller}/login`, credential);
	}

	storeSession = (token) => {
		localStorage.setItem('appUser', JSON.stringify(token));
	}

	clearSession = () => {
		localStorage.clear();
	}
}

let securityService = new SecurityService("auth");

export default securityService;