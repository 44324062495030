import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default class ConfirmDialog extends React.Component {
	static propTypes = {
		onConfirm: PropTypes.func,
		onClose: PropTypes.func,
		text: PropTypes.string
	};

	static defaultProps = {
		onConfirm: () => { }
	}

	onConfirmed = () => {
		const {
			onConfirm,
			onClose
		} = this.props;

		onConfirm();
		onClose();
	}

	render() {
		const {
			open,
			onClose,
			text
		} = this.props;

		return (
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">Confirmación</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						{text}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>
						Cancelar
					</Button>
					<Button onClick={this.onConfirmed} color="primary">
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}