export default theme => ({
    root: {
        // paddingTop: theme.spacing.unit * 1,
        // padding: theme.spacing.unit * 3
    },
    content: {
        marginTop: theme.spacing.unit * 3
    },
    row: {

    },
    columnGrid: {
        height: '500px'
    },
    button: {
        margin: theme.spacing.unit,
    },
    input: {
        display: 'none',
    },
    first: {
        borderRight: '1px solid #eee',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    second: {
        paddingLeft: '20px',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    item: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        marginRight: "10px"
    },
    giantIcon: {
        width: '70px',
        height: '70px'
    },
    itemDescription: {
        fontSize: "20px",
        //fontWeight: "bold"
    }
});
