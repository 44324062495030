import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Weather from "components/Weather";
import WithDashboard from './components/Hoc/WithDashboard';

import withAuth from 'hoc/WithAuth';


import {
    Cloud,
} from "@material-ui/icons";

import { cardTitle } from 'assets/jss/material-dashboard-pro-react/material-dashboard-pro-react';

import RQsDetailsCard from './components/RQsDetailsCard';
import ShortcutsCard from './components/ShortcutsCard';
import NoticiesCard from './components/NoticiesCard';

// import requisitionsService from "services/requisitionsService";

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 4
    },
    item: {
        height: '100%'
    },
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
});

class Dashboard extends Component {
    state = {
        summary: null,
        toExpire: null,
        geo: null,
        geoError: null,
    };

    componentDidMount = () => {
        this.getGeo();
    };

    componentWillMount = () => {
    };

    hasPermission = businessFunctionId => {
        return (
            this.props.permissions &&
            this.props.permissions.find(x => x.bfId == businessFunctionId)
        );
    };

    getGeo = async () => {
        fetch("https://geo-api.sicsa.cloud")
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    this.setState({ geo: data.data });
                }
            })
            .catch(() => {
                this.setState({ geoError: 'No se pudo obtener ubicación' });
            });
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            geo,
            geoError,
        } = this.state;

        return (
            <DashboardLayout title="Dashboard">
                <div className={classes.root}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2}>
                                    {
                                        this.hasPermission(69) &&
                                        <Grid item xs={12} md={6}>
                                            <RQsDetailsCard />
                                        </Grid>
                                    }
                                    <Grid item xs={12} md={6}>
                                        <ShortcutsCard />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <NoticiesCard />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardHeader color="primary" icon>
                                                <CardIcon color="primary">
                                                    <Cloud />
                                                </CardIcon>
                                                <Typography variant="h3" style={{ marginTop: 10 }}>Clima</Typography>
                                            </CardHeader>
                                            <CardBody>
                                                {
                                                    geo &&
                                                    <Weather geo={geo} error={geoError} />
                                                }
                                            </CardBody>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </DashboardLayout >
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles),
    withAuth,
)(WithDashboard(Dashboard));
