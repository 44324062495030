import { combineReducers } from 'redux';

import categoriesReducer from './CategoryRedux';
import subCategoryReducer from './SubCategoryRedux';
import subCategoryLocationReducer from './SubCategoryLocationRedux';
import companyLocationReducer from './CompanyLocationRedux';
import cityReducer from './CityRedux';
import entityTypeReducer from './EntityTypeRedux';
import priorityReducer from './PriorityRedux';
import priorityLevelReducer from './PriorityLevelRedux';
import stateReducer from './StateRedux';
import rolesReducer from './RoleRedux';
import userReducer from './UserRedux';
import userGroupReducer from './UserGroupRedux';
import notificationReducer from './NotificationRedux';
import deparmentReducer from './DeparmentRedux';
import chartWorkItemReducer from './ChartWorkItemRedux';
import responseTimeTypeReducer from './ResponseTimeTypeRedux';
import attachmentConfigReducer from './AttachmentConfigRedux';
import subCategoryAttachmentConfigReducer from './SubCategoryAttachmentConfigRedux';
import taskTypeReducer from './TaskTypeRedux';
import taskReducer from './TaskRedux';
import workItemTaskReducer from './WorkItemTaskRedux';

const rootReducer = combineReducers({
  attachmentConfigReducer,
  userReducer,
  categoriesReducer,
  entityTypeReducer,
  chartWorkItemReducer,
  subCategoryReducer,
  subCategoryLocationReducer,
  cityReducer,
  companyLocationReducer,
  responseTimeTypeReducer,
  rolesReducer,
  priorityReducer,
  priorityLevelReducer,
  stateReducer,
  deparmentReducer,
  subCategoryAttachmentConfigReducer,
  notificationReducer,
  taskTypeReducer,
  taskReducer,
  userGroupReducer,
  workItemTaskReducer
});

export default rootReducer;
