import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Typography, withStyles, Avatar, Chip } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";

import withAuth from 'hoc/WithAuth';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react/material-dashboard-pro-react';

import {
    Link,
    Search,
    GetApp,
    CloudDownload,
    Receipt,
} from "@material-ui/icons";

// import requisitionsService from "services/requisitionsService";

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 4
    },
    item: {
        height: '100%'
    },
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
});



const RQDetailsCard = ({ permissions, history }) => {
    // const [data, setData] = useState(null);
    // const [loading, setLoading] = useState(false);

    // const getData = async () => {
    //     setLoading(true);

    //     try {
    //         const response = await requisitionsService.getDashboardInfo();
    //         setData(response.data.data);
    //     }
    //     catch (e) {
    //         console.log(e);
    //     }

    //     setLoading(false);
    // };
    const hasPermission = id => {
        return (
            permissions &&
            permissions.find(x => x.bfId == id)
        );
    };

    useEffect(() => {
        // getData();
    }, []);

    // const renderLoading = () => {
    //     return (
    //         <Grid container spacing={2}>
    //             <Grid item xs={12}>
    //                 <CircularProgress color="primary" />
    //             </Grid>
    //         </Grid>
    //     );
    // };

    // const formatCurrency = (number) => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(number);

    const renderContent = () => {
        return (
            <>
                <Grid container spacing={2}>
                    {
                        hasPermission(69) &&
                        <Grid item xs={12}>
                            <Chip
                                avatar={<Avatar>B</Avatar>}
                                label="Buscar requisiciones"
                                clickable
                                color="primary"
                                onClick={() => {
                                    history.push('/search-requisitions');
                                }}
                                deleteIcon={<Search />}
                                onDelete={() => {
                                    history.push('/search-requisitions');
                                }}
                            />
                        </Grid>
                    }
                    {
                        hasPermission(69) &&
                        <Grid item xs={12}>
                            <Chip
                                avatar={<Avatar>D</Avatar>}
                                label="Descargar Layout"
                                clickable
                                color="secondary"
                                onClick={() => {
                                    history.push('/download-layout');
                                }}
                                deleteIcon={<GetApp />}
                                onDelete={() => {
                                    history.push('/download-layout');
                                }}
                            />
                        </Grid>
                    }
                    {
                        hasPermission(119) &&
                        <Grid item xs={12}>
                            <Chip
                                avatar={<Avatar>R</Avatar>}
                                label="Reporte detallado RQs"
                                clickable
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                    history.push('/detailed-rq-report');
                                }}
                                deleteIcon={<CloudDownload />}
                                onDelete={() => {
                                    history.push('/detailed-rq-report');
                                }}
                            />
                        </Grid>
                    }
                    {
                        hasPermission(114) &&
                        <Grid item xs={12}>
                            <Chip
                                variant="outlined"
                                avatar={<Avatar>T</Avatar>}
                                label="Timbrar traslados"
                                clickable
                                color="secondary"
                                onClick={() => {
                                    history.push('/consigment-notes');
                                }}
                                deleteIcon={<Receipt />}
                                onDelete={() => {
                                    history.push('/consigment-notes');
                                }}
                            />
                        </Grid>
                    }
                </Grid>
            </>
        );
    };

    return (
        <Card>
            <CardHeader color="primary" icon>
                <CardIcon color="primary">
                    <Link />
                </CardIcon>
                <Typography variant="h3" style={{ marginTop: 10 }}>Accesos directos</Typography>
            </CardHeader>
            <CardBody>
                {/* {loading && renderLoading()} */}
                {/* {!loading && renderContent()} */}
                {renderContent()}
            </CardBody>
        </Card>
    );
};


RQDetailsCard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles),
    withAuth,
)(RQDetailsCard);


/*

*/