import API from "./Api";

class PriorityLevelService extends API {

    constructor(controller){
        super(controller);
    }

    createMultiple = (items) => {
        return this.API.post(`${this.controller}/multiple`, items);
    }
    
    getBySubCategoryId = (subCategoryId) => {
        return this.API.get(`${this.controller}/subcategory/${subCategoryId}`);
    }
    
}

let priorityLevelService = new PriorityLevelService("prioritylevel");

export default priorityLevelService;