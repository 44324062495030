import { takeLatest, call, put, all } from "redux-saga/effects";
import { PriorityLevelCreators, PriorityLevelTypes } from "redux/PriorityLevelRedux";
import priorityLevelService from "services/priorityLevelService";
import { MessageOperation } from "../constants";
import { getAll as getPriorities } from 'sagas/prioritySagas';

export function* priorityLevelCreateMultiple({ items }) {
	yield put(PriorityLevelCreators.setLoading(true));
	const response = yield call(priorityLevelService.createMultiple, items);

	if (response.ok) {
		yield getBySubCategoryId({id: items.subCategoryId});        
		yield put(PriorityLevelCreators.priorityLevelCreateSuccess(MessageOperation.success));
	} else {
		yield put(PriorityLevelCreators.priorityLevelCreateFailure(response.problem));
	}

	yield put(PriorityLevelCreators.setLoading(false));
}

export function* getBySubCategoryId({ id }) {
    yield* getPriorities();
	yield put(PriorityLevelCreators.setLoading(true));
	const response = yield call(priorityLevelService.getBySubCategoryId, id);

	if (response.ok) {
		yield put(PriorityLevelCreators.priorityLevelGetSuccess(response.data.data));
	} else {
		yield put(PriorityLevelCreators.priorityLevelGetFailure(response.problem));
	}

	yield put(PriorityLevelCreators.setLoading(false));
}

/**
 * priorityLevelSagas 
 */
function* priorityLevelSagas() {
	yield all([
		takeLatest(PriorityLevelTypes.PRIORITY_LEVEL_GET_REQUEST, getBySubCategoryId),
		takeLatest(PriorityLevelTypes.PRIORITY_LEVEL_CREATE_REQUEST, priorityLevelCreateMultiple),
	]);
}

export default priorityLevelSagas;