export default theme => ({
	root: {},
	form: {},
	textField: {
		width: '100%',
		marginBottom: theme.spacing.unit * 2
	},
	portletFooter: {
		paddingLeft: theme.spacing.unit * 3,
		paddingRight: theme.spacing.unit * 3,
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2
	}
});