import React from 'react';
import { usePushNotifications } from '../hooks';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';

// export const hocComponentName = (WrappedComponent) => {
//     const pushNotification = usePushNotifications();
//     const hoc = ({ ...props }) => <WrappedComponent pushNotification={pushNotification} {...props} />;

//     hoc.propTypes = {
//         pushNotification: PropTypes.object
//     };

//     hoc.defaultProps = {
//         pushNotification: null
//     };

//     return hoc;
// };

// export default WrapperComponent => connect(() => ({}), {})(hocComponentName(WrapperComponent));


function withMyHook(Component) {
    return function WrappedComponent(props) {
        const pushNotification = usePushNotifications();
        return <Component {...props} pushNotification={pushNotification} />;
    };
}

export default withMyHook;