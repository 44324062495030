import React from "react";
import PropTypes from "prop-types";

import classNames from 'classnames';

import {
	InputLabel
} from "@material-ui/core";

import { Select } from "components";

import { withStyles } from "@material-ui/core";

import "./styles.jsx";

const SelectForm = ({ label, items, classes, form, field }) => (

	<div
		className={
			classNames(
				classes.selectRoot,
				{ "error": form.touched[field.name] && form.errors[field.name] }
			)
		}
	>
		<InputLabel>{label}</InputLabel>
		<Select
			isSearchable
			value={field.value}
			onChange={(selected) => form.setFieldValue(field.name, selected.value)}
			options={items}
			onMenuClose={() => form.setFieldTouched(field.name, true, true)}
			onMenuOpen={() => form.setFieldTouched(field.name, false)}
		/>
		{
			form.touched[field.name] && form.errors[field.name] &&
			<p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
		}
	</div>
);


SelectForm.propTypes = {
	label: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	classes: PropTypes.object
};

export default withStyles(SelectForm);