import Api from "./Api";

class ClasificationsService extends Api {
    
    constructor(controller){
        super(controller);
    }

    save = model => {
        return this.API.post(`${this.controller}`, model);
    };
}

let service = new ClasificationsService("classifications");

export default service;