import { takeLatest, call, put, all } from "redux-saga/effects";
import { StateTypes, StateCreators } from "../redux/StateRedux";
import stateService from "../services/stateService";
import State from "../contract/responses/State";

export function* getAll() {
	yield put(StateCreators.setLoading(true));

	const response = yield call(stateService.getAll);

	if (response.ok) {
		yield put(StateCreators.stateListSuccess(response.data.data.map(item => new State(item))));
	} else {
		yield put(StateCreators.stateListFailure(response.problem));
	}

	yield put(StateCreators.setLoading(false));
}

/**
 * sagas 
 */
function* statesSagas() {
	yield all([
		takeLatest(StateTypes.STATE_LIST_REQUEST, getAll),
	]);
}

export default statesSagas;