import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';
import { WithNotifications } from '../../../../../../hoc';

// Material components
import {
	// Button,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography
} from '@material-ui/core';

// Material icons
import {
	ArrowForwardIos as ArrowForwardIosIcon,
	ForumOutlined,
	NotificationImportantOutlined
} from '@material-ui/icons';

// Component styles
import styles from './styles';

const icons = {
	0: {
		icon: <NotificationImportantOutlined />,
		color: 'red'
	},
	1: {
		icon: <ForumOutlined />,
		color: 'green'
	},
	2: {
		icon: <ForumOutlined />,
		color: 'blue'
	}
};

class NotificationList extends Component {
	getNotificationUrl = (notification) => {
		switch (notification.type) {
			case 0:
				return notification.url || "#";
			case 1:
			case 2:
				return `/ticket/${notification.id}`;
		}
	}

	getNotificationTitle = (notification) => {
		switch (notification.type) {
			case 0:
				return notification.text;
			case 1:
				return `Ticket #${notification.id} actualizado`;
			case 2:
				return `Se te ha asignado el ticket #${notification.id}`;
		}
	}

	getNotificationSecondary = (notification) => {
		return moment(notification.creationDate).fromNow();
	}

	render() {
		const { className, classes, notifications, onSelect } = this.props;

		const rootClassName = classNames(classes.root, className);

		return (
			<div className={rootClassName}>
				{
					notifications.length > 0 ?
						(
							<Fragment>
								<div className={classes.header}>
									<Typography variant="h6">Notificaciones</Typography>
									<Typography
										className={classes.subtitle}
										variant="body2"
									>
										{notifications.length} nuevas
									</Typography>
								</div>
								<div className={classes.content}>
									<List component="div">
										{notifications.map(notification => (
											<Link
												key={notification.notificationId}
												onClick={() => {
													this.props.readNotification(notification.notificationId);
												}}
												to={this.getNotificationUrl(notification)}
											>
												<ListItem
													className={classes.listItem}
													component="div"
													onClick={onSelect}
												>
													<ListItemIcon
														className={classes.listItemIcon}
														style={{ color: icons[notification.type].color }}
													>
														{icons[notification.type].icon}
													</ListItemIcon>
													<ListItemText
														classes={{ secondary: classes.listItemTextSecondary }}
														primary={this.getNotificationTitle(notification)}
														secondary={this.getNotificationSecondary(notification)}
													/>
													<ArrowForwardIosIcon className={classes.arrowForward} />
												</ListItem>
												<Divider />
											</Link>
										))}
									</List>
									<div className={classes.footer}>
										{/* <Button
											color="primary"
											component={Link}
											size="small"
											to="#"
											variant="contained"
										>
											See all
										</Button> */}
									</div>
								</div>
							</Fragment>
						)
						:
						(
							<div className={classes.empty}>
								<div className={classes.emptyImageWrapper}>
									<img
										alt="Empty list"
										className={classes.emptyImage}
										src="/images/empty.png"
									/>
								</div>
								<Typography variant="h4">Sin notificaciones</Typography>
							</div>
						)
				}
			</div>
		);
	}
}

NotificationList.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired,
	notifications: PropTypes.array.isRequired,
	onSelect: PropTypes.func
};

NotificationList.defaultProps = {
	notifications: [],
	onSelect: () => { }
};

export default WithNotifications(withStyles(styles)(NotificationList));
