import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ClassificationsView from "views/Classifications";

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress,
    Checkbox,
    IconButton,
} from '@material-ui/core';

import { Visibility } from '@material-ui/icons';

//import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Modal } from 'components';

//Services
import storagesService from "services/storagesService";

//Assets
import styles from './styles';
import { WithNotifications } from 'hoc';

class Suppliers extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        id: PropTypes.number.isRequired,
    };

    state = {
        items: [],
        showClassificationsOfStorage: null,
    };

    columns = [
        {
            title: "ID",
            field: "storageId",
            editable: 'never',
        },
        {
            title: "Nombre",
            field: 'name'
        },
        {
            title: "Código SAP",
            field: 'sapCode'
        },
        {
            title: "Clasificaciones",
            field: "supplierId",
            editable: 'never',
            render: row => (
                <IconButton
                    disabled={!row || !row.supplierId}
                    onClick={() => {
                        this.setState({ showClassificationsOfStorage: row.storageId });
                    }}
                >
                    <Visibility />
                </IconButton>
            )
        },
        {
            title: "Activo",
            field: 'isActive',
            type: 'bool',
            initialEditValue: true,
            editComponent: props => (
                <Checkbox
                    checked={props.value}
                    onChange={(e, value) => {
                        props.onChange(value);
                    }}
                />
            ),
            render: row => (
                <Checkbox
                    disabled
                    checked={row.isActive}
                // onChange={(e, value) => {
                //     const items = this.state.items;
                //     const item = items.find(x => x.row.tableData.id);
                //     item.isActive = value;

                //     this.setState({ items });
                // }}
                />
            ),
        },
    ];

    componentDidMount = () => {
        this.getData();
    }

    getData = async () => {
        const response = await storagesService.getById(this.props.id);
        if (response.ok && response.data.status != "Fail") {
            this.setState({ items: response.data.data });
        }
    }

    getTitle = () => {
        return "Almacenes";
    }

    save = async (newData) => {
        newData.supplierId = this.props.id;

        if (!newData.sapCode) {
            this.props.addNotification({ message: 'Codigo de SAP es requerido', options: { variant: "error" } });
            return Promise.reject();
        }

        if (!newData.name) {
            this.props.addNotification({ message: 'Nombre de almacen es requerido', options: { variant: "error" } });
            return Promise.reject();
        }

        const response = await storagesService.save(newData);


        if (!response.ok) {
            this.props.addNotification({ message: 'No se pudo guardar información', options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: 'Información guardada', options: { variant: "success" } });
        this.getData();
    }

    update = async (newData) => {
        const response = await storagesService.save(newData);

        if (!response.ok) {
            this.props.addNotification({ message: 'No se pudo guardar información', options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: 'Información guardada', options: { variant: "success" } });
        this.getData();
    }

    delete = async (item) => {
        const response = await storagesService.delete(item.storageId);

        if (response.data.status == "Fail") {
            this.props.addNotification({ message: response.data.data, options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: 'Almacen borrado exitosamente', options: { variant: "success" } });
        this.getData();
    }

    renderTable = () => {
        const {
            items,
            loading
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        isLoading={loading}
                        columns={this.columns}
                        items={items}
                        options={{
                            pageSize: 10,
                            search: false
                            // selection: copiyng ? false : true
                        }}
                        editable={{
                            onRowAdd: this.save,
                            onRowUpdate: this.update,
                            onRowDelete: this.delete,
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderClassificationsModal = () => {
        const {
            showClassificationsOfStorage,
        } = this.state;

        return (
            <Modal
                title="Clasificaciones de almacen"
                isOpen={showClassificationsOfStorage != null}
                onRequestClose={() => {
                    this.setState({ showClassificationsOfStorage: null });
                }}
                style={{ width: '95%', height: '85%' }}
            >
                <ClassificationsView
                    id={showClassificationsOfStorage}
                    hideLayout
                    onClose={() => this.setState({ showClassificationsOfStorage: null })}
                />
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading
        } = this.state;

        let isLoading = loading;
        return (
            // <DashboardLayout title={this.getTitle()}>
            <div className={classes.root}>
                <div className={classes.content}>
                    <Portlet className={classes.root}>
                        <PortletContent noPadding>
                            {
                                isLoading
                                    ? <CircularProgress />
                                    : this.renderTable()
                            }
                        </PortletContent>
                    </Portlet>
                    {this.renderClassificationsModal()}
                </div>
            </div>
            // </DashboardLayout>
        );
    }
}

export default WithNotifications(withStyles(styles)(Suppliers));