import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    setLoading: ['isLoading'],
    taskTypeGetListRequest: ['id'],
    taskTypeGetListSuccess: ['taskTypes'],
    taskTypeGetListFailure: ['error'],
    taskTypeGetRequest: ['id'],
    taskTypeGetSuccess: ['taskType'],
    taskTypeGetFailure: ['error'],
    taskTypeCreateRequest: ['taskType'],
    taskTypeCreateSuccess: ['taskType', 'messageOperation'],
    taskTypeCreateFailure: ['error'],
    taskTypeUpdateRequest: ['taskType'],
    taskTypeUpdateSuccess: ['taskType', 'messageOperation'],
    taskTypeUpdateFailure: ['error'],
    taskTypeDeleteRequest: ['id'],
    taskTypeDeleteSuccess: ['id', 'messageOperation'],
    taskTypeDeleteFailure: ['error'],
    taskTypeSetMessage: ['messageOperation']
  },
  {}
);

export const TaskTypeTypes = Types;
export const TaskTypeCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  taskTypes: [],
  isLoading: false,
  messageOperation: null
});

export const onTaskTypeGetListSuccess = (state, { taskTypes }) =>
  state.merge({ taskTypes: taskTypes });
export const onTaskTypeGetSuccess = (state, { taskType }) =>
  state.merge({ taskType: taskType });
export const onTaskTypeCreateSuccess = (
  state,
  { taskType, messageOperation }
) =>
  state.merge({
    taskTypes: state.taskTypes.concat(taskType),
    messageOperation,
    taskType: taskType
  });
export const onTaskTypeUpdateSuccess = (
  state,
  { taskType, messageOperation }
) =>
  state.merge({
    taskTypes: state.taskTypes.map(i => (i.id === taskType.id ? taskType : i)),
    taskType: taskType,
    messageOperation
  });
export const onTaskTypeDeleteSuccess = (state, { id, messageOperation }) => {
  let item = state.taskTypes.find(i => i.id == id);
  return state.merge({
    taskTypes: state.taskTypes.map(i =>
      i.id == item.id ? { ...item, isActive: false } : i
    ),
    messageOperation
  });
};

export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });
export const onTaskTypeSetMessage = (state, { messageOperation }) =>
  state.merge({ messageOperation });

//map our actions types to our reducer functions
export const HANDLERS = {
  [TaskTypeTypes.TASK_TYPE_GET_LIST_SUCCESS]: onTaskTypeGetListSuccess,
  [TaskTypeTypes.TASK_TYPE_GET_SUCCESS]: onTaskTypeGetSuccess,
  [TaskTypeTypes.TASK_TYPE_CREATE_SUCCESS]: onTaskTypeCreateSuccess,
  [TaskTypeTypes.TASK_TYPE_UPDATE_SUCCESS]: onTaskTypeUpdateSuccess,
  [TaskTypeTypes.TASK_TYPE_DELETE_SUCCESS]: onTaskTypeDeleteSuccess,
  [TaskTypeTypes.SET_LOADING]: onSetLoading,
  [TaskTypeTypes.TASK_TYPE_SET_MESSAGE]: onTaskTypeSetMessage
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectIsLoading = state => state.taskTypeReducer.isLoading;
export const selectMessageOperation = state =>
  state.taskTypeReducer.messageOperation;
export const selectTaskTypes = state =>
  Immutable.asMutable(state.taskTypeReducer.taskTypes, {
    deep: true
  });
export const selectTaskType = state => state.taskTypeReducer.taskType;
