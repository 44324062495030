import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    setLoading: ['isLoading'],
    attachmentConfigListRequest: [],
    attachmentConfigListSuccess: ['attachmentConfigs'],
    attachmentConfigListError: ['messageOperation'],
    attachmentConfigGetRequest: ['id'],
    attachmentConfigGetSuccess: ['attachmentConfig'],
    attachmentConfigGetFailure: ['error', 'messageOperation'],
    attachmentConfigSaveMultipleRequest: ['attachmentConfig'],
    attachmentConfigSaveMultipleSuccess: [
      'attachmentConfig',
      'messageOperation'
    ],
    attachmentConfigSaveMultipleFailure: ['error'],
    attachmentConfigCreateRequest: ['attachmentConfig'],
    attachmentConfigCreateSuccess: ['attachmentConfig', 'messageOperation'],
    attachmentConfigCreateFailure: ['error'],
    attachmentConfigUpdateRequest: ['attachmentConfig'],
    attachmentConfigUpdateSuccess: ['attachmentConfig', 'messageOperation'],
    attachmentConfigUpdateFailure: ['error'],
    attachmentConfigDeleteRequest: ['id'],
    attachmentConfigDeleteSuccess: ['id', 'messageOperation'],
    attachmentConfigDeleteFailure: ['messageOperation'],
    attachmentConfigSetMessage: ['messageOperation']
  },
  {}
);

export const AttachmentConfigTypes = Types;
export const AttachmentConfigCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  attachmentConfigs: [],
  isLoading: false,
  isValidEdit: undefined
});

export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });
export const onAttachmentConfigListSuccess = (state, { attachmentConfigs }) =>
  state.merge({ attachmentConfigs });
export const onAttachmentConfigGetSuccess = (state, { attachmentConfig }) =>
  state.merge({ attachmentConfig });
export const onAttachmentConfigCreateSuccess = (
  state,
  { attachmentConfig, messageOperation }
) =>
  state.merge({
    attachmentConfigs: state.attachmentConfigs.concat(attachmentConfig),
    messageOperation
  });
export const onAttachmentConfigSaveMultipleSuccess = (
  state,
  { attachmentConfig, messageOperation }
) => {
  if (state.attachmentConfigs.find(i => i.id == attachmentConfig.id))
    state.merge({
      attachmentConfigs: state.attachmentConfigs.map(i =>
        i.id == attachmentConfig.id ? attachmentConfig : i
      ),
      attachmentConfig,
      messageOperation
    });
  else
    state.merge({
      attachmentConfigs: state.attachmentConfigs.concat(attachmentConfig),
      messageOperation
    });
};

export const onAttachmentConfigUpdateSuccess = (
  state,
  { attachmentConfig, messageOperation }
) =>
  state.merge({
    attachmentConfigs: state.attachmentConfigs.map(i =>
      i.id == attachmentConfig.id ? attachmentConfig : i
    ),
    attachmentConfig,
    messageOperation
  });
export const onAttachmentConfigDeleteSuccess = (
  state,
  { id, messageOperation }
) => {
  let item = state.attachmentConfigs.find(i => i.id == id);
  return state.merge({
    attachmentConfigs: state.attachmentConfigs.map(i =>
      i.id == item.id ? { ...item, isActive: false } : i
    ),
    messageOperation
  });
};
export const onAttachmentConfigSetMessage = (state, { messageOperation }) =>
  state.merge({ messageOperation });

//map our actions types to our reducer functions
export const HANDLERS = {
  [AttachmentConfigTypes.SET_LOADING]: onSetLoading,
  [AttachmentConfigTypes.ATTACHMENT_CONFIG_LIST_SUCCESS]: onAttachmentConfigListSuccess,
  [AttachmentConfigTypes.ATTACHMENT_CONFIG_GET_SUCCESS]: onAttachmentConfigGetSuccess,
  [AttachmentConfigTypes.ATTACHMENT_CONFIG_CREATE_SUCCESS]: onAttachmentConfigCreateSuccess,
  [AttachmentConfigTypes.ATTACHMENT_CONFIG_SAVE_MULTIPLE_SUCCESS]: onAttachmentConfigSaveMultipleSuccess,
  [AttachmentConfigTypes.ATTACHMENT_CONFIG_UPDATE_SUCCESS]: onAttachmentConfigUpdateSuccess,
  [AttachmentConfigTypes.ATTACHMENT_CONFIG_DELETE_SUCCESS]: onAttachmentConfigDeleteSuccess,
  [AttachmentConfigTypes.ATTACHMENT_CONFIG_SET_MESSAGE]: onAttachmentConfigSetMessage
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectAttachmentConfigs = state =>
  Immutable.asMutable(state.attachmentConfigReducer.attachmentConfigs, {
    deep: true
  });
export const selectAttachmentConfig = state =>
  state.attachmentConfigReducer.attachmentConfig;
export const selectIsLoading = state => state.attachmentConfigReducer.isLoading;
export const selectMessageOperation = state =>
  state.attachmentConfigReducer.messageOperation;
