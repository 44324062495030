import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {
	SaveOutlined,
} from '@material-ui/icons';

import { DatePicker } from "@material-ui/pickers";


// UI Components
import {
	withStyles,
	AppBar,
	Tab,
	Tabs,
	Grid,
	InputLabel,
	Button,
	Typography,
	CircularProgress,
	Hidden,
	FormControlLabel,
	Switch,
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import {
	Portlet,
	PortletContent,
	RichEditor,
	SaveContainer,
	TabPanel,
} from 'components';
import { TextField } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
	InfoRounded,
} from '@material-ui/icons';
import { tabProps } from 'components/TabPanel';

//Services
import { WithNotifications } from 'hoc';
import noticiesService from 'services/noticiesService';
import usersService from 'services/userService';

//Assets
import styles from './styles';

function NewsForm(props) {
	const emptyItem = {
		title: props.title || '',
		body: '',
		pinnedEnd: null
	};

	const {
		classes,
		match: {
			params: { id: propsId }
		},
		title,
	} = props;

	const [editing, setEditing] = useState(false);
	const [tab, setTab] = useState(0);
	const [item, setItem] = useState(emptyItem);
	const [loading] = useState(false);
	const [itemUpdate, setItemUpdate] = useState(new Date().toISOString());
	const [users, setUsers] = useState([]);
	const [id, setId] = useState(props.id);

	const init = async () => {
		loadItemFromUrl();

		usersService.getAll().then(response => {
			setUsers(response && response.data ? response.data.data : []);
		});
	};

	const cleanScreen = () => {
		setItem({ ...emptyItem });
		setTab(0);
		setEditing(false);
		setId(null);
	};

	const loadItemFromUrl = () => {

		if (!propsId || title != null) {
			setItem({ ...emptyItem });
			return;
		}

		setId(propsId);
		setEditing(true);

		noticiesService.getById(propsId).then(response => {
			if (
				!response.data ||
				response.data.status == 'Fail' ||
				!response.data.data
			) {
				props.history.push('/');
				return;
			}

			let item = response.data.data;

			setItemUpdate(new Date().toISOString());
			setItem(item);
		});

	};

	const save = async values => {
		let completeItem = {
			...values,
		};

		if (editing) {
			await update(completeItem);
		} else {
			await create(completeItem);
		}
	};

	const create = async item => {
		try {
			const response = await noticiesService.create(item);

			if (response.status != 200) {
				props.addNotification({
					message: 'Error guardando',
					options: { variant: 'error' }
				});
				return;
			}

			if (response.data.status == 'Fail') {
				props.addNotification({
					message: response.data.data.error,
					options: { variant: 'error' }
				});
				return;
			}

			props.addNotification({
				message: 'Aviso creado',
				options: { variant: 'success' }
			});

			if (title != null) {
				props.onCreated(response.data.data);
			} else {
				props.history.push(`/news/${response.data.data.id}`);
			}
		} catch (e) {
			props.addNotification({
				message: 'Error guardando',
				options: { variant: 'error' }
			});
		}
	};

	const update = async item => {
		try {
			item.id = id;
			const response = await noticiesService.update(item);

			if (response.status != 200) {
				props.addNotification({
					message: 'Error guardando',
					options: { variant: 'error' }
				});
				return;
			}

			if (response.data.status == 'Fail') {
				props.addNotification({
					message: response.data.data.error,
					options: { variant: 'error' }
				});
				return;
			}

			props.addNotification({
				message: 'Aviso actualizado',
				options: { variant: 'success' }
			});

			loadItemFromUrl();
		} catch (e) {
			props.addNotification({
				message: 'Error guardando',
				options: { variant: 'error' }
			});
		}
	};

	const renderTabs = () => {
		return (
			<div>
				<AppBar position="static" color="default">
					<Tabs
						value={tab}
						onChange={(_, tab) => setTab(tab)}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab icon={<InfoRounded />} {...tabProps(0)} />
					</Tabs>
				</AppBar>
				<TabPanel value={tab} index={0} boxStyle={{ paddingTop: '0px' }}>
					{renderForm()}
				</TabPanel>
			</div>
		);
	};

	console.log(users);

	const renderForm = () => {
		// if (item) {
		// 	createdBy = users.find(x => x.id == item.createdBy);
		// }

		return (
			<Formik
				key={itemUpdate}
				enableReinitialize
				initialValues={editing ? item : emptyItem}
				validationSchema={validationSchema}
				onSubmit={async (values, { setSubmitting }) => {
					await save(values);
					setSubmitting(false);
				}}
				render={({ submitForm, isSubmitting }) => ( // values
					<SaveContainer saveContent={submitForm}>
						<Form>
							<Grid container className={classes.root} spacing={1}>
								<Hidden mdDown>
									<Grid item xsOnly className={classes.item} md={7} lg={7}>
										<Field
											type="text"
											label="Titulo"
											name="title"
											margin="normal"
											component={TextField}
											fullWidth
											autoFocus
										/>
									</Grid>
								</Hidden>
								<Grid
									container
									direction="row"
									justifyContent="center"
									alignItems="center"
									xs={12}
									md={5}
									lg={4}
								>
									<Grid item xs={12} md={7} lg={7}>
										<Button
											size="small"
											component="button"
											variant="outlined"
											color="default"
											className={classes.button}
											onClick={submitForm}
											disabled={isSubmitting}
											style={{ marginLeft: '0px', marginTop: 10 }}
										>
											<SaveOutlined className={classes.leftIcon} />
											<Typography
												style={{ fontSize: '10px', marginLeft: '3px' }}
											>
												{editing ? 'Guardar' : 'Crear'}
											</Typography>
										</Button>
									</Grid>
									<Grid item xs={12} md={5} lg={5}>
										<Typography style={{ fontSize: '10px', marginLeft: '3px' }}>
											Fecha de Creación:{' '}
											{item && item.createdDate &&
												Moment(item.createdDate)
													.local()
													.fromNow()}
										</Typography>
										<Typography style={{ fontSize: '10px', marginLeft: '3px' }}>
											Fecha de modificación:{' '}
											{item && item.modifiedDate &&
												Moment(item.modifiedDate)
													.local()
													.fromNow()}
										</Typography>
									</Grid>
								</Grid>
								{
									editing &&
									<Grid item xs={12} md={6}>
										<FormControlLabel
											control={
												<Field
													label="Activo"
													name="isActive"
													component={(props) => (
														<Switch
															checked={props.field.value}
															value={props.field.name}
															onChange={(e, value) => props.form.setFieldValue(props.field.name, value)}
														/>
													)}
												/>
											}
											label="Activo"
										/>
									</Grid>
								}
								<Grid item xs={12} md={6}>
									<Field
										name="pinnedEnd"
										margin="normal"
										render={({ field, form }) => (
											<Grid
												direction="column"
												justifyContent="center"
												className={
													classNames(
														classes.inputRoot,
														{ "error": form.touched[field.name] && form.errors[field.name] }
													)
												}
											>
												<InputLabel>Dejar fijo hasta</InputLabel>
												<DatePicker
													clearable
													format="DD MMMM [del] YYYY"
													value={field.value}
													onChange={(date) => form.setFieldValue(field.name, date)}
												/>
												{
													form.touched[field.name] && form.errors[field.name] &&
													<p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
												}
											</Grid>
										)}
									/>
								</Grid>

								<Hidden smUp>
									<Grid item xs={12} className={classes.item}>
										<Field
											type="text"
											label="Titulo"
											name="title"
											margin="normal"
											component={TextField}
											fullWidth
											autoFocus
										/>
									</Grid>
								</Hidden>
								<Grid item xs={12} className={classes.item}>
									<Field
										name="body"
										render={({ field, form }) => (
											<div
												className={classNames(classes.selectRoot, {
													error:
														form.touched[field.name] && form.errors[field.name]
												})}
											>
												<InputLabel style={{ marginBottom: 10 }}>Cuerpo del aviso</InputLabel>
												<RichEditor
													placeholder="Cuerpo del aviso"
													value={field.value}
													onChange={value =>
														form.setFieldValue(field.name, value)
													}
													onBlur={() =>
														form.setFieldTouched(field.name, true, true)
													}
												/>
												{form.touched[field.name] &&
													form.errors[field.name] && (
														<p className="MuiFormHelperText-root Mui-error">
															{form.errors[field.name]}
														</p>
													)}
											</div>
										)}
									/>
								</Grid>
							</Grid>
						</Form>
					</SaveContainer>
				)}
			/>
		);
	};

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		if (propsId) {
			loadItemFromUrl();
		} else {
			cleanScreen();
		}
	}, [propsId]);

	let isLoading = loading;

	if (editing && !item && !loading) {
		isLoading = true;
	}

	const componentLayout = () => (
		<Portlet className={classes.root}>
			<PortletContent noPadding>
				{isLoading ? <CircularProgress /> : renderTabs()}
			</PortletContent>
		</Portlet>
	);

	if (title != null) {
		return componentLayout();
	}

	return (
		<DashboardLayout
			title={
				isLoading
					? ''
					: !editing
						? 'Nuevo aviso'
						: `Detalle de aviso ${item.id}`
			}
		>
			<div className={classes.root}>
				<div className={classes.content}>{componentLayout()}</div>
			</div>
		</DashboardLayout>
	);
}

NewsForm.propTypes = {
	classes: PropTypes.object.isRequired,
	id: PropTypes.number,
	title: PropTypes.string,
	onCreated: PropTypes.func,
	onBack: PropTypes.func
};

NewsForm.defaultProps = {
	id: null,
	onBack: () => { },
	onCreated: () => { },
	title: null
};

const validationSchema = Yup.object().shape({
	title: Yup.string()
		.max(100, 'Maximo 100 caracteres')
		.required('Titulo requerido'),
	body: Yup.string()
		.required('Cuerpo requerido'),
});

export default WithNotifications(withRouter(withStyles(styles)(NewsForm)));
