import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Typography, withStyles, CircularProgress, Divider, Grid } from '@material-ui/core';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";

import { cardTitle } from 'assets/jss/material-dashboard-pro-react/material-dashboard-pro-react';

import {
    Storefront,
} from "@material-ui/icons";

import requisitionsService from "services/requisitionsService";

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 4
    },
    item: {
        height: '100%'
    },
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
});

const RQDetailsCard = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        setLoading(true);

        try {
            const response = await requisitionsService.getDashboardInfo();
            setData(response.data.data);
        }
        catch (e) {
            console.log(e);
        }

        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const renderLoading = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>
        );
    };

    const formatCurrency = (number) => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(number);

    const renderContent = () => {
        if (!data)
            return null;

        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>Hoy</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>RQs: <strong>{data.todayRequisitions}</strong></Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>Pedido : <strong>{formatCurrency(data.todayRequisitionsTotal)}</strong></Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>Surtido : <strong>{formatCurrency(data.todayRequisitionsTotalSupplied)}</strong></Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Divider variant="middle" />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>Semana</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>RQs: <strong>{data.weekRequisitions}</strong></Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>Pedido : <strong>{formatCurrency(data.weekRequisitionsTotal)}</strong></Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>Surtido : <strong>{formatCurrency(data.weekRequisitionsTotalSupplied)}</strong></Typography>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <Card>
            <CardHeader color="primary" icon>
                <CardIcon color="primary">
                    <Storefront />
                </CardIcon>
                <Typography variant="h3" style={{ marginTop: 10 }}>Requisiciones recibidas</Typography>
            </CardHeader>
            <CardBody>
                {loading && renderLoading()}
                {!loading && renderContent()}
            </CardBody>
        </Card>
    );
};


RQDetailsCard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles)
)(RQDetailsCard);


/*

*/