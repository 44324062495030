import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Typography, withStyles, Divider, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import moment from 'moment';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react/material-dashboard-pro-react';

import {
    Announcement,
    RoomOutlined,
} from "@material-ui/icons";

import noticiesService from "services/noticiesService";

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 4
    },
    item: {
        height: '100%'
    },
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
});

const NoticiesCard = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        setLoading(true);

        try {
            const response = await noticiesService.getRecent();
            setData(response.data.data);
        }
        catch (e) {
            console.log(e);
        }

        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const renderLoading = () => {
        return (
            <>
                <Skeleton variant="circle" height={40} />
                <Skeleton variant="rect" height={118} />
            </>
        );
    };

    const renderNoNoticies = () => {
        return (
            <Typography variant="h6">
                No hay avisos creados
            </Typography>
        );
    };

    const renderContent = () => {
        if (!data || !data.length)
            return renderNoNoticies();

        return (
            <Grid container spacing={2}>
                {
                    data.map((i, ix) => (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} md={10}>
                                            <Typography variant="h6">
                                                {i.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Grid container>

                                                <Grid item xs={12}>
                                                    <Typography variant="caption">
                                                        {
                                                            i.pinnedEnd &&
                                                            <RoomOutlined />
                                                        }
                                                        <b>Publicado el:</b> {moment().format('DD/MM/YYYY')}
                                                    </Typography>
                                                </Grid>
                                                {/* <Grid item xs={12}>
                                                    <Typography variant="caption">
                                                        <b>Por: </b> Daniel Ballesteros
                                                    </Typography>
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="content" dangerouslySetInnerHTML={{ __html: i.body }} />
                                </Grid>
                                {ix !== data.length - 1
                                    &&
                                    <Grid item xs={12} style={{ marginTop: 10 }}>
                                        <Divider />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        );
    };

    return (
        <Card>
            <CardHeader color="primary" icon>
                <CardIcon color="primary">
                    <Announcement />
                </CardIcon>
                <Typography variant="h3" style={{ marginTop: 10 }}>Avisos</Typography>
            </CardHeader>
            <CardBody>
                {loading && renderLoading()}
                {!loading && renderContent()}
            </CardBody>
        </Card>
    );
};


NoticiesCard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles)
)(NoticiesCard);


/*

*/