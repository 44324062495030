import React, { Component } from 'react';
import compose from 'recompose/compose';
import { withSnackbar } from 'notistack';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, TextField } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
  SaveContainer
} from 'components';

// Component styles
import styles from './styles';

class AccountDetails extends Component {
  static propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSave: PropTypes.func
  };

  state = {
    pass: '',
    confirmPass: ''
  };

  handleChange = e => {
    this.setState({
      state: e.target.value
    });
  };

  validateSave = () => {
    const { onSave } = this.props;

    const { pass, confirmPass } = this.state;

    if (pass != confirmPass) {
      this.props.enqueueSnackbar('Contraseñas no conciden');
      this.confirmPass.focus();
    }

    onSave(pass);
  };

  render() {
    const { classes, className, ...rest } = this.props;
    const { pass, confirmPass } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Mi cuenta" />
        </PortletHeader>
        <PortletContent noPadding>
          <SaveContainer saveContent={this.validateSave}>
            <form autoComplete="off">
              <div className={classes.field}>
                <TextField
                  autoFocus
                  className={classes.textField}
                  helperText="Por favor especifica la nueva contraseña"
                  label="Contraseña"
                  margin="dense"
                  type="password"
                  required
                  value={pass}
                  variant="outlined"
                  onChange={e => this.setState({ pass: e.target.value })}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  className={classes.textField}
                  label="Confirmar contraseña"
                  type="password"
                  margin="dense"
                  required
                  value={confirmPass}
                  variant="outlined"
                  ref={i => (this.confirmPass = i)}
                  onChange={e => this.setState({ confirmPass: e.target.value })}
                />
              </div>
            </form>
          </SaveContainer>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="contained"
            onClick={this.validateSave}
            disabled={!pass || !confirmPass}
          >
            Actualizar contraseña
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

export default compose(
  withStyles(styles),
  withSnackbar
)(AccountDetails);
