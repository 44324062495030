import API from './Api';

class CompanyLocationService extends API {
  constructor(controller) {
    super(controller);
  }

  getIsValidEdit = companyLocationId => {
    return this.API.get(`${this.controller}/isvalidedit/${companyLocationId}`);
  };
}

let companyLocationService = new CompanyLocationService('companylocations');

export default companyLocationService;
