export const Messages = {
  400: 'messsage.http.method.400',
  404: 'messsage.http.method.404',
  409: 'messsage.http.method.409',
  500: 'messsage.http.method.500'
};

export const ResponseStatus = {
  fail: 'Fail'
};

export const MessageOperation = {
  success: { message: 'message.operation.success', type: 'success' },
  error: { message: 'message.operation.error', type: 'error' },
  conflict: { message: 'message.operation.conflict', type: 'warning' }
};

export const globalKeyMap = {
  //SAVE: { name: 'save form', sequence: 'Ctrl+s', action: 'keyup' }
  SAVE: { name: 'save form', sequence: 'Ctrl+s', action: 'keydown' }
};
