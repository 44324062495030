import API from "./Api";

class ClientsService extends API {

    constructor(controller) {
        super(controller);
    }

    getAllSimple = () => {
        return this.API.get(`${this.controller}/get-all-simple`);
    }

    getBillData = id => {
        return this.API.get(`${this.controller}/${id}/bill-data`);
    }

    getLunchRooms = (id) => {
        return this.API.get(`${this.controller}/${id}/lunch-rooms`);
    }

    getSalesReport = (id, lunchRoomId, year) => {
        return this.API.get(`${this.controller}/${id}/sales-report?lunchRoomId=${lunchRoomId}&year=${year}`);
    }

    getSalesReportResume = (id, lunchRoomId, year) => {
        return this.API.get(`/clients/${id}/item-prices/${year}/${lunchRoomId}`);
    }

    getSalesReportDetails = (id, lunchRoomId, year) => {
        return this.API.get(`clients/${id}/sales/${year}/${lunchRoomId}`);
    }

    getItemPricesReport = id => {
        return this.API.get(`${this.controller}/${id}/item-prices-report`);
    }

    getMenu = id => {
        return this.API.get(`${this.controller}/${id}/menu`);
    }

    getService = id => {
        return this.API.get(`${this.controller}/${id}/menu-shifts`);
    }

    getData = id => {
        return this.API.get(`${this.controller}/${id}`);
    }

    getExpenses = id => {
        return this.API.get(`expenses?clientId=${id}`);
    }

    getDocuments = clientId => {
        return this.API.get(`ClientContract/get-by-client/${clientId}`);
    }
}

let userService = new ClientsService("clients");

export default userService;