import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Swal from 'sweetalert2';

// UI Components
// import { DatePicker } from "@material-ui/pickers";
// import { formatNumber } from 'utils/numbers';

import {
	withStyles,
	Grid,
	CircularProgress,
	InputLabel,
	TextField,
	CardContent,
	Card,
	Button,
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';

import {
	Portlet,
	PortletContent,
	Select,
	Table,
} from 'components';


//Services
import { WithNotifications } from 'hoc';
import storagesService from 'services/storagesService';
import transformationsService from 'services/transformationsService';
// import suppliersService from 'services/suppliersService';
// import purchasesService from 'services/purchasesService';
// import purchasesReceiptService from 'services/purchasesReceiptService';

//Assets
import styles from './styles';
import { formatNumber } from 'utils/numbers';

function TransformationsForm(props) {
	const {
		classes,
		title,
		hideLayout,
		onSave,
	} = props;

	// const [branches, setBranches] = useState([]);
	const [items, setItems] = useState([]);
	const [baseItems, setBaseItems] = useState([]);
	const [details, setDetails] = useState([]);
	const [isManual, setIsManual] = useState(!props.itemId);
	const [statusId, setStatusId] = useState(null);
	const [id, setId] = useState(props.id);
	const [storageId] = useState(props.storageId);
	const [itemId, setItemId] = useState(props.itemId);
	const [item, setItem] = useState(null);
	const [baseItemId, setBaseItemId] = useState(null);
	const [quantityRequested, setQuantityRequested] = useState(props.quantity);
	const [quantityRequestedReal, setQuantityRequestedReal] = useState(props.quantity);
	const [baseQuantity, setBaseQuantity] = useState(null);
	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showModalItem, setShowModalItem] = useState(false);
	const [newItemId, setNewItemId] = useState(null);
	const [obs, setObs] = useState("");

	const [columns, setColumns] = useState();

	const [statuses] = useState([
		{ value: 0, label: 'Todo' },
		{ value: 1, label: 'Nueva' },
		{ value: 2, label: 'En Proceso' },
		{ value: 3, label: 'En Validación' },
		{ value: 4, label: 'Cerrada' },
	]);

	const refs = {};

	const init = async () => {
		// suppliersService.getInternal().then(response => {
		// 	setBranches(response && response.data ? response.data.data.map((i) => ({ value: i.supplierId, label: i.supplierName })) : []);
		// });

		getBaseItems();
		getItems();
	};

	const getItems = async () => {
		if (!storageId) {
			return;
		}

		const response = await storagesService.getItems(storageId);
		if (!response.ok || response.data.status == "Fail") {
			return;
		}

		const items = response.data.data.map((i) => ({
			value: i.itemId,
			label: i.detail,
		}));

		setItems(items);
	};

	const getBaseItems = async () => {
		if (!storageId) {
			return;
		}

		const response = await storagesService.getBaseItems(storageId);
		if (!response.ok || response.data.status == "Fail") {
			return;
		}

		const items = response.data.data.map((i) => ({
			value: i.itemId,
			label: i.detail,
		}));

		setBaseItems(items);
	};

	const loadItem = async () => {
		if (!id) {
			setIsManual(props.itemId == null);
			return;
		}

		setLoading(true);

		try {
			const response = await transformationsService.getById(id);
			if (response && response.data && response.data.data) {
				console.log("Transform", response.data.data);
				setStatusId(response.data.data.statusId);
				setBaseItemId(response.data.data.baseItemId);
				setBaseQuantity(response.data.data.baseQuantity);
				setQuantityRequested(response.data.data.requestedQuantity);
				setQuantityRequestedReal(response.data.data.baseRealQuantity || response.data.data.baseQuantity);
				setItemId(response.data.data.requestedItemId);
				setBaseItemId(response.data.data.baseItemId);
				setIsManual(!response.data.data.requestedItemId);
				setItem(response.data.data);

				let newDetails = [
					...response.data.data.details,
					{
						itemId: null,
						type: 98,
						quantity: response.data.data.cuttingQuantity || 0,
						quantityReal: response.data.data.cuttingQuantityReal || 0,
						itemDetail: "Recorte",
					},
					{
						itemId: null,
						type: 99,
						quantity: response.data.data.decreaseQuantity || "",
						quantityReal: response.data.data.decreaseQuantityReal || 0,
						itemDetail: "Merma",
					}
				];

				const allDetails = newDetails.map((i, index) => ({
					...i,
					id: 0,
					index,
					quantityReal: response.data.data.statusId < 3 ? 0 : response.data.data.statusId == 3 ? i.quantity : i.quantityReal,
				}));

				console.log("All details", allDetails);

				setDetails(allDetails);
			}
		}
		catch (e) {
			console.log(e);
		}

		setLoading(false);
	};

	useEffect(() => {
		init();
	}, [storageId]);

	useEffect(() => {
		let columns = [
			{
				title: "Producto",
				field: "itemDetail",
				editable: 'never',
			},
			{
				title: "Cantidad",
				field: 'quantity',
				render: renderRowInput,
			},
		];

		if (statusId >= 3) {
			columns.push({
				title: "Cantidad Real",
				field: 'quantityReal',
				render: renderRowInputReal
			});
		}

		setColumns(columns);
	}, [details]);

	useEffect(() => {
		loadItem();
	}, [id]);

	const validateOrder = (creating) => {
		if (creating) {
			if (baseQuantity > 1000)
				return false;

			if (details && details.length) {
				let totalQuantity = 0;
				details.forEach(i => totalQuantity += parseFloat(i.quantity || 0));

				if (totalQuantity != baseQuantity)
					return false;
			}
		}
		else {
			if (statusId >= 3 && !quantityRequestedReal || statusId < 3 && !baseQuantity) {
				return false;
			}

			let totalQuantity = 0;
			details.forEach(i => totalQuantity += parseFloat((statusId >= 3 ? i.quantityReal : i.quantity) || 0));

			if (totalQuantity != (statusId >= 3 ? quantityRequestedReal : baseQuantity))
				return false;
		}

		return true;
	};

	const getModel = (creating) => {
		let merma = 0;
		let recorte = 0;
		let mermaR = 0;
		let recorteR = 0;

		if (!creating) {
			const recorteItem = details.find(x => x.type == 98);
			recorte = recorteItem ? recorteItem.quantity : 0;
			recorteR = recorteItem ? recorteItem.quantityReal : 0;

			const mermaItem = details.find(x => x.type == 99);
			merma = mermaItem ? mermaItem.quantity : 0;
			mermaR = mermaItem ? mermaItem.quantityReal : 0;
		}

		const sumDetails = details.filter(d => !d.type).reduce(function (a, d) {
			return a + (+d.quantity);
		}, 0);

		const sumDetailsReal = details.filter(d => !d.type).reduce(function (a, d) {
			return a + (+d.quantityReal);
		}, 0);

		const model = {
			...(item || {}),
			productionOrderId: id || 0,
			statusId: creating ? 1 : statusId,
			branchId: creating ? props.branchId : item ? item.branchId : props.branchId,
			storageId: storageId,
			requestedItemId: creating ? itemId || null : item.requestedItemId,
			requestedQuantity: quantityRequested || null,
			baseItemId,
			baseQuantity,
			baseRealQuantity: statusId == 3 ? quantityRequestedReal : 0,
			measurementUnitId: 1,
			measurementUnitAbbr: "Kg",
			processedQuantity: baseQuantity,
			decreaseQuantity: merma,
			decreaseQuantityReal: statusId >= 3 ? mermaR : 0,
			cuttingQuantity: recorte,
			cuttingQuantityReal: statusId >= 3 ? recorteR : 0,
			total: statusId < 3 ? ((parseFloat(sumDetails) || 0) + (parseFloat(recorte) || 0) + (parseFloat(merma) || 0))
				: ((parseFloat(sumDetailsReal) || 0) + (recorteR || 0) + (parseFloat(mermaR) || 0)),
			observations: obs || "",
			baseNotUsedQuantity: statusId >= 3 ? mermaR : merma,
			decreasePercentage: porcMerma(),
			details: (details || [])
				.filter(x => x.itemId != null)
				.map((i) => ({
					...i,
				})),
		};

		if (creating && itemId) {
			const requestedItem = items.find(x => x.value == itemId);
			model.details = [{
				itemId: itemId,
				itemDetail: requestedItem.label,
				quantity: 0,
				isActive: true,
			}];
		}

		// eslint-disable-next-line
		console.log(model);

		return model;
	};

	const save = async () => {
		// eslint-disable-next-line
		debugger;

		const isCreating = statusId == null;

		if (saving || (!isCreating && (!details || !details.length)) || statusId === 4) {
			return;
		}

		setSaving(true);

		let isValid = validateOrder(isCreating);
		if (!isValid) {
			props.addNotification({ message: 'La suma de las cantidades no cuadra con el total de materia prima', options: { variant: "error" } });
			setSaving(false);
			return;
		}

		const model = getModel(isCreating);

		if (statusId == 2) {
			const result = await Swal.fire({
				title: "Quiere enviar la hoja a validación?",
				showCancelButton: true,
				confirmButtonText: "Si",
				cancelButtonText: "No",
			});

			if (result) {
				model.statusId += 1;
			}
		}
		else if (isCreating) {
			model.statusId = itemId ? 1 : 2;
		}
		else {
			model.statusId += 1;
		}

		try {
			let action = "";
			let actionOk = "";
			let response = null;
			if (isCreating) {
				action = "creando";
				actionOk = "creado";
				response = await transformationsService.create(model);
			}
			else if (model.statusId < 4) {
				action = "actualizando";
				actionOk = "actualizado";
				response = await transformationsService.update(model);
			}
			else if (model.statusId == 4) {
				action = "validando";
				actionOk = "validado";
				response = await transformationsService.validate(model);
			}

			if (!response || !response.data || !response.data.data || !response.data.data.result) {
				props.addNotification({ message: `Error ${action} hoja de produccion`, options: { variant: "error" } });
				setSaving(false);
				return;
			}
			else {
				props.addNotification({ message: `Se ha ${actionOk} la hoja de produccion`, options: { variant: "success" } });

				if (isCreating) {
					setId(response.data.data.result);
					loadItem();
				}
				else {
					onSave();
				}

			}
		} catch (e) {
			console.log(e);
		}

		setSaving(false);
	};

	const renderRowInput = (row) => {
		const type = 'B';
		console.log(row);
		return (
			<TextField
				disabled={statusId >= 3}
				key={`IX${row.index}-T${type}`}
				inputRef={i => refs[`input${row.index}-${type}`] = i}
				type="text"
				value={row.quantity || ""}
				onChange={(e) => {
					const value = e.target.value;

					if (isNaN(value))
						return;

					const newDetails = [...details];
					const detail = newDetails.find(x => x.index == row.index);
					detail.quantity = e.target.value;
					// getRowTotal(detail, type);
					setDetails(newDetails);
				}}
				onKeyPress={async (e) => {
					if (e.key == "Enter") {
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
				onKeyDown={e => {
					if (e.keyCode == 38) { //Up
						e.preventDefault();
						previousFocus(row.index, type);
					}
					else if (e.keyCode == 40) { //Down
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
			/>
		);
	};

	const renderRowInputReal = (row) => {
		const type = 'BR';
		return (
			<TextField
				disabled={statusId >= 4}
				key={`IX${row.index}-T${type}`}
				inputRef={i => refs[`input${row.index}-${type}`] = i}
				type="text"
				value={row.quantityReal || ""}
				onChange={(e) => {
					const value = e.target.value;

					if (isNaN(value))
						return;

					const newDetails = [...details];
					const detail = newDetails.find(x => x.index == row.index);
					detail.quantityReal = e.target.value;
					// getRowTotal(detail, type);
					setDetails(newDetails);
				}}
				onKeyPress={async (e) => {
					if (e.key == "Enter") {
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
				onKeyDown={e => {
					if (e.keyCode == 38) { //Up
						e.preventDefault();
						previousFocus(row.index, type);
					}
					else if (e.keyCode == 40) { //Down
						e.preventDefault();
						nextFocus(row.index, type);
					}
				}}
			/>
		);
	};

	const previousFocus = (index, type) => {
		const prev = index - 1;

		if (prev < 0) {
			return;
		}

		if (refs[`input${prev}-${type}`] != null) {
			const input = refs[`input${prev}-${type}`];
			input.focus();
			input.setSelectionRange(0, input.value.length);
		}
		else {
			previousFocus(prev, type);
		}
	};

	const nextFocus = (index, type) => {
		let next = index + 1;

		if (next > details.length) {
			return;
		}

		if (refs[`input${next}-${type}`] != null) {
			const input = refs[`input${next}-${type}`];
			input.focus();
		}
		else {
			nextFocus(next, type);
		}
	};

	const addItem = () => {
		const detail = details.find(x => x.itemId == newItemId);

		if (detail != null) {
			props.addNotification({ message: 'El producto ya esta agregado a la hoja de producción', options: { variant: "error" } });
			return;
		}

		// debugger;

		let addedDetails = details.filter(x => x.itemId != null);
		let otherDetails = details.filter(x => x.itemId == 427 || x.itemId == 424 || x.itemId == null);

		addedDetails.push({
			id: 0,
			itemId: newItemId,
			itemDetail: items.find(x => x.value == newItemId).label,
			quantity: null,
		});

		const newDetails = [...addedDetails, ...otherDetails];

		setDetails(newDetails.map((i, index) => ({
			...i,
			index,
		})));

		setNewItemId(null);
		setShowModalItem(false);
	};

	const renderItemModal = () => {
		return (
			<Modal
				title="Agregar producto transformado"
				isOpen={showModalItem}
				onRequestClose={() => {
					setShowModalItem(false);
					setNewItemId(null);
				}}
				style={{ width: '410px', height: '240px', padding: 10 }}
			>
				<form className="contracts-form">
					<Grid container className={classes.root} spacing={2} style={{ paddingTop: 10 }}>
						<Grid item xs={12}>
							<Card>
								<CardContent>
									<Grid container className={classes.root} spacing={1}>
										<Grid item className={classes.item} xs={12}>
											<InputLabel>Producto</InputLabel>
											<Select
												isClearable
												value={newItemId}
												onChange={(selected) => {
													if (selected && selected.value) {
														setNewItemId(selected.value);
													} else {
														setNewItemId(null);
													}
												}}
												onClear={() => {
													setNewItemId(null);
												}}
												options={items}
												styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
												menuPortalTarget={document.body}
												menuPosition={'absolute'}
												menuPlacement={'bottom'}
											/>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Grid container className={classes.root} spacing={1}>
								<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button disabled={!newItemId} variant="outlined" color="primary" onClick={addItem}>
										Agregar
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</Modal>
		);
	};

	const porcMerma = () => {
		if (!id || !details || !details.length || (!baseQuantity && !quantityRequestedReal))
			return 0;

		let quantityField = 'quantity';

		if (statusId >= 3) {
			quantityField = 'quantityReal';
		}

		const mermaQty = details.find(i => i.itemId == null && i.type == 99)[quantityField];
		return mermaQty * 100 / (statusId >= 3 ? quantityRequestedReal : baseQuantity);
	};

	const renderForm = () => {
		return (
			<form className="contracts-form">
				{
					loading
					&& <CircularProgress />
				}
				{renderItemModal()}
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 10 }}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									{!isManual &&
										<Grid item className={classes.item} xs={12} md={6} lg={3}>
											<InputLabel>Solicitado</InputLabel>
											<Select
												isDisabled
												isClearable
												value={itemId}
												onChange={(selected) => {
													if (selected && selected.value) {
														setItemId(selected.value);
													} else {
														setItemId(null);
													}
												}}
												onClear={() => {
													setItemId(null);
												}}
												options={items}
												styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
												menuPortalTarget={document.body}
												menuPosition={'absolute'}
												menuPlacement={'bottom'}
											/>
										</Grid>
									}
									{!isManual &&
										<Grid item className={classes.item} xs={12} md={6} lg={3}>
											<TextField
												label="Cant."
												value={quantityRequested}
												InputLabelProps={{ shrink: true }}
												onChange={(e) => {
													setQuantityRequested(e.target.value);
												}}
											/>
										</Grid>
									}
									{!isManual && <Grid item className={classes.item} xs={12} md={6} lg={6} />}

									<Grid item className={classes.item} xs={12} md={6} lg={3}>
										<InputLabel>Materia prima base</InputLabel>
										<Select
											isClearable
											value={baseItemId}
											onChange={(selected) => {
												if (selected && selected.value) {
													setBaseItemId(selected.value);
												} else {
													setBaseItemId(null);
												}
											}}
											onClear={() => {
												setBaseItemId(null);
											}}
											options={baseItems}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={3}>
										<TextField
											disabled={statusId >= 3}
											label="Cant."
											value={baseQuantity}
											InputLabelProps={{ shrink: true }}
											onChange={(e) => {
												setBaseQuantity(e.target.value);
											}}
										/>
									</Grid>
									{
										statusId >= 3 &&
										<Grid item className={classes.item} xs={12} md={6} lg={3}>
											<TextField
												disabled={statusId >= 4}
												label="Cant. Real"
												value={quantityRequestedReal}
												InputLabelProps={{ shrink: true }}
												onChange={(e) => {
													setQuantityRequestedReal(e.target.value);
												}}
											/>
										</Grid>
									}
									{
										id && statusId &&
										<Grid item className={classes.item} xs={12} md={6} lg={6}>
											<InputLabel>Status</InputLabel>
											<Select
												isDisabled
												isClearable
												value={statusId}
												onChange={(selected) => {
													if (selected && selected.value) {
														setStatusId(selected.value);
													} else {
														setStatusId(null);
													}
												}}
												onClear={() => {
													setStatusId(null);
												}}
												options={statuses}
												styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
												menuPortalTarget={document.body}
												menuPosition={'absolute'}
												menuPlacement={'bottom'}
											/>
										</Grid>
									}
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					{
						id &&
						<Grid item xs={12}>
							<Grid container className={classes.root} spacing={2}>
								<Grid item className={classes.item} xs={12}>
									<Table
										title=""
										isLoading={loading}
										columns={columns}
										items={details}
										options={{
											paging: false,
											search: false,
											selection: false,
										}}
										actions={[
											{
												icon: 'add',
												isFreeAction: true,
												tooltip: "Agregar producto",
												onClick: () => setShowModalItem(true),
											}
										]}
									/>
								</Grid>
								<Grid item className={classes.item} xs={12} style={{ padding: 20 }}>
									<TextField
										multiline
										rows={3}
										value={obs}
										label="Observaciones"
										fullWidth
										onChange={e => setObs(e.target.value)}
									/>
								</Grid>
								{
									statusId == 3 &&
									<Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
										Merma = {formatNumber(porcMerma())}%
									</Grid>

								}
								{
									statusId == 3 &&
									<Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
										{
											details && details.length > 0 &&
											<Button
												variant="outlined" color="primary" onClick={save}
											>
												Validar y cerrar
											</Button>
										}
									</Grid>
								}
								{
									statusId < 3 &&
									<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
										{
											details && details.length > 0 &&
											<Button variant="outlined" color="primary" onClick={save}>
												Guardar
											</Button>
										}
									</Grid>
								}
							</Grid>
						</Grid>
					}
					{
						!id &&
						<Grid item xs={12}>
							<Grid container className={classes.root} spacing={1}>
								<Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button
										variant="outlined"
										color="secondary"
										onClick={save}
										disabled={!baseQuantity || !baseItemId}
									>
										Programar producción
									</Button>
								</Grid>
							</Grid>
						</Grid>
					}
				</Grid>
			</form>
		);
	};

	const componentLayout = () => (
		<Portlet className={classes.root}>
			<PortletContent noPadding>
				{
					loading
					&& <CircularProgress />
				}
				{renderForm()}
			</PortletContent>
		</Portlet>
	);

	if (title != null) {
		return componentLayout();
	}

	if (hideLayout) {
		return componentLayout();
	}

	return (
		<DashboardLayout
			title={"Hoja producción transformación"}
		>
			<div className={classes.root}>
				<div className={classes.content}>{componentLayout()}</div>
			</div>
		</DashboardLayout>
	);
}

TransformationsForm.propTypes = {
	classes: PropTypes.object.isRequired,
	id: PropTypes.number,
	title: PropTypes.string,
	onCreated: PropTypes.func,
	onBack: PropTypes.func,
	itemId: PropTypes.number,
	quantity: PropTypes.number,
	storageId: PropTypes.number,
};

TransformationsForm.defaultProps = {
	id: null,
	itemId: null,
	quantity: null,
	stoargeId: null,
	onBack: () => { },
	onCreated: () => { },
	title: null
};

export default WithNotifications(withRouter(withStyles(styles)(TransformationsForm)));
