import { takeLatest, call, put, all } from "redux-saga/effects";
import { CityTypes, CityCreators } from "redux/CityRedux";
import cityService from "services/cityService";
import City from "contract/responses/City";

export function* getAll() {
	yield put(CityCreators.setLoading(true));

	const response = yield call(cityService.getAll);

	if (response.ok) {
		yield put(CityCreators.cityListSuccess(response.data.data.map(item => new City(item))));
	} else {
		yield put(CityCreators.cityListFailure(response.problem));
	}

	yield put(CityCreators.setLoading(false));
}

export function* getCitiesByStateId({ id }) {
	//  yield put(CityCreators.setLoading(true))

	const response = yield call(cityService.getCitiesByStateId, id);

	if (response.ok) {
		yield put(CityCreators.cityListSuccess(response.data.data.map(item => new City(item))));
	} else {
		yield put(CityCreators.cityListFailure(response.problem));
	}

	//  yield put(CityCreators.setLoading(false))
}

/**
 * sagas 
 */
function* citySagas() {
	yield all([
		takeLatest(CityTypes.CITY_LIST_STATE_ID_REQUEST, getCitiesByStateId),
		takeLatest(CityTypes.CITY_LIST_REQUEST, getAll)
	]);
}

export default citySagas;