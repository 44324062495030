import API from './Api';

class subCategoryAttachmentConfigService extends API {
  constructor(controller) {
    super(controller);
  }

  getByAttachmentConfigId = id => {
    return this.API.get(`${this.controller}/attachmentconfig/${id}`);
  };
}

let service = new subCategoryAttachmentConfigService(
  'subcategoryattachmentconfig'
);

export default service;
