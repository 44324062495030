import React from 'react';
import TrackVisibility from 'react-on-screen';

class ControlSave extends React.Component {
  handleNVEvent = e => {
    const { saveContent } = this.props;

    if (
      e.keyCode == 83 &&
      (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)
    ) {
      if (document.activeElement != document.body)
        document.activeElement.blur();
      e.preventDefault();
      saveContent();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleNVEvent, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleNVEvent, false);
  }
  
  shouldComponentUpdate(nextProps){

if(nextProps.isVisible)
{
  document.addEventListener('keydown', this.handleNVEvent, false);
} else {
  document.removeEventListener('keydown', this.handleNVEvent, false);
}

    return true;
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

const SaveContainer = props => {
  return (
    <TrackVisibility>
      {({ isVisible }) => <ControlSave {...props} isVisible={isVisible} />}
    </TrackVisibility>
  );
};

export default SaveContainer;
