import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./../redux";
import sagas from "sagas";

const sagaMiddleware = createSagaMiddleware();

/*eslint-disable */
const logger = store => next => action => {
	console.log("dispatching", action);
	console.log("next state", store.getState());
	let result = next(action);
	return result;
}

/*eslint-enable */
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(logger, sagaMiddleware)));

sagaMiddleware.run(sagas);

export default store;