import API from './Api';

class AttachmentConfigService extends API {
  constructor(controller) {
    super(controller);
  }

  saveMultiple = attachmentConfig => {
    return this.API.post(`${this.controller}/savemultiple`, attachmentConfig);
  };
}

let service = new AttachmentConfigService('attachmentconfig');

export default service;
