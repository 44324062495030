import API from "./Api";

class CategoryLocationService extends API {

    constructor(controller){
        super(controller);
    }

    saveMultiple = (items) => {
		return this.API.post(`${this.controller}/save-multiple`, items);
	}

	getByOwnerId = (categoryId, ownerId) => {
		return this.API.get(`${this.controller}/category/${categoryId}/owner/${ownerId}`);
    }
    
    getByCategoryId = (categoryId) => {
		return this.API.get(`${this.controller}/category/${categoryId}`);
	}
}

let categoryLocationService = new CategoryLocationService("categoryLocations");

export default categoryLocationService;