import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Avatar, Typography } from '@material-ui/core';

// Shared components
import { Portlet, PortletContent } from 'components';

// Component styles
import styles from './styles';

class AccountProfile extends Component {
	render() {
		const { classes, className, session, ...rest } = this.props;

		const rootClassName = classNames(classes.root, className);

		return (
			<Portlet
				{...rest}
				className={rootClassName}
			>
				<PortletContent>
					<div className={classes.details}>
						<div className={classes.info}>
							<Typography variant="h2">{session.userName}</Typography>
							<Typography
								className={classes.locationText}
								variant="body1"
							>
								{session.email}
							</Typography>
							<Typography
								className={classes.dateText}
								variant="body1"
							>
								{moment().format("hh:mm A")}
							</Typography>
						</div>
						<Avatar
							className={classes.avatar}
							src="/images/avatars/avatar_generic_0.png"
						/>
					</div>
				</PortletContent>
			</Portlet>
		);
	}
}

AccountProfile.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired,
	session: PropTypes.object
};

export default withStyles(styles)(AccountProfile);
