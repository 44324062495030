import Api from "./Api";

class LunchRoomsService extends Api {

    constructor(controller) {
        super(controller);
    }

    getLocalUnits = (date) => {
        let url = `${this.controller}/get-local-with-rq?date=${date.toJSON()}`;
        return this.API.get(url);
    }

    search = (routeId, date) => {
        let url = `${this.controller}/search?routeId=${routeId}`;

        if (date) {
            if (typeof date == 'object')
                url += `&date=${date.toJSON()}`;
            else
                url += `&date=${date}`;
        }

        return this.API.get(url);
    }

    getLunchRoomsByBranchOffice = (branchOfficeId) => {
        let url = `${this.controller}/search/by-branch-office?branchOfficeId=${branchOfficeId}`;
        return this.API.get(url);
    }

    saveLunchRoomsInfo = (model) => {
        return this.API.put(`${this.controller}/info`, model);
    }
}

let service = new LunchRoomsService("lunch-rooms");

export default service;