import API from './Api';

class TaskTypesService extends API {
  constructor(controller) {
    super(controller);
  }
}

let service = new TaskTypesService('tasktypes');

export default service;
