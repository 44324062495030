import * as React from 'react';
import AsyncSelect from 'react-select/async';

const SelectAsyncComponent = ({
    defaultOptions,
    value,
    onChange,
    placeholder,
    noOptionsMessage,
    ...props
}) => {
    const getObjectFromId = id => {
        return defaultOptions.find(x => x.value == id);
    };

    defaultOptions = defaultOptions || [];

    props = {
        ...props,
        noOptionsMessage: noOptionsMessage ? noOptionsMessage : ({inputValue}) => !inputValue ? "Escribe para buscar opciones" : "No hay opciones",
        placeholder: placeholder || 'Seleccionar',
        onChange: selected => {
            onChange(selected || { value: null });
        },
        value: getObjectFromId(value),
        isMulti: false,
        defaultOptions
    };

    return <AsyncSelect {...props} />;
};

export default SelectAsyncComponent;
