import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Divider, Typography } from '@material-ui/core';
import moment from 'moment';

// Component styles
const styles = theme => ({
	root: {
		padding: theme.spacing.unit * 4
	},
	company: {
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 0.5
	}
});

class Footer extends Component {
	render() {
		const { classes, className } = this.props;

		const rootClassName = classNames(classes.root, className);

		return (
			<div className={rootClassName}>
				<Divider />
				<Typography
					className={classes.company}
					variant="body1"
				>
					&copy; {moment().format("YYYY")} SEA - Todos los derechos reservados
				</Typography>
				<Typography variant="caption" />
			</div>
		);
	}
}

Footer.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);