import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import Modal from 'components/Modal';
import ViewRequisition from "views/ViewRequisition";
// UI Components
import {
    withStyles,
    Grid,
    // CircularProgress,
    InputLabel,
    Button,
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select } from 'components';
import { DatePicker } from "@material-ui/pickers";

//Services
import { WithTicket, WithSession, WithNotifications } from "hoc";

import deliveriesService from "services/deliveriesService";
import routesService from "services/routesService";
import driversService from "services/driversService";
import vehiclesService from "services/vehiclesService";
//Assets
import styles from './styles';
import { CheckOutlined, DescriptionOutlined, PictureAsPdfOutlined } from '@material-ui/icons';

class SearchDeliveries extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        requisitions: [],
        startDate: Moment().startOf('day').toDate(),
        endDate: Moment.utc().endOf('day').toDate(),
        vehicleId: 0,
        driverId: 0,
        routeId: 0,
        loading: false
    };

    columns = [
        {
            title: "Fecha timbre",
            field: 'stampDate',
            render: row => {
                return (
                    <span>{Moment(row.stampDate).format("DD/MM/YYYY")}</span>
                );
            }
        },
        {
            title: "Fecha pedido",
            field: 'deliveryDate',
            render: row => {
                return (
                    <span>{Moment(row.deliveryDate).format("DD/MM/YYYY")}</span>
                );
            }
        },
        {
            title: "Camión",
            field: 'vehicleName',
        },
        {
            title: "Chofer",
            field: 'driverName',
        },
        {
            title: "Ruta",
            field: 'routeName',
        },
        {
            title: "UUID",
            field: 'uuid',
        },
        {
            title: "Carta porte",
            field: 'applyConsigmentNoteString',
            render: row => {
                if (!row.applyConsigmentNote)
                    return null;

                return (
                    <CheckOutlined />
                );
            }
        },
        {
            title: "Documentos",
            field: 'actions',
            export: false,
            render: (row) => <span>
                <Button
                    variant="outlined"
                    color="primary"
                    component={Link}
                    to={{ pathname: `${window.env.API_URL}reports/cfdi-delivery?deliveryId=${row.id}` }}
                    style={{ margin: 10 }}
                    target="_blank"
                    download
                >
                    <PictureAsPdfOutlined />
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    component={Link}
                    to={{ pathname: `${window.env.API_URL}cfdi/${row.cfdiId}/xml` }}
                    target="_blank"
                    download
                >
                    <DescriptionOutlined />
                </Button>
            </span>
        }
    ];

    statuses = [
        { value: 0, label: "Todos" },
        { value: 1, label: "Por Recibir" },
        { value: 2, label: "Recibidos por revisar" },
        { value: 3, label: "Recibidos" },
        { value: 4, label: "Confirmados" },
        { value: 5, label: "Embarcados" },
        // { value: 6, label: "Cancelados" },
        { value: 7, label: "Entregados" },
        { value: 8, label: "Facturados" }
    ]

    componentDidMount = () => {
        this.getCatalogs();
        this.search();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            startDate,
            endDate,
            vehicleId,
            driverId,
            routeId,
        } = this.state;

        if (prevState.endDate != endDate && endDate < startDate)
            this.setState({ startDate: endDate }, this.search);
        else if (prevState.startDate != startDate && startDate > endDate)
            this.setState({ endDate: startDate }, this.search);
        else if (prevState.startDate != startDate || prevState.endDate != endDate || prevState.vehicleId != vehicleId || prevState.driverId != driverId || prevState.routeId != routeId)
            this.search();
    }

    getCatalogs = async () => {
        const routesPromise = routesService.getAll();
        const vehiclesPromise = vehiclesService.getAll();
        const driversPromise = driversService.getAll();

        let [
            routesResponse,
            vehiclesResponse,
            driversResponse,
        ] = await Promise.all([
            routesPromise,
            vehiclesPromise,
            driversPromise,
        ]);

        if (routesResponse.ok && routesResponse.data.status != "Fail") {
            const routes = routesResponse.data.data.filter(x => x.generateConsignmentNote).map(i => ({ value: i.routeId, label: i.name }));
            this.setState({ routes: [{ value: 0, label: "Todas" }, ...routes, { value: 999999999, label: "Rutas especiales" }], originalRoutes: routesResponse.data.data });
        }

        if (vehiclesResponse.ok && vehiclesResponse.data.status != "Fail") {
            const vehicles = vehiclesResponse.data.data.map(i => ({ value: i.id, label: `${i.brand} ${i.model} ${i.year} | Placas: ${i.plate} | #${i.transportCode}` }));
            this.setState({ vehicles: [{ value: 0, label: "Todos" }, ...vehicles], originalVehicles: vehiclesResponse.data.data });
        }

        if (driversResponse.ok && driversResponse.data.status != "Fail") {
            const drivers = driversResponse.data.data.map(i => ({ value: i.employeeId, label: `${i.name} ${i.lastName1} ${i.lastName2}` }));
            this.setState({ drivers: [{ value: 0, label: "Todos" }, ...drivers], originalDrivers: driversResponse.data.data });
        }
    }

    getStatus = (row) => {
        const status = this.statuses.find(x => x.value == row.statusId);
        return status ? status.label.replace(/s*$/, "") : "";
    }

    search = async () => {
        const {
            startDate,
            endDate,
            vehicleId,
            driverId,
            routeId,
        } = this.state;

        this.setState({ loading: true });

        const response = await deliveriesService.search(startDate, endDate, vehicleId, driverId, routeId === 999999999 ? null : routeId);
        this.setState({ loading: false });

        if (response.ok && Array.isArray(response.data.data)) {
            this.setState({
                items: response.data.data.map((i) => ({
                    ...i,
                    stampDate: i.cfdi.stampDate,
                    vehicleName: `${i.vehicle.brand} ${i.vehicle.model} ${i.vehicle.year} | Placas: ${i.vehicle.plate} | #${i.vehicle.transportCode}`,
                    driverName: `${i.driver.driverName}`,
                    routeName: i.route ? `${i.route.name}` : "Ruta especial",
                    applyConsigmentNoteString: i.applyConsigmentNote ? "Si" : "No",
                    uuid: i.cfdi ? i.cfdi.uuid : "",
                }))
            });
        }
        else {
            this.props.addNotification({ message: "Error realizando busqueda", options: { variant: "error" } });
        }
    }

    getTitle = () => {
        return "Buscador de envíos";
    }

    renderFilters = () => {
        const {
            startDate,
            endDate,
            vehicleId,
            driverId,
            routeId,
            vehicles,
            drivers,
            routes,
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha inicial</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={startDate}
                            onChange={startDate => this.setState({ startDate })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha final</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={endDate}
                            onChange={endDate => this.setState({ endDate })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >

                        <InputLabel>Camión</InputLabel>
                        <Select
                            value={vehicleId}
                            onChange={(selected) => {
                                this.setState({ vehicleId: selected.value });
                            }}
                            options={vehicles}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} lg={2} alignContent="center" className={classes.item}>
                    <InputLabel>Chofer</InputLabel>
                    <Select
                        value={driverId}
                        onChange={(selected) => {
                            this.setState({ driverId: selected.value });
                        }}
                        options={drivers}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition={'absolute'}
                        menuPlacement={'bottom'}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={2} alignContent="center" className={classes.item}>
                    <InputLabel>Ruta</InputLabel>
                    <Select
                        value={routeId}
                        onChange={(selected) => {
                            this.setState({ routeId: selected.value });
                        }}
                        options={routes}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition={'absolute'}
                        menuPlacement={'bottom'}
                    />
                </Grid>
            </Grid>
        );
    }

    renderTable = () => {
        const {
            items,
            selectedItems,
            copiyng,
            loading
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    {this.renderFilters()}
                    <Table
                        columns={this.columns}
                        items={copiyng ? selectedItems : items}
                        options={{
                            pageSize: copiyng ? 100 : 10,
                            toolbar: true,
                            search: false,
                            exportButton: true,
                            exportFileName: "Traslados",
                        }}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                        isLoading={loading}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderViewModal = () => {
        const {
            requisitionIdToShow
        } = this.state;

        return (
            <Modal
                title="Detalle de requisición"
                isOpen={this.state.requisitionIdToShow != null}
                onRequestClose={() => {
                    this.setState({ requisitionIdToShow: null });
                }}
                style={{ width: '95%', height: '85%' }}
            >
                <ViewRequisition
                    id={requisitionIdToShow}
                    hideLayout
                    onClose={() => this.setState({ requisitionIdToShow: null })}
                />
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {this.renderViewModal()}
                                {this.renderTable()}
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithNotifications(WithSession(WithTicket(withStyles(styles)(SearchDeliveries))))));