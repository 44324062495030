export default theme => {
    return {
        root: {
            paddingTop: "0px",
            // padding: theme.spacing.unit * 3
        },
        content: {
            marginTop: theme.spacing.unit * 3
        },
        row: {

        },
        columnGrid: {
            height: '500px'
        },
        giantIcon: {
            width: '70px',
            height: '70px'
        },
        button: {
            marginRight: theme.spacing(1),
        },
        leftIcon: {
            marginRight: theme.spacing(0.2),
            fontSize: 22,
        },
        input: {
            display: 'none',
        },
        first: {
            borderRight: '1px solid #eee',
            marginTop: theme.spacing.unit,
            marginBottom: theme.spacing.unit,
        },
        second: {
            paddingLeft: '20px!important',
            marginTop: theme.spacing.unit,
            marginBottom: theme.spacing.unit,
        },
        item: {
            marginTop: theme.spacing.unit,
            marginBottom: theme.spacing.unit,
        },
        // itemSpacing: {
        //     marginTop: theme.spacing.unit * 2,
        //     marginBottom: theme.spacing.unit * 2,
        // },
        listRoot: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        inline: {
            display: 'inline'
        },
        historyList: {
            height: '600px',
            borderRight: '1px solid #ddd',
            overflowX: 'auto'
        },
        historyItem: {
            cursor: 'pointer'
        },
        historyDetails: {
            height: '600px',
            overflow: 'auto'
        },
        historyTitle: {
            width: "100%"
        },
        oldDiv: {
            ...theme.typography.button,
            backgroundColor: "#fce6e6",
            padding: theme.spacing(1),
            textTransform: 'none'
        },
        newDiv: {
            ...theme.typography.button,
            backgroundColor: "#e3ffe4",
            padding: theme.spacing(1),
            textTransform: 'none'
        },
        realtionshipsModalContainer: {
            padding: '25px',
        },
        discussionMessage: {
            marginBottom: theme.spacing(1)
        },
        discussionMessages: {
            marginTop: '-20px'
        },
        inputLabel: {
            marginBottom: theme.spacing.unit * 1,
            fontSize: 14
        }
    };
};
