import Api from "./Api";

class BranchOfficesService extends Api {
    constructor(controller) {
        super(controller);
    }
}

let service = new BranchOfficesService("branch-offices");

export default service;