import EntityBase from '../EntityBase';

class Deparment extends EntityBase {
  constructor(obj) {
    if (!obj) obj = {};
    super(obj);
    this.code = obj.code ? obj.code : '';
    this.detail = obj.detail ? obj.detail : '';
    this.ownerId = obj.ownerId ? obj.ownerId : undefined;
    this.companyId = obj.companyId ? obj.companyId : undefined;
  }
}

export default Deparment;
