import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions({
    priorityGetAllRequest: [],
    priorityGetAllSuccess: ['items'],
    priorityGetAllFailure: ['error'],
    setLoading: ["isLoading"]
}, {});

export const PriorityTypes = Types;
export const PriorityCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
    priorities: [],
    isLoading: false
});

export const onGetAll = (state, { items }) => state.merge({ priorities: items });
export const onSetLoading = (state, { isLoading }) => state.merge({ isLoading });

//map our actions types to our reducer functions
export const HANDLERS = {
    [PriorityTypes.PRIORITY_GET_ALL_SUCCESS]: onGetAll,
    [PriorityTypes.SET_LOADING]: onSetLoading,
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectPriorities = state => Immutable.asMutable(state.priorityReducer.priorities.filter(x => x.isActive), { deep: true });
export const setLoading = state => state.priorityReducer.isLoading;