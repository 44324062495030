import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions({
	responseTimeTypeListRequest: [],
	responseTimeTypeListSuccess: ['responseTimeTypes'],
	responseTimeTypeListFailure: ['error'],
	setLoading: ["isLoading"]
}, {});

export const ResponseTimeTypes = Types;
export const ResponseTimeTypesCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
	responseTimeTypes: [],
	isLoading: false
});

export const onResponseTimeTypeListSuccess = (state, { responseTimeTypes }) => state.merge({ responseTimeTypes });
export const onSetLoading = (state, { isLoading }) => state.merge({ isLoading });

//map our actions types to our reducer functions
export const HANDLERS = {
	[ResponseTimeTypes.RESPONSE_TIME_TYPE_LIST_SUCCESS]: onResponseTimeTypeListSuccess,
	[ResponseTimeTypes.SET_LOADING]: onSetLoading
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectResponseTimeTypes = state => Immutable.asMutable(state.responseTimeTypeReducer.responseTimeTypes, { deep: true });
export const getLoading = state => state.responseTimeTypeReducer.isLoading;