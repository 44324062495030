import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import qs from 'qs';

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table } from 'components';

//Services
import { WithSession } from "hoc";
import noticiesService from "services/noticiesService";

//Assets
import styles from './styles';

class NewsList extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        items: [],
        selectedItems: []
    };

    columns = [
        {
            title: "Id",
            field: 'id',
            render: row => {
                return (
                    <Link to={`/news/${row.id}`}>
                        {row.title}
                    </Link>
                );
            }
        },
        {
            title: "Titulo",
            field: 'title',
            render: row => {
                return (
                    <Link to={`/news/${row.id}`}>
                        {row.title}
                    </Link>
                );
            }
        },
        // {
        //     title: "Creador",
        //     field: 'createdBy',
        //     render: row => {
        //         console.log(this.props);
        //         const user = this.props.users ? this.props.users.find(x => x.id == row.createdBy) : null;
        //         return (
        //             <span>{user ? user.displayName : ""}</span>
        //         );
        //     }
        // },
        {
            title: "Actualización",
            field: 'createdDate',
            render: row => {
                return (
                    <span>
                        {
                            row.modifiedDate
                                ? Moment.utc(row.modifiedDate).local().fromNow()
                                : Moment.utc(row.createdDate).local().fromNow()}
                    </span>
                );
            }
        }
    ];

    componentDidMount = () => {
        this.getItems();
        this.getFiltersFromUrl();
    }

    componentDidUpdate = (prevProps) => {
        const {
            location: {
                search,
                pathname
            }
        } = this.props;

        if (search != prevProps.location.search) {
            this.getFiltersFromUrl();
        }

        if (pathname != prevProps.location.pathname) {
            this.getItems();
        }
    }

    getItems = () => {
        noticiesService.getAll().then((response) => {
            if (!response.data || response.data.status == "Fail" || !response.data.data || !Array.isArray(response.data.data)) {
                return;
            }

            this.setState({ items: response.data.data }, () => {
            });
        });
    }

    getFiltersFromUrl = () => {
        const {
            location: {
                search
            }
        } = this.props;

        var qsParams = qs.parse(search.replace("?", ""));

        if (qsParams.filter) {
            this.setState({ filter: qsParams.filter });
        }
    }

    getTitle = () => {
        return "Avisos";
    }

    renderTable = () => {
        const {
            items,
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        columns={this.columns}
                        items={items}
                        options={{ pageSize: 10, selection: false }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading,
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {
                                    isLoading
                                        ? <CircularProgress />
                                        : this.renderTable()
                                }
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithSession(withStyles(styles)(NewsList))));