import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
//import Yup from 'yup';
import Moment from 'moment';
import classNames from "classnames";

// UI Components
import {
    withStyles,
    Grid,
    Typography,
    // CircularProgress,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Button,
    InputLabel,
    Icon,
    TextField
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select, SelectAsync } from 'components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker } from "@material-ui/pickers";
import { Formik, Field, Form } from 'formik';

//Services
import { WithSession, WithNotifications } from "hoc";
import requisitionsService from "services/requisitionsService";
import storagesService from "services/storagesService";
import productsService from "services/productsService";
import classificationsService from "services/classificationsService";

//Assets
import styles from './styles';

class PendingSupplyItems extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        id: PropTypes.number,
        onBack: PropTypes.func
    };

    static defaultProps = {
        id: null,
        onBack: () => { },
    };

    state = {
        date: new Date().toISOString(),
        details: [],
        storages: [],
        categories: [],
        filtersPanelExpanded: true,
        tablePanelExpanded: true
    };

    columns = [
        {
            title: "Folio",
            field: 'folio',
        },
        {
            title: "Fecha",
            field: 'deliveryDate',
            render: row => <span>{Moment(row.deliveryDate).format("DD-MM-YYYY")}</span>
        },
        {
            title: "Unidad",
            field: 'lunchRoomName',
        },
        // {
        //     title: "Material",
        //     field: 'sapCode',
        // },
        {
            title: "Descripción",
            render: row => <span>{this.getPresentationName(row)}</span>
        },
        {
            title: "Solicitado",
            field: 'totalQuantity',
        },
        {
            title: "Unidad de medida",
            field: 'measurementUnitName',
        },
        // {
        //     title: "Costo",
        //     render: row => <span>${row.total}</span>
        // },
        {
            title: "Cantidad",
            render: row => this.renderRowInput(row)
        },
        {
            title: "Notas",
            field: 'notes',
        }
    ];

    emptyFilters = {
        startDate: Moment().startOf('day').toDate(),
        endDate: Moment().startOf('day').toDate(),
        storageId: 0,
        classificationId: 0,
        itemId: null,
    }

    componentDidMount = () => {
        this.getData();
    }

    getData = async () => {
        const storagesResponse = await storagesService.getAll();
        const classificationsResponse = await classificationsService.getAll();

        if (storagesResponse.ok && storagesResponse.data.status != "Fail") {
            const storages = storagesResponse.data.data.map(i => ({ value: i.storageId, label: i.name }));
            this.setState({ storages: [{ value: 0, label: "TODOS" }, ...storages] });
        }

        if (classificationsResponse.ok && classificationsResponse.data.status != "Fail") {
            const classifications = classificationsResponse.data.data.map(i => ({
                value: i.clasificationId, label: i.name, storageId: i.storageId
            }));
            this.setState({ classifications: [{ value: 0, label: "TODOS" }, ...classifications] });
        }
    }

    getPresentationName = (item) => {
        if (item.itemDetail != item.itemPresentationDescription)
            return `${item.itemDetail} ** ${item.itemPresentationDescription}`;
        else
            return item.itemDetail;
    }

    previousFocus = (index) => {
        const prevFocus = index - 1;

        if (prevFocus < 0) {
            return;
        }

        if (this[`input${prevFocus}`] != null) {
            const input = this[`input${prevFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.previousFocus(prevFocus);
        }
    }

    nextFocus = (index) => {
        let {
            details
        } = this.state;

        let nextFocus = index + 1;

        if (nextFocus > details.length) {
            return;
        }

        if (this[`input${nextFocus}`] != null) {
            const input = this[`input${nextFocus}`];
            input.focus();
        }
        else {
            this.nextFocus(nextFocus);
        }
    }

    renderRowInput = (row) => {
        return <TextField
            inputRef={i => this[`input${row.index}`] = i}
            type="text"
            key={row.id + this.state.date}
            value={row.totalSuppliedQuantity || ""}
            onChange={(e) => {
                const value = e.target.value;

                if (isNaN(value))
                    return;

                let details = this.state.details;
                const detail = details.find(x => x.id == row.id);
                detail.totalSuppliedQuantity = e.target.value;
                detail.modified = true;
                this.setState({ details });
            }}
            onKeyPress={async (e) => {
                if (e.key == "Enter") {
                    e.preventDefault();
                    this.nextFocus(row.index);
                }
            }}
            onKeyDown={e => {
                if (e.keyCode == 38) { //Up
                    e.preventDefault();
                    this.previousFocus(row.index);
                }
                else if (e.keyCode == 40) { //Down
                    e.preventDefault();
                    this.nextFocus(row.index);
                }
            }}
               />;
    }

    save = async () => {
        const {
            details,
            searchValues
        } = this.state;

        if (this.loading)
            return;

        const modifiedItems = details.filter(x => x.modified && x.totalSuppliedQuantity != x.original);

        if (!modifiedItems.length) {
            this.props.addNotification({ message: "Ningun cambio detectado", options: { variant: "warning" } });
            return;
        }

        this.loading = true;
        this.setState({ loading: true });

        let model = {
            supplierRequisitionItems: modifiedItems.map(i => ({
                supplierRequisitionDetailId: i.supplierRequisitionDetailId,
                suppliedQuantity: i.totalSuppliedQuantity
            }))
        };

        const response = await requisitionsService.saveProductsQuantitiesByItem(model).catch(() => {
            this.loading = false;
            this.setState({ loading: false });
        });

        this.loading = false;
        this.setState({ loading: false });

        if (!response.ok || response.data.status == "Fail" || !response.data.data.result) {
            this.props.addNotification({ message: "Sucedio un error inesperado", options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: "Se guardaron las cantidades correctamente", options: { variant: "success" } });
        this.search(searchValues);
    }

    search = async (values) => {
        this.setState({ searchValues: values });

        const response = await requisitionsService.searchRequisitionDetails({
            itemPresentationId: values.itemId || null,
            storageId: values.storageId || null,
            classificationId: values.classificationId || null,
            startDate: values.startDate,
            endDate: values.endDate
        }).catch(() => {
            this.props.addNotification({ message: "Sucedio un error inesperado", options: { variant: "error" } });
        });

        if (!response.ok || response.data.status == "Fail" || !response.data.data) {
            return;
        }

        let details = response.data.data;
        details.forEach((i, index) => {
            i.index = index;
            i.original = i.totalSuppliedQuantity;
            i.modified = false;
        });
        this.setState({ details, key: new Date().toISOString() });
    }

    renderFilters = () => {
        const {
            classes
        } = this.props;

        const {
            storages,
            classifications
        } = this.state;

        return (
            <Grid container alignItems="center">
                <Grid item xs={12} justify="center" alignContent="center">
                    <Formik
                        style={{ width: '100%' }}
                        enableReinitialize
                        initialValues={this.emptyFilters}
                        onSubmit={async (values, form) => {
                            //this.setState({ filtersPanelExpanded: false });
                            this.search(values);
                            form.setSubmitting(false);
                        }}
                        render={({ submitForm, isSubmitting }) => (
                            <Form>
                                <Grid container className={classes.root} justify="space-beetwen">
                                    <Grid item xs={12} md={4} lg={3} alignContent="center" className={classes.item}>
                                        <Field
                                            name="startDate"
                                            render={({ field, form }) => (
                                                <Grid
                                                    direction="column"
                                                    justify="center"
                                                    className={
                                                        classNames(
                                                            classes.inputRoot,
                                                            { "error": form.touched[field.name] && form.errors[field.name] }
                                                        )
                                                    }
                                                >
                                                    <InputLabel>Fecha Inicial</InputLabel>
                                                    <DatePicker
                                                        format="DD MMMM [del] YYYY"
                                                        value={field.value}
                                                        onChange={(date) => {
                                                            if (date > form.values.endDate)
                                                                form.setFieldValue('endDate', date);

                                                            form.setFieldValue(field.name, date);
                                                        }}
                                                        autoOk
                                                    />
                                                    {
                                                        form.touched[field.name] && form.errors[field.name] &&
                                                        <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                    }
                                                </Grid>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={3} alignContent="center" className={classes.item}>
                                        <Field
                                            name="endDate"
                                            render={({ field, form }) => (
                                                <Grid
                                                    direction="column"
                                                    justify="center"
                                                    className={
                                                        classNames(
                                                            classes.inputRoot,
                                                            { "error": form.touched[field.name] && form.errors[field.name] }
                                                        )
                                                    }
                                                >
                                                    <InputLabel>Fecha Final</InputLabel>
                                                    <DatePicker
                                                        format="DD MMMM [del] YYYY"
                                                        value={field.value}
                                                        onChange={(date) => {
                                                            if (date < form.values.startDate)
                                                                form.setFieldValue('startDate', date);

                                                            form.setFieldValue(field.name, date);
                                                        }}
                                                        autoOk
                                                    />
                                                    {
                                                        form.touched[field.name] && form.errors[field.name] &&
                                                        <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                    }
                                                </Grid>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={3} className={classes.item} alignContent="center">
                                        <Field
                                            name="storageId"
                                            render={({ field, form }) => {
                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>Almacen</InputLabel>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            value={field.value}
                                                            onChange={(selected) => form.setFieldValue(field.name, selected.value)}
                                                            options={storages}
                                                            onMenuClose={() => form.setFieldTouched(field.name, true, true)}
                                                            onMenuOpen={() => form.setFieldTouched(field.name, false)}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'absolute'}
                                                            menuPlacement={'bottom'}
                                                        />
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={3} alignContent="center" className={classes.item}>
                                        <Field
                                            name="classificationId"
                                            render={({ field, form }) => {
                                                let tmpClassifications = [...(classifications || [])];
                                                if (form.values.storageId)
                                                    tmpClassifications = tmpClassifications.filter(x => x.storageId == form.values.storageId || x.value == 0);

                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>Clasificación</InputLabel>
                                                        <Select
                                                            isClearable
                                                            key={form.values.storageId}
                                                            isSearchable
                                                            isDisabled={!form.values.storageId}
                                                            value={field.value}
                                                            onChange={(selected) => {
                                                                form.setFieldValue(field.name, selected.value);
                                                            }}
                                                            options={tmpClassifications}
                                                            onMenuClose={() => form.setFieldTouched(field.name, true, true)}
                                                            onMenuOpen={() => form.setFieldTouched(field.name, false)}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'absolute'}
                                                            menuPlacement={'bottom'}
                                                        />
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={3} alignContent="center" className={classes.item}>
                                        <Field
                                            name="itemId"
                                            render={({ field, form }) => (
                                                <div
                                                    className={
                                                        classNames(
                                                            classes.selectRoot,
                                                            { "error": form.touched[field.name] && form.errors[field.name] }
                                                        )
                                                    }
                                                >
                                                    <InputLabel>Articulo</InputLabel>
                                                    <SelectAsync
                                                        value={field.value}
                                                        onChange={(selected) => form.setFieldValue(field.name, selected.value)}
                                                        cacheOptions
                                                        loadOptions={async value => {
                                                            if (!value) return [];

                                                            const res = await productsService.search(
                                                                value,
                                                                form.values.storageId,
                                                                form.values.classificationId
                                                            );

                                                            let result = res.ok && res.data.status != "Fail" && res.data.data && Array.isArray(res.data.data)
                                                                ? res.data.data
                                                                : [];

                                                            return result.map(i => ({
                                                                value: i.itemPresentationId,
                                                                label: i.itemDetail == i.itemPresentationDescription
                                                                    ? i.itemDetail
                                                                    : i.itemDetail + " ** " + i.itemPresentationDescription
                                                            }));
                                                        }}
                                                        onInputChange={newValue => {
                                                            this.setState({ itemSearch: newValue });
                                                            return newValue;
                                                        }}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        menuPortalTarget={document.body}
                                                        menuPosition={'absolute'}
                                                        menuPlacement={'bottom'}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                    <Grid container className={classes.item} justify="flex-end">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            endIcon={<Icon>send</Icon>}
                                            disabled={isSubmitting}//|| !values.storageId || !values.date}
                                            onClick={submitForm}
                                        >
                                            Buscar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            className={classes.button}
                                            endIcon={<Icon>send</Icon>}
                                            disabled={isSubmitting}
                                            onClick={() => this.setState({ date: new Date() })}
                                            style={{ marginRight: '10px' }}
                                        >
                                            Limpiar filtros
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    />
                </Grid>
            </Grid>
        );
    }

    renderTable = () => {
        const {
            details,
            loading
        } = this.state;

        const someModified = details.some(x => x.modified);

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        columns={this.columns}
                        items={details}
                        options={{
                            paging: false,
                            search: false,
                            selection: false
                        }}
                        actions={someModified ? [
                            {
                                icon: 'save',
                                tooltip: 'Guardar',
                                onClick: this.save,
                                isFreeAction: true,
                                disabled: loading
                            }
                        ] : []}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            filtersPanelExpanded,
            tablePanelExpanded
        } = this.state;

        return (
            <DashboardLayout title="Surtir articulos">
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                <div className={classes.root}>
                                    <ExpansionPanel expanded={filtersPanelExpanded} onChange={(e, v) => this.setState({ filtersPanelExpanded: v })}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="filters-content"
                                            id="filters-header"
                                        >
                                            <Typography className={classes.heading}>Opciones de busqueda</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            {this.renderFilters()}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel expanded={tablePanelExpanded}>
                                        <ExpansionPanelSummary
                                            aria-controls="result-content"
                                            id="result-header"
                                        >
                                            <Typography className={classes.heading}>Resultados</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            {this.renderTable()}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(WithSession(WithNotifications(withStyles(styles)(PendingSupplyItems))));