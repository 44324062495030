import { takeLatest, call, put, all } from "redux-saga/effects";
import { NotificationTypes, NotificationCreators } from "redux/NotificationRedux";
import notificationsService from "services/notificationsService";

export function* getNotifications() {
    const response = yield call(notificationsService.getAll);
    if (response.ok && response.data.status == "Success") {
        yield put(NotificationCreators.getNotificationsRequestSuccess(response.data.data));
    }
}

export function* readNotification({ notificationId }) {
    const response = yield call(notificationsService.readNotification, notificationId);
    if (response.ok && response.data.status == "Success") {
        yield put(NotificationCreators.readNotificationRequestSuccess(notificationId));
    }
}

export function* addNotification({ notification }) {
    yield put(NotificationCreators.onNotificationAdded(notification));
}

export function* clearNotification() {
    yield put(NotificationCreators.onNotificationCleared());
}

function* notificationSagas() {
    yield all([
        takeLatest(NotificationTypes.GET_NOTIFICATIONS_REQUEST, getNotifications),
        takeLatest(NotificationTypes.READ_NOTIFICATION_REQUEST, readNotification),
        takeLatest(NotificationTypes.ADD_NOTIFICATION, addNotification),
        takeLatest(NotificationTypes.CLEAR_NOTIFICATION, clearNotification)
    ]);
}

export default notificationSagas;