import API from "./Api";

class ResponseTimeType extends API {

    constructor(controller){
        super(controller);
    }
    
}

let service = new ResponseTimeType("responsetimetypes");

export default service;