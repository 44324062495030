export default () => ({
	root: {
		flexGrow: 1,
		flexShrink: 0,
		overflow: 'hidden',
		borderRadius: '0px',
	},
	progressWrapper: {
		paddingTop: '48px',
		paddingBottom: '24px',
		display: 'flex',
		justifyContent: 'center'
	}
});
