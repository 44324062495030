import Api from "./Api";

class CityService extends Api {
    
    constructor(controller){
        super(controller);
    }

	getCitiesByStateId = (id) => {
		return this.API.get(`${this.controller}/state/${id}`);
	}
}

let service = new CityService("cities");

export default service;