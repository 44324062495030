import Api from "./Api";

class PushNotificationsService extends Api {

    constructor(controller) {
        super(controller);
    }

    readNotification = (id) => {
        return this.API.put(`${this.controller}/${id}`);
    }
}

export default new PushNotificationsService("notifications");