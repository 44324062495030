import API from './Api';

class RequisitionsService extends API {
    constructor(controller) {
        super(controller);
    }

    getRouteLunchRoomDetails = (lunchRoomId, date) => {
        const model = {
            lunchRoomId,
            deliveryDate: date
        };

        return this.API.post(`${this.controller}/filter-by-lunch-room`, model);
    }
}

export default new RequisitionsService('requisitionsDetails');
