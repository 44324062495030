import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    companyLocationListRequest: [],
    companyLocationListSuccess: ['companyLocations'],
    companyLocationListError: ['messageOperation'],
    companyLocationGetRequest: ['id'],
    companyLocationGetSuccess: ['companyLocation'],
    companyLocationGetFailure: ['error', 'messageOperation'],
    companyLocationCreateRequest: ['companyLocation'],
    companyLocationCreateSuccess: ['companyLocation', 'messageOperation'],
    companyLocationCreateFailure: ['error'],
    companyLocationUpdateRequest: ['companyLocation'],
    companyLocationUpdateSuccess: ['companyLocation', 'messageOperation'],
    companyLocationUpdateFailure: ['error'],
    companyLocationDeleteRequest: ['id'],
    companyLocationDeleteSuccess: ['id', 'messageOperation'],
    companyLocationDeleteFailure: ['messageOperation'],
    companyLocationIsValidEditRequest: ['companyLocation'],
    companyLocationIsValidEditSuccess: ['isValidEdit'],
    companyLocationIsValidEditFailure: ['messageOperation'],
    setLoading: ['isLoading'],
    companyLocationSetMessage: ['messageOperation']
  },
  {}
);

export const CompanyLocationTypes = Types;
export const CompanyLocationCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  companyLocations: [],
  isLoading: false,
  isValidEdit: undefined
});

export const onCompanyLocationListSuccess = (state, { companyLocations }) =>
  state.merge({ companyLocations });
export const onCompanyLocationGetSuccess = (state, { companyLocation }) =>
  state.merge({ companyLocation });
export const onCompanyLocationCreateSuccess = (
  state,
  { companyLocation, messageOperation }
) =>
  state.merge({
    companyLocations: state.companyLocations.concat(companyLocation),
    messageOperation
  });
export const onCompanyLocationUpdateSuccess = (
  state,
  { companyLocation, messageOperation }
) =>
  state.merge({
    companyLocations: state.companyLocations.map(i =>
      i.id == companyLocation.id ? companyLocation : i
    ),
    companyLocation,
    messageOperation
  });
export const onCompanyLocationDeleteSuccess = (
  state,
  { id, messageOperation }
) => {
  let item = state.companyLocations.find(i => i.id == id);
  return state.merge({
    companyLocations: state.companyLocations.map(i =>
      i.id == item.id ? { ...item, isActive: false } : i
    ),
    messageOperation
  });
};
export const onCompanyLocationIsValidEditSuccess = (state, { isValidEdit }) =>
  state.merge({
    isValidEdit: isValidEdit
  });
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });
export const onCompanyLocationSetMessage = (state, { messageOperation }) =>
  state.merge({ messageOperation });

//map our actions types to our reducer functions
export const HANDLERS = {
  [CompanyLocationTypes.COMPANY_LOCATION_LIST_SUCCESS]: onCompanyLocationListSuccess,
  [CompanyLocationTypes.COMPANY_LOCATION_GET_SUCCESS]: onCompanyLocationGetSuccess,
  [CompanyLocationTypes.COMPANY_LOCATION_CREATE_SUCCESS]: onCompanyLocationCreateSuccess,
  [CompanyLocationTypes.COMPANY_LOCATION_UPDATE_SUCCESS]: onCompanyLocationUpdateSuccess,
  [CompanyLocationTypes.COMPANY_LOCATION_DELETE_SUCCESS]: onCompanyLocationDeleteSuccess,
  [CompanyLocationTypes.COMPANY_LOCATION_IS_VALID_EDIT_SUCCESS]: onCompanyLocationIsValidEditSuccess,
  [CompanyLocationTypes.SET_LOADING]: onSetLoading,
  [CompanyLocationTypes.COMPANY_LOCATION_SET_MESSAGE]: onCompanyLocationSetMessage
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectCompanyLocations = state =>
  Immutable.asMutable(state.companyLocationReducer.companyLocations, {
    deep: true
  });
export const selectCompanyLocation = state =>
  state.companyLocationReducer.companyLocation;
export const selectIsLoading = state => state.companyLocationReducer.isLoading;
export const selectIsValidEdit = state =>
  state.companyLocationReducer.isValidEdit;
export const selectMessageOperation = state =>
  state.companyLocationReducer.messageOperation;
