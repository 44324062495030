import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    setLoading: ['isLoading'],
    entityTypeGetListRequest: [''],
    entityTypeGetListSuccess: ['entityTypes'],
    entityTypeGetListFailure: ['error'],
    entityTypeSetMessage: ['messageOperation']
  },
  {}
);

export const EntityTypes = Types;
export const EntityTypeCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  entityTypes: [],
  isLoading: false,
  messageOperation: null
});

export const onEntityTypeGetListSuccess = (state, { entityTypes }) =>
  state.merge({ entityTypes: entityTypes });
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });
export const onEntityTypeSetMessage = (state, { messageOperation }) =>
  state.merge({ messageOperation });

//map our actions types to our reducer functions
export const HANDLERS = {
  [EntityTypes.ENTITY_TYPE_GET_LIST_SUCCESS]: onEntityTypeGetListSuccess,
  [EntityTypes.SET_LOADING]: onSetLoading,
  [EntityTypes.ENTITY_TYPE_SET_MESSAGE]: onEntityTypeSetMessage
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectIsLoading = state => state.entityTypeReducer.isLoading;
export const selectMessageOperation = state =>
  state.entityTypeReducer.messageOperation;
export const selectEntityTypes = state =>
  Immutable.asMutable(
    state.entityTypeReducer.entityTypes.filter(x => x.id !== 1),
    {
      deep: true
    }
  );
export const selectFullEntityTypes = state =>
  Immutable.asMutable(state.entityTypeReducer.entityTypes, {
    deep: true
  });
