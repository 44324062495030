import API from './Api';

class UserGroupService extends API {
  constructor(controller) {
    super(controller);
  }
}

let userGroupService = new UserGroupService('usergroups');

export default userGroupService;
