import { takeLatest, put, call } from 'redux-saga/effects';
import {
  CompanyLocationTypes,
  CompanyLocationCreators
} from 'redux/CompanyLocationRedux';
import companyLocationService from 'services/companyLocationService';
import CompanyLocation from 'contract/responses/CompanyLocation';
import { getAll as getStates } from './stateSagas';
import { getCitiesByStateId } from './citySagas';
import { getAll as getUsers } from './userSagas';
import { MessageOperation } from '../constants';

export function* getCompanyCatalogs() {
  yield put(CompanyLocationCreators.setLoading(true));
  yield* getStates(false);
  yield* getUsers(false);
  yield* getAll(false);
  yield put(CompanyLocationCreators.setLoading(false));
}

export function* getAll(showLoading) {
  if (showLoading) yield put(CompanyLocationCreators.setLoading(true));

  const response = yield call(companyLocationService.getAll);

  if (response.ok) {
    yield put(
      CompanyLocationCreators.companyLocationListSuccess(response.data.data)
    );
  } else {
    yield put(CompanyLocationCreators.userSetMessage(MessageOperation.error));
  }

  if (showLoading) yield put(CompanyLocationCreators.setLoading(false));
}

export function* getById({ id }) {
  yield put(CompanyLocationCreators.setLoading(true));

  const response = yield call(companyLocationService.getById, id);

  if (response.ok) {
    let companyLocation = new CompanyLocation(response.data.data);
    yield* getCitiesByStateId({ id: companyLocation.address.stateId });
    yield put(
      CompanyLocationCreators.companyLocationGetSuccess(companyLocation)
    );
  } else {
    yield put(
      CompanyLocationCreators.companyLocationSetMessage(MessageOperation.error)
    );
  }

  yield put(CompanyLocationCreators.setLoading(false));
}

export function* create({ companyLocation }) {
  yield put(CompanyLocationCreators.setLoading(true));

  const response = yield call(companyLocationService.create, companyLocation);

  if (response.ok) {
    let companyLocation = new CompanyLocation(response.data.data);
    yield put(
      CompanyLocationCreators.companyLocationCreateSuccess(
        companyLocation,
        MessageOperation.success
      )
    );
    yield* getById({ id: companyLocation.id });
  } else {
    yield put(
      CompanyLocationCreators.companyLocationSetMessage(MessageOperation.error)
    );
  }

  yield put(CompanyLocationCreators.setLoading(false));
}

export function* update({ companyLocation }) {
  yield put(CompanyLocationCreators.setLoading(true));

  const response = yield call(companyLocationService.update, companyLocation);

  if (response.ok) {
    yield put(
      CompanyLocationCreators.companyLocationUpdateSuccess(
        companyLocation,
        MessageOperation.success
      )
    );
    yield* getById({ id: companyLocation.id });
  } else {
    yield put(
      CompanyLocationCreators.companyLocationSetMessage(MessageOperation.error)
    );
  }

  yield put(CompanyLocationCreators.setLoading(false));
}

export function* isValidEdit({ companyLocation }) {
  //yield put(CompanyLocationCreators.setLoading(true));

  const response = yield call(
    companyLocationService.getIsValidEdit,
    companyLocation
  );

  if (response.ok) {
    yield put(
      CompanyLocationCreators.companyLocationIsValidEditSuccess(
        response.data.data.result
      )
    );
  } else {
    yield put(
      CompanyLocationCreators.companyLocationSetMessage(MessageOperation.error)
    );
  }

  //yield put(CompanyLocationCreators.setLoading(false));
}

export function* deleteItem({ id }) {
  yield put(CompanyLocationCreators.setLoading(true));

  const response = yield call(companyLocationService.delete, id);

  if (response.ok) {
    yield put(
      CompanyLocationCreators.companyLocationDeleteSuccess(
        id,
        MessageOperation.success
      )
    );
  } else {
    if (response.status == 409) {
      yield put(
        CompanyLocationCreators.companyLocationSetMessage(
          MessageOperation.conflict
        )
      );
    } else {
      yield put(
        CompanyLocationCreators.companyLocationSetMessage(
          MessageOperation.error
        )
      );
    }
  }

  yield put(CompanyLocationCreators.setLoading(false));
}

/*
	CompanyLocationSagas
*/
function* CompanyLocationSagas() {
  yield takeLatest(
    CompanyLocationTypes.COMPANY_LOCATION_LIST_REQUEST,
    getCompanyCatalogs
  );
  yield takeLatest(CompanyLocationTypes.COMPANY_LOCATION_GET_REQUEST, getById);
  yield takeLatest(
    CompanyLocationTypes.COMPANY_LOCATION_CREATE_REQUEST,
    create
  );
  yield takeLatest(
    CompanyLocationTypes.COMPANY_LOCATION_UPDATE_REQUEST,
    update
  );
  yield takeLatest(
    CompanyLocationTypes.COMPANY_LOCATION_IS_VALID_EDIT_REQUEST,
    isValidEdit
  );
  yield takeLatest(
    CompanyLocationTypes.COMPANY_LOCATION_DELETE_REQUEST,
    deleteItem
  );
}

export default CompanyLocationSagas;
