import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    getRolesRequest: [],
    getRolesSuccessfull: ['roles'],
    getRolesError: ['error'],
    createRoleRequest: ['role'],
    createRoleOK: ['role', 'messageOperation'],
    createRoleKO: ['error'],
    updateRoleRequest: ['role'],
    updateRoleOK: ['role', 'messageOperation'],
    updateRoleKO: ['error'],
    deleteRoleRequest: ['id'],
    deleteRoleOK: ['id', 'messageOperation'],
    deleteRoleFailure: ['messageOperation'],
    setLoading: ['isLoading'],
    rolesSetMessage: ['messageOperation']
  },
  {}
);

export const RoleTypes = Types;
export const RoleCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  roles: [],
  isLoading: false
});

export const onSetRoles = (state, { roles }) => state.merge({ roles });
export const onCreateRole = (state, { role, messageOperation }) =>
  state.merge({ roles: state.roles.concat(role), messageOperation });
export const onUpdateRole = (state, { role, messageOperation }) =>
  state.merge({
    roles: state.roles.map(i => (i.id === role.id ? role : i)),
    messageOperation
  });
export const onDeleteRole = (state, { id, messageOperation }) =>
  state.merge({
    roles: state.roles.filter(x => x.id !== id),
    messageOperation
  });
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });
export const onRolesSetMessage = (state, { messageOperation }) =>
  state.merge({ messageOperation });

//map our actions types to our reducer functions
export const HANDLERS = {
  [RoleTypes.GET_ROLES_SUCCESSFULL]: onSetRoles,
  [RoleTypes.CREATE_ROLE_OK]: onCreateRole,
  [RoleTypes.UPDATE_ROLE_OK]: onUpdateRole,
  [RoleTypes.DELETE_ROLE_OK]: onDeleteRole,
  [RoleTypes.SET_LOADING]: onSetLoading,
  [RoleTypes.ROLES_SET_MESSAGE]: onRolesSetMessage
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const getRoles = state =>
  Immutable.asMutable(state.rolesReducer.roles, { deep: true });
export const selectRoles = state =>
  Immutable.asMutable(state.rolesReducer.roles.filter(x => x.isActive), {
    deep: true
  });
export const selectRolesLookup = state => {
  const { roles } = state.rolesReducer;
  return roles
    ? roles
        .filter(x => x.isActive)
        .reduce((obj, item) => {
          obj[item.id] = item.code;
          return obj;
        }, {})
    : [];
};
export const selectMessageOperation = state =>
  state.rolesReducer.messageOperation;
export const selectIsLoading = state => state.rolesReducer.isLoading;
