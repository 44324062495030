import Api from './Api';

class DeparmentService extends Api {
  constructor(controller) {
    super(controller);
  }
}

let service = new DeparmentService('deparments');

export default service;
