import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress,
    Checkbox,
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table } from 'components';

//Services
import routesService from "services/routesService";

//Assets
import styles from './styles';
import { WithNotifications } from 'hoc';

class Routes extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        routes: [],
        date: Moment().startOf('day').toDate(),
        routeId: null,
        lunchRoom: null,
        // date: Moment('18/04/2020', "DD/MM/YYYY").startOf('day').toDate(),
        // routeId: 6,
        // lunchRoom: { lunchRoomName: "FAMA", lunchRoomId: 8 },
        tab: 0,
        plasticBoxes: "",
        details: []
    };

    columns = [
        {
            title: "Nombre",
            field: 'name'
        },
        {
            title: "Generar carta porte",
            field: ' ',
            render: (row) => (
                <Checkbox
                    checked={row.generateConsignmentNote}
                    onChange={(e, value) => {
                        let routes = this.state.routes;
                        let item = routes[row.tableData.id];
                        item.generateConsignmentNote = value;
                        this.setState({ routes });
                    }}
                />
            )
        },
        {
            title: "Especificar fecha de timbre",
            field: ' ',
            render: (row) => (
                <Checkbox
                    checked={row.specifySignDate}
                    onChange={(e, value) => {
                        let routes = this.state.routes;
                        let item = routes[row.tableData.id];
                        item.specifySignDate = value;
                        this.setState({ routes });
                    }}
                />
            )
        },
    ];

    componentDidMount = () => {
        this.getData();
    }

    getData = async () => {
        const routesResponse = await routesService.getAll();
        if (routesResponse.ok && routesResponse.data.status != "Fail") {
            this.setState({ routes: routesResponse.data.data });
        }
    }

    previousFocus = (index) => {
        const prevFocus = index - 1;

        if (prevFocus < 0) {
            return;
        }

        if (this[`input${prevFocus}`] != null) {
            const input = this[`input${prevFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.previousFocus(prevFocus);
        }
    }

    nextFocus = (index) => {
        let {
            details
        } = this.state;

        let nextFocus = index + 1;

        if (nextFocus > details.length) {
            return;
        }

        if (this[`input${nextFocus}`] != null) {
            const input = this[`input${nextFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.nextFocus(nextFocus);
        }
    }

    getTitle = () => {
        return "Rutas";
    }

    save = async () => {
        const model = {
            routes: this.state.routes
        };

        const response = await routesService.saveRoutes(model);

        if (!response.ok) {
            this.props.addNotification({ message: 'No se pudo guardar información', options: { variant: "error" } });
        }

        this.props.addNotification({ message: 'Información guardada', options: { variant: "success" } });
    }

    renderTable = () => {
        const {
            routes,
            loading
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        isLoading={loading}
                        columns={this.columns}
                        items={routes}
                        options={{
                            pageSize: 10,
                            search: false
                            // selection: copiyng ? false : true
                        }}
                        actions={[
                            {
                                icon: 'save',
                                isFreeAction: true,
                                tooltip: 'Guardar',
                                onClick: this.save
                            }
                        ]}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {
                                    isLoading
                                        ? <CircularProgress />
                                        : this.renderTable()
                                }
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default WithNotifications(withStyles(styles)(Routes));