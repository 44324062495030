import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
//import Yup from 'yup';
import Moment from 'moment';
import classNames from "classnames";

// UI Components
import {
    withStyles,
    Grid,
    Typography,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Button,
    InputLabel,
    Icon,
    Checkbox,
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Select } from 'components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker } from "@material-ui/pickers";
import { Formik, Field, Form } from 'formik';

//Services
import { WithSession, WithNotifications } from "hoc";
import requisitionsService from "services/requisitionsService";
import lunchRoomsService from "services/lunchRoomsService";
import branchOfficesService from "services/branchOfficesService";

//Assets
import styles from './styles';

class DownloadConcentratedRQReport extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        id: PropTypes.number,
        onBack: PropTypes.func
    };

    static defaultProps = {
        id: null,
        onBack: () => { },
    };

    state = {
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        branchOffices: [],
        lunchRooms: [],
        types: [],
        filtersPanelExpanded: true,
        tablePanelExpanded: true
    };

    emptyFilters = {
        deliveryDate: Moment(),
        branchOfficeId: 0,
        lunchRoomId: 0,
        type: 0,
        showOnlyDifferences: false,
    }

    componentDidMount = () => {
        this.getData();
    }

    getData = () => {
        this.getLunchRooms();
        this.getBranchOffices();
        this.getTypes();
    }

    getLunchRooms = async (branchOfficeId) => {
        let response;
        if (branchOfficeId) {
            response = await lunchRoomsService.getLunchRoomsByBranchOffice(branchOfficeId);
        }
        else {
            response = await lunchRoomsService.getAll();
        }

        if (response.ok && response.data.status != "Fail") {
            const lunchRooms = response.data.data.map(i => ({ value: i.lunchRoomId, label: i.lunchRoomName }));
            this.setState({ lunchRooms: [{ value: 0, label: "TODOS" }, ...lunchRooms] });
        }
    }

    getBranchOffices = async () => {
        let response = await branchOfficesService.getAll();
        if (response.ok && response.data.status != "Fail") {
            const branchOffices = response.data.data.map(i => ({ value: i.branchOfficeId, label: i.name }));
            this.setState({ branchOffices: [{ value: 0, label: "TODOS" }, ...branchOffices] });
        }
    }

    getTypes = () => {
        const types = [
            { value: 0, label: "TODOS" },
            { value: 1, label: "Normales" },
            { value: 2, label: "Extras" },
        ];

        this.setState({ types });
    }

    download = async (values) => {
        this.setState({ searchValues: values });

        const response = await requisitionsService.downloadConcentratedRQReport({
            deliveryDate: values.deliveryDate.startOf('day'),
            branchOfficeId: values.branchOfficeId || null,
            lunchRoomId: values.lunchRoomId || null,
            type: values.type,
            showOnlyDifferences: values.showOnlyDifferences
        }).catch(() => {
            this.props.addNotification({ message: "Sucedio un error inesperado", options: { variant: "error" } });
        });

        if (!response.ok || response.data.status == "Fail" || !response.data.data) {
            return;
        }

        var a = document.createElement("a");
        a.href = window.env.API_URL + "files/" + response.data.data;
        a.setAttribute("download", "Layout");
        a.click();
        return false;
    }

    renderFilters = () => {
        const {
            classes
        } = this.props;

        const {
            lunchRooms,
            branchOffices,
            types,
        } = this.state;

        return (
            <Grid container alignItems="center">
                <Grid item xs={12} justify="center" alignContent="center">
                    <Formik
                        style={{ width: '100%' }}
                        enableReinitialize
                        initialValues={this.emptyFilters}
                        onSubmit={async (values, form) => {
                            this.download(values);
                            form.setSubmitting(false);
                        }}
                        render={({ submitForm, isSubmitting }) => (
                            <Form>
                                <Grid container className={classes.root} justify="space-beetwen">
                                    <Grid item xs={12} md={4} lg={4} alignContent="center" className={classes.item}>
                                        <Field
                                            name="deliveryDate"
                                            render={({ field, form }) => (
                                                <Grid
                                                    direction="column"
                                                    justify="center"
                                                    className={
                                                        classNames(
                                                            classes.inputRoot,
                                                            { "error": form.touched[field.name] && form.errors[field.name] }
                                                        )
                                                    }
                                                >
                                                    <InputLabel>Fecha de entrega</InputLabel>
                                                    <DatePicker
                                                        format="DD MMMM [del] YYYY"
                                                        value={field.value}
                                                        onChange={(date) => form.setFieldValue(field.name, date)}
                                                        autoOk
                                                    />
                                                    {
                                                        form.touched[field.name] && form.errors[field.name] &&
                                                        <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                    }
                                                </Grid>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4} className={classes.item} alignContent="center">
                                        <Field
                                            name="branchOfficeId"
                                            render={({ field, form }) => {
                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>Sucursal</InputLabel>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            value={field.value}
                                                            onChange={(selected) => {
                                                                this.getLunchRooms(selected ? selected.value : null);

                                                                form.setFieldValue(field.name, selected.value);
                                                                form.setFieldValue('lunchRoomId', '0');
                                                            }}
                                                            options={branchOffices}
                                                            onMenuClose={() => form.setFieldTouched(field.name, true, true)}
                                                            onMenuOpen={() => form.setFieldTouched(field.name, false)}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'absolute'}
                                                            menuPlacement={'bottom'}
                                                        />
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4} className={classes.item} alignContent="center">
                                        <Field
                                            name="lunchRoomId"
                                            render={({ field, form }) => {
                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>Unidad</InputLabel>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            value={field.value}
                                                            onChange={(selected) => form.setFieldValue(field.name, selected.value)}
                                                            options={lunchRooms}
                                                            onMenuClose={() => form.setFieldTouched(field.name, true, true)}
                                                            onMenuOpen={() => form.setFieldTouched(field.name, false)}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'absolute'}
                                                            menuPlacement={'bottom'}
                                                        />
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4} className={classes.item} alignContent="center">
                                        <Field
                                            name="type"
                                            render={({ field, form }) => {
                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>Tipo de pedido</InputLabel>
                                                        <Select
                                                            isClearable
                                                            isSearchable
                                                            value={field.value}
                                                            onChange={(selected) => form.setFieldValue(field.name, selected.value)}
                                                            options={types}
                                                            onMenuClose={() => form.setFieldTouched(field.name, true, true)}
                                                            onMenuOpen={() => form.setFieldTouched(field.name, false)}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'absolute'}
                                                            menuPlacement={'bottom'}
                                                        />
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4} className={classes.item} alignContent="center">
                                        <Field
                                            name="showOnlyDifferences"
                                            render={({ field, form }) => {
                                                return (
                                                    <div
                                                        className={
                                                            classNames(
                                                                classes.selectRoot,
                                                                { "error": form.touched[field.name] && form.errors[field.name] }
                                                            )
                                                        }
                                                    >
                                                        <Checkbox
                                                            checked={field.value}
                                                            color="primary"
                                                            onChange={() => form.setFieldValue(field.name, !field.value)}
                                                        />
                                                        <Typography
                                                            className={classes.policyText}
                                                            variant="body1"
                                                        >
                                                            Solo diferencias
                                                        </Typography>
                                                        {
                                                            form.touched[field.name] && form.errors[field.name] &&
                                                            <p className="MuiFormHelperText-root Mui-error">{form.errors[field.name]}</p>
                                                        }
                                                    </div>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid container className={classes.item} justify="flex-end">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            endIcon={<Icon>send</Icon>}
                                            disabled={isSubmitting}//|| !values.storageId || !values.date}
                                            onClick={submitForm}
                                        >
                                            Descargar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            className={classes.button}
                                            endIcon={<Icon>send</Icon>}
                                            disabled={isSubmitting}
                                            onClick={() => this.setState({ date: new Date() })}
                                            style={{ marginRight: '10px' }}
                                        >
                                            Limpiar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    />
                </Grid>
            </Grid>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            filtersPanelExpanded
        } = this.state;

        return (
            <DashboardLayout title="Descargar reporte detallado de RQ">
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                <div className={classes.root}>
                                    <ExpansionPanel expanded={filtersPanelExpanded} onChange={(e, v) => this.setState({ filtersPanelExpanded: v })}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="filters-content"
                                            id="filters-header"
                                        >
                                            <Typography className={classes.heading}>Parametros del reporte de excel</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            {this.renderFilters()}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(WithSession(WithNotifications(withStyles(styles)(DownloadConcentratedRQReport))));