import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { AccountProfile, AccountDetails } from './components';

import compose from 'recompose/compose';

import withSession from "hoc/WithSession";
import withAuth from "hoc/WithAuth";
import usersService from "services/userService";


// Component styles
const styles = theme => ({
	root: {
		padding: theme.spacing.unit * 4
	}
});

class ChangePassword extends Component {
	state = { tabIndex: 0 };

	componentWillUpdate = (prevProps) => {
		if (!prevProps.changePasswordMessage && this.props.changePasswordMessage) {
			this.props.enqueueSnackbar(this.props.changePasswordMessage);
		}
	}

	changePassword = async (newPassword) => {
		const response = await usersService.updatePassword(newPassword);

		if (response.status != 200) {
			this.props.enqueueSnackbar("Error guardando", { variant: "error" });
			return;
		}

		if (response.data.status == "Fail") {
			this.props.enqueueSnackbar(response.data.data.error, { variant: "error" });
			return;
		}

		this.props.enqueueSnackbar("Contraseña actualizada", { variant: "success" });
		this.props.history.push("/");
	}

	render() {
		const { classes } = this.props;

		return (
			<DashboardLayout title="Perfil de usuario">
				<div className={classes.root}>
					<Grid
						container
						spacing={4}
					>
						<Grid
							item
							lg={4}
							md={6}
							xl={4}
							xs={12}
						>
							<AccountProfile session={this.props.session} />
						</Grid>
						<Grid
							item
							lg={8}
							md={6}
							xl={8}
							xs={12}
						>
							<AccountDetails onSave={this.changePassword} />
						</Grid>
					</Grid>
				</div>
			</DashboardLayout>
		);
	}
}

ChangePassword.propTypes = {
	classes: PropTypes.object.isRequired
};

export default compose(
	withStyles(styles),
	withSession,
	withAuth,
	withSnackbar
)(ChangePassword);
