import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserCreators, selectUsers } from '../redux/UserRedux';
import {
  CategoryCreators,
  selectCategories,
  selectIsLoading
} from '../redux/CategoryRedux';
import {
  SubCategoryCreators,
  selectSubCategories
} from '../redux/SubCategoryRedux';
import { WorkItemTaskCreators } from '../redux/WorkItemTaskRedux';

export const mapStateToProps = state => ({
  categories: selectCategories(state),
  subCategories: selectSubCategories(state),
  users: selectUsers(state),
  isLoading: selectIsLoading(state)
});

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCategories: CategoryCreators.getCategoriesRequest,
      getSubCategories: SubCategoryCreators.allSubCategoriesRequest,
      getUsers: UserCreators.userListRequest,
      getSubCategoryTasks:
        WorkItemTaskCreators.workItemTaskGetByWorkItemIdRequest
    },
    dispatch
  );

export const hocConector = WrappedComponent => {
  const hoc = ({ ...props }) => <WrappedComponent {...props} />;

  hoc.propTypes = {
    getUsers: PropTypes.func,
    getCategories: PropTypes.func,
    getSubCategories: PropTypes.func,
    categories: PropTypes.array,
    subCategories: PropTypes.array,
    users: PropTypes.array
  };

  return hoc;
};

export default Component =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(hocConector(Component));
