import API from "./Api";

class SubCategoryLocationService extends API {

    constructor(controller){
        super(controller);
    }

    saveMultiple = (items) => {
		return this.API.post(`${this.controller}/save-multiple`, items);
	}

	createMultiple = (item) => {
		return this.API.post(`${this.controller}/multiple`, item);
	}

	getByOwnerId = (subCategoryId, ownerId) => {
		return this.API.get(`${this.controller}/subcategory/${subCategoryId}/owner/${ownerId}`);
    }
    
    getBySubCategoryId = (id) => {
		return this.API.get(`${this.controller}/subcategory/${id}`);
	}
}

let subCategoryLocationService = new SubCategoryLocationService("subcategorylocations");

export default subCategoryLocationService;