import React from "react";
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import PropTypes from 'prop-types';

import "./styles.css";

const Weather = (props) => {
	const { data, isLoading, errorMessage } = useOpenWeather({
		key: 'f59ebf0311af712aab17907a90b39d22',
		lat: props.geo.ll[0],
		lon: props.geo.ll[1],
		lang: props.lang,
		unit: 'metric', // values are (metric, standard, imperial)
	});

	return (
		<ReactWeather
			isLoading={isLoading}
			errorMessage={errorMessage}
			data={data}
			lang={props.lang}
			locationLabel={props.geo ? `${props.geo.city}, ${props.geo.region}` : ""}
			unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
			showForecast
		/>
	);
};

Weather.propTypes = {
	lang: PropTypes.string,
	geo: PropTypes.object,
};

Weather.defaultProps = {
	lang: 'es',
};

export default Weather;