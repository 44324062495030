import { takeLatest, call, put } from 'redux-saga/effects';
import { UserGroupTypes, UserGroupCreators } from 'redux/UserGroupRedux';
import userGroupService from 'services/userGroupService';
import UserGroup from 'contract/responses/UserGroup';
import { MessageOperation } from '../constants';

export function* getAll(showLoading) {
  if (showLoading) yield put(UserGroupCreators.setLoading(true));

  const response = yield call(userGroupService.getAll);

  if (response.ok) {
    yield put(
      UserGroupCreators.userGroupListSuccess(
        response.data.data.map(item => new UserGroup(item))
      )
    );
  } else {
    yield put(UserGroupCreators.userGroupSetMessage(MessageOperation.error));
  }

  if (showLoading) yield put(UserGroupCreators.setLoading(false));
}

/**
 * sagas
 */
function* userGroupSagas() {
  yield takeLatest(UserGroupTypes.USER_GROUP_LIST_REQUEST, getAll);
}

export default userGroupSagas;
