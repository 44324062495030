import React from "react";
import { withStyles, Tooltip, IconButton } from '@material-ui/core';
import styles from "./styles";

const ButtonToolTip = ({ title, children, classes, onClick }) => (
    <Tooltip title={title}>
        <IconButton
            className={classes.button}
            onClick={() => {
                if (onClick) onClick();
            }}
        >
            {children}
        </IconButton>
    </Tooltip>
);

export default withStyles(styles)(ButtonToolTip);