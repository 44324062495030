import Api from "./Api";

class PushNotificationsService extends Api {

    constructor(controller) {
        super(controller);
    }

    postSubscription = (subscription) => {
        const keys = subscription.toJSON().keys;
        return this.API.post(`${this.controller}`, {
            Endpoint: subscription.endpoint,
            ExpirationDate: subscription.expirationDate,
            Auth: keys.auth,
            P256dh: keys.p256dh
        });
    }

    deleteSubscription = (id) => {
        return this.API.delete(`${this.controller}/${id}`);
    }
}

export default new PushNotificationsService("push-subscriptions");