import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

// UI Components
import {
	withStyles,
	AppBar,
	Tab,
	Tabs,
	Grid,
	CircularProgress,
	InputLabel,
	TextField,
	CardContent,
	Card,
	CardHeader,
	Checkbox,
	Chip,
	Link,
	Typography,
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import {
	Portlet,
	PortletContent,
	TabPanel,
	Select,
	Table,
} from 'components';
import {
	Receipt,
	MonetizationOn,
	MenuBook,
	List,
	InsertDriveFile,
	ContactPhone,
	Money,
} from '@material-ui/icons';
import { tabProps } from 'components/TabPanel';

//Services
import { WithNotifications } from 'hoc';
import clientsService from 'services/clientsService';
import documentsService from 'services/documentsService';
import historicalPriceService from 'services/historicalPriceService';

import HistoricalPriceChart from './components/historicalPriceChart';

//Assets
import styles from './styles';

function ClientsContractsForm(props) {
	const emptyItem = {
		billData: {
			cfdi: {},
			paymentMethod: {},
			productSAT: {},
			productUnitSAT: {},
			recipientRegime: {},
			tenderType: {},
		},
		lunchRooms: [],
		salesReport: [],
		itemPricesReport: [],
		documents: [],
		contacts: [],
		clientGroups: [],
		clientId: "",
		saleItems: [],
		historicalPrice: [],
		saleItemId: null,
		clientGroupId: null,
		loaded: false,
		menu: {
			agreements: "",
			guarantee: "",
			menuItems: [],
		},
		service: {
			agreement: "",
			menuShifts: [],
		},
	};

	const {
		classes,
		match: {
			params: { id: propsId }
		},
		title,
	} = props;

	const [tab, setTab] = useState(0);
	const [item, setItem] = useState({ ...emptyItem });
	const [loading] = useState(false);
	const [clients, setClients] = useState([]);
	const [clientId, setClientId] = useState(null);
	const [loadingSalesReport, setLoadingSalesReport] = useState(false);
	const [loadingHistoricalPriceReport, setLoadingHistoricalPriceReport] = useState(false);
	const [lunchRoomId, setLunchRoomId] = useState(0);
	const [year, setYear] = useState(2023);
	const [documentsColumns, setDocumentsColumns] = useState([]);

	const columns = [
		{
			title: "Nombre",
			field: 'name'
		},
		{
			title: "Planta",
			field: 'lunchRoom',
		},
		{
			title: "Ocupación",
			field: 'occupation',
		},
		{
			title: "Celular",
			field: 'cellphone',
		},

		{
			title: "Correo",
			field: 'email',
		},
		// {
		// 	title: "Activo",
		// 	field: 'isActive',
		// 	type: 'bool',
		// 	// eslint-disable-next-line
		// 	render: (row) => <Checkbox disabled checked={row.isActive} />,
		// },
	];

	const menuItemsColumns = [
		{
			title: "Tiempo",
			field: 'mealTime'
		},
		{
			title: "Concepto",
			field: 'mealConcept',
		},
		{
			title: "Descripción",
			field: 'meal',
		},
		{
			title: "Cantidad",
			field: 'quantity',
			render: (row) => <span>{formatNumber(row.quantity)}</span>,
		},

		{
			title: "Unidad de medida",
			field: 'mealUnit',
		},
		{
			title: "Observaciones",
			field: 'observations',
		},
	];

	const menuShiftsColumns = [
		{
			title: "Servicio",
			field: 'serviceShift',
			// render: (row) => <span>{row.serviceShiftId}. {row.serviceShift}</span>,
		},
		{
			title: "Cantidad estimada",
			field: 'quantity',
			render: (row) => row.quantity ? <span>{formatNumber(row.quantity)}</span> : <span />,
		},
		{
			title: "Inicio",
			field: 'startTime',
			render: (row) => row.startTime ? <span>{moment(row.startTime).format('HH:mm')}</span> : <span />,
		},
		{
			title: "Fin",
			field: 'endTime',
			render: (row) => row.endTime ? <span>{moment(row.endTime).format('HH:mm')}</span> : <span />,
		},
		{
			title: "Duración",
			field: 'serviceDuration',
		},
		{
			title: "Observaciones",
			field: 'observation',
		},
	];

	const formatCurrency = (number) => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(number);
	const formatNumber = (number) => new Intl.NumberFormat('es-MX').format(number);

	const expensesColumns = [
		{
			title: "Concepto",
			field: 'concept'
		},
		{
			title: "SICSA",
			field: 'isSICSA',
			type: 'bool',
			// eslint-disable-next-line
			render: (row) => <Checkbox disabled checked={row.isSICSA} />,
			headerStyle: { width: '40px', maxwidth: '40px' },
			cellStyle: { width: '40px', maxwidth: '40px', padding: '5px 10px 5px 10px' },
		},
		{
			title: "CLIENTE",
			field: 'isClient',
			type: 'bool',
			// eslint-disable-next-line
			render: (row) => <Checkbox disabled checked={row.isClient} />,
			headerStyle: { width: '40px', maxwidth: '40px' },
			cellStyle: { width: '40px', maxwidth: '40px', padding: '5px 10px 5px 10px' },
		},
		{
			title: "Observaciones",
			field: 'comments',
		},
	];

	const init = async () => {
		// loadItemFromUrl();

		clientsService.getAllSimple().then(response => {
			setClients(response && response.data ? response.data.data.map((i) => ({ value: i.id, label: i.name })) : []);
		});
	};

	const cleanScreen = () => {
		setItem({ ...emptyItem });
		setTab(0);
	};

	const loadItem = async () => {
		setLunchRoomId(0);

		if (!clientId) {
			cleanScreen();
			return;
		}

		const client = await loadClient();


		try {
			const expensesP = clientsService.getExpenses(clientId);
			const documentsP = clientsService.getDocuments(clientId);
			const lunchRoomsP = clientsService.getLunchRooms(clientId);
			// const itemPricesReportP = clientsService.getSalesReportResume(clientId, 0, year);
			const clientGroupsP = historicalPriceService.getClientGroups(clientId);
			const menuP = clientsService.getMenu(clientId);
			const serviceP = clientsService.getService(clientId);

			const results = {
				expenses: await expensesP,
				documents: await documentsP,
				lunchRooms: await lunchRoomsP,
				//salesReport: await salesReportP,
				// itemPricesReport: await itemPricesReportP,
				clientGroups: await clientGroupsP,
				menu: await menuP,
				service: await serviceP,
			};

			const newItem = {
				...client,
				loaded: true,
				expenses: results.expenses.data.data && results.expenses.data.data.length ? results.expenses.data.data : [],
				documents: results.documents.data.data && results.documents.data.data.length ? results.documents.data.data : [],
				lunchRooms: results.lunchRooms.data.data && results.lunchRooms.data.data.length
					? [{ lunchRoomId: 0, lunchRoomName: "Todas" }, ...results.lunchRooms.data.data]
					: [{ lunchRoomId: 0, lunchRoomName: "Todas" }],
				salesReport: [],
				itemPricesReport: [],
				//itemPricesReport: results.itemPricesReport.data.data && results.itemPricesReport.data.data.length ? results.itemPricesReport.data.data : [],
				clientGroups: results.clientGroups.data.data && results.clientGroups.data.data.length ? results.clientGroups.data.data : [],
				menu: results.menu.data && results.menu.data.data ? results.menu.data.data : { ...emptyItem.menu },
				service: results.service.data && results.service.data.data ? results.service.data.data : { ...emptyItem.service },
				saleItems: [],
			};

			setItem(newItem);

			// eslint-disable-next-line
			console.log('new item:', newItem);
		}
		catch (e) {
			console.log(e);
		}
	};

	const loadItemPrices = async () => {
		if (!year || !clientId)
			return;

		try {
			console.log(1);
		}
		catch (e) {
			console.log(e);
		}
	};

	const loadGroupSaleItems = async () => {
		if (!clientId || item.clientGroupId == null || !item.loaded) {
			setItem({ ...item, saleItems: [] });
			return;
		}

		try {
			let response;
			if (item.clientGroupId == -1) {
				response = await historicalPriceService.getClientSaleItemsWithoutGroup(clientId);
			}
			else if (item.clientGroupId == 0) {
				response = await historicalPriceService.getAllClientSaleItems(clientId);
			}
			else {
				response = await historicalPriceService.getClientGroupSaleItems(item.clientGroupId);
			}

			let items = response.data && response.data.data ? response.data.data : [];

			if (!items || !items.length) {
				setItem({ ...item, saleItems: [] });
				return;
			}

			console.log(items);
			setItem({ ...item, saleItems: items });
		}
		catch (e) {
			console.log(e);
		}
	};

	const loadHistoricalPriceReport = async () => {
		setItem({ ...item, historicalPrice: [] });

		if (!clientId || !item.saleItemId || !item.loaded) {
			return;
		}

		setLoadingHistoricalPriceReport(true);
		try {
			const response = await historicalPriceService.getClientSaleItemHistoricalPrice(clientId, item.saleItemId);

			let items = response.data && response.data.data ? response.data.data : [];

			if (!items || !items.length) {
				setItem({ ...item, historicalPrice: [] });
				setLoadingHistoricalPriceReport(false);
				return;
			}

			console.log(items);
			setItem({ ...item, historicalPrice: items });
		}
		catch (e) {
			console.log(e);
		}

		setLoadingHistoricalPriceReport(false);
	};

	const loadSalesReport = async () => {
		if (!year || !clientId || !item.loaded)
			return;

		setLoadingSalesReport(true);
		try {
			let itemPricesReport = [];
			const response1 = await clientsService.getSalesReportResume(clientId, (lunchRoomId || 0), year);
			if (response1.data && response1.data.data) {
				itemPricesReport = response1.data.data;
			}

			console.log("ITEM PRICES REPORT:", itemPricesReport);

			const response = await clientsService.getSalesReportDetails(clientId, (lunchRoomId || 0), year);

			let items = response.data && response.data.data ? response.data.data : [];

			if (!items || !items.length) {
				setItem({ ...item, salesReport: [], itemPricesReport });
				setLoadingSalesReport(false);
				return;
			}

			const salesReport = response.data.data;

			console.log("SALE REPORT:", salesReport);

			setItem({ ...item, salesReport, itemPricesReport });
		}
		catch (e) {
			console.log(e);
		}

		setLoadingSalesReport(false);
	};

	const loadClient = async () => {
		if (!clientId) {
			cleanScreen();
			return null;
		}

		try {
			const response = await clientsService.getData(clientId);
			if (
				!response.data ||
				response.data.status == 'Fail' ||
				!response.data.data
			) {
				return true;
			}

			return {
				...response.data.data,
				billData: { ...emptyItem.billData, ...response.data.data.billData, },
				contacts: [...emptyItem.contacts, ...response.data.data.contacts],
				salesReport: [],
				itemPricesReport: [],
				lunchRooms: [],
				menu: { ...emptyItem.menu, agreements: "", guarantee: "" },
				service: { ...emptyItem.service, agreement: "" },
				loaded: true,
			};
		}
		catch (e) {
			// eslint-disable-next-line
			console.log(e);
			return null;
		}
	};

	const getSellReportColumns = () => {
		let columns = [
			{
				field: 'className',
				title: 'Clase',
			},
			{
				field: 'enero',
				title: 'Enero',
				render: r => {
					return (<span>{r.className === 'Total $' ? formatCurrency(r.enero) : formatNumber(r.enero)}</span>);
				},
			},
			{
				field: 'febrero',
				title: 'Febrero',
				render: r => {
					return (<span>{r.className === 'Total $' ? formatCurrency(r.febrero) : formatNumber(r.febrero)}</span>);
				},
			},
			{
				field: 'marzo',
				title: 'Marzo',
				render: r => {
					return (<span>{r.className === 'Total $' ? formatCurrency(r.marzo) : formatNumber(r.marzo)}</span>);
				},
			},
			{
				field: 'abril',
				title: 'Abril',
				render: r => {
					return (<span>{r.className === 'Total $' ? formatCurrency(r.abril) : formatNumber(r.abril)}</span>);
				},
			},
			{
				field: 'mayo',
				title: 'Mayo',
				render: r => {
					return (<span>{r.className === 'Total $' ? formatCurrency(r.mayo) : formatNumber(r.mayo)}</span>);
				},
			},
			{
				field: 'junio',
				title: 'Junio',
				render: r => {
					return (<span>{r.className === 'Total $' ? formatCurrency(r.junio) : formatNumber(r.junio)}</span>);
				},
			},
			{
				field: 'julio',
				title: 'Julio',
				render: r => {
					return (<span>{r.className === 'Total $' ? formatCurrency(r.julio) : formatNumber(r.julio)}</span>);
				},
			},
			{
				field: 'agosto',
				title: 'Agosto',
				render: r => {
					return (<span>{r.className === 'Total $' ? formatCurrency(r.agosto) : formatNumber(r.agosto)}</span>);
				},
			},
			{
				field: 'septiembre',
				title: 'Septiembre',
				render: r => {
					return (<span>{r.className === 'Total $' ? formatCurrency(r.septiembre) : formatNumber(r.septiembre)}</span>);
				},
			},
			{
				field: 'octubre',
				title: 'Octubre',
				render: r => {
					return (<span>{r.className === 'Total $' ? formatCurrency(r.octubre) : formatNumber(r.octubre)}</span>);
				},
			},
			{
				field: 'noviembre',
				title: 'Noviembre',
				render: r => {
					return (<span>{r.className === 'Total $' ? formatCurrency(r.noviembre) : formatNumber(r.noviembre)}</span>);
				},
			},
			{
				field: 'diciembre',
				title: 'Diciembre',
				render: r => {
					return (<span>{r.className === 'Total $' ? formatCurrency(r.diciembre) : formatNumber(r.diciembre)}</span>);
				},
			},
		];

		return columns;
	};

	const getHistoricalPriceTableColumns = () => {
		let columns = [
			{
				field: 'date',
				title: 'Fecha',
				render: r => <span>{moment(r.date).format('DD/MM/YYYY')}</span>,
				// headerStyle: { width: '100px', maxwidth: '100px' },
				// cellStyle: { width: '100px', maxwidth: '100px' },
			},
			{
				field: 'date2',
				title: 'Año',
				render: r => <span>{moment(r.date).format('YYYY')}</span>,
				headerStyle: { width: '40px', maxwidth: '40px' },
				cellStyle: { width: '40px', maxwidth: '40px' },
			},
			{
				field: 'price',
				title: 'Precio',
				align: 'right',
				render: r => <span>{formatCurrency(r.price)}</span>,
				// headerStyle: { width: '100px', maxwidth: '100px' },
				// cellStyle: { width: '100px', maxwidth: '100px' },
			},
			{
				field: 'increase',
				title: 'Incremento',
				align: 'right',
				render: r => !r.increase ? "-" : <span>{formatCurrency(r.increase)}</span>,
				headerStyle: { width: '80px', maxwidth: '80px' },
				cellStyle: { width: '80px', maxwidth: '80px' },
			},
			{
				field: 'variation',
				title: 'Variación',
				align: 'center',
				render: r => !r.variation ? "-" : <span>{formatNumber(r.variation * 100)}%</span>,
				headerStyle: { width: '80px', maxwidth: '80px' },
				cellStyle: { width: '80px', maxwidth: '80px' },
			},
		];

		return columns;
	};

	const downloadFile = async (file) => {
		const response = await documentsService.downloadFile(file.id);
		var blob = new Blob([response.data], { type: "application/pdf" });
		var link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		var fileName = file.fileName;
		link.download = fileName;
		link.click();
	};

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		cleanScreen();
		loadItem();
	}, [clientId]);

	useEffect(() => {
		loadSalesReport();
		loadItemPrices();
	}, [lunchRoomId, year, item.loaded]);

	useEffect(() => {
		loadHistoricalPriceReport();
	}, [clientId, item.saleItemId, item.loaded]);

	useEffect(() => {
		setItem({ ...item, saleItemId: null });
		loadGroupSaleItems();
	}, [clientId, item.clientGroupId, item.loaded]);

	useEffect(() => {
		if (propsId) {
			// loadItemFromUrl();
		} else {
			cleanScreen();
		}
	}, [propsId]);

	useEffect(() => {
		setDocumentsColumns([
			{
				title: "Documento",
				field: 'contract'
			},
			{
				title: "Fecha inicio",
				field: 'startDate',
				type: 'date',
				// render: (row) => <Checkbox disabled checked={row.isSICSA} />,
				headerStyle: { width: '80px', maxwidth: '80px' },
				cellStyle: { width: '80px', maxwidth: '80px', padding: '5px 10px 5px 10px' },
			},
			{
				title: "Fecha vencimiento",
				field: 'expirationDate',
				type: 'date',
				// render: (row) => <Checkbox disabled checked={row.isSICSA} />,
				headerStyle: { width: '80px', maxwidth: '80px' },
				cellStyle: { width: '80px', maxwidth: '80px', padding: '5px 10px 5px 10px' },
			},
			{
				title: "Valor anual",
				field: 'annualValue',
				type: 'text',
				render: (row) => (
					<Typography variant="body1">
						{new Intl.NumberFormat('en-US',
							{ style: 'currency', currency: 'MXN' }
						).format(row.annualValue)}
					</Typography>
				),
				headerStyle: { width: '80px', maxwidth: '80px' },
				cellStyle: { width: '80px', maxwidth: '80px', padding: '5px 10px 5px 10px' },
			},
			{
				title: "Archivo",
				field: 'fileName',
				type: 'text',
				render: (row) => <Link href="#" onClick={() => downloadFile(row)}>{row.fileName}</Link>,
				headerStyle: { width: '80px', maxwidth: '80px' },
				cellStyle: { width: '80px', maxwidth: '80px', padding: '5px 10px 5px 10px' },
			},
			{
				title: "Estado",
				field: 'custom',
				type: 'text',
				render: (row) => {
					let now = new Date();
					let expirationDate = new Date(row.expirationDate);
					let status = "Vigente";
					if (expirationDate < now) {
						status = "Vencido";
					}

					return <Chip label={status} variant="outlined" color="primary" style={{ backgroundColor: status == "Vigente" ? 'green' : 'red', color: 'white' }} />;
				},
				headerStyle: { width: '80px', maxwidth: '80px' },
				cellStyle: { width: '80px', maxwidth: '80px', padding: '5px 10px 5px 10px' },
			},
		]);
	}, [item, item.documents]);

	const renderBillData = () => {
		// if (item) {
		// 	createdBy = users.find(x => x.id == item.createdBy);
		// }

		return (
			<form className="contracts-form" key={clientId}>
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 10 }}>
					<Grid item xs={12}>
						<Card>
							<CardHeader title="Datos fiscales" />
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12} md={7} lg={4}>
										<InputLabel>Denominación / Razón social</InputLabel>
										<Select
											isClearable
											value={clientId}
											onChange={(selected) => {
												if (selected && selected.value) {
													setClientId(selected.value);
												} else {
													setClientId(null);
													cleanScreen();
												}
											}}
											onClear={() => {
												setClientId(null);
												cleanScreen();
											}}
											options={clients}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="RFC" value={item.billData.rfc} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Oficina" value={item.lunchRoom || ''} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Código postal" value={item.billData.postalCode} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Uso CFDI" value={item.billData.cfdi.code} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Descripción uso" value={item.billData.cfdi.name} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={4} lg={3}>
										<TextField fullWidth disabled label="Regimen fiscal" value={item.billData.recipientRegime.code} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={8} lg={9}>
										<TextField fullWidth disabled label="Descripcion regimen" value={item.billData.recipientRegime.name} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Método de pago" value={item.billData.paymentMethod.description} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Forma de pago" value={item.billData.tenderType.code} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Plazo crédito" value={item.billData.creditTermDays} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Clave producto servicio" value={item.billData.productSAT.code} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Clave unidad" value={item.billData.productUnitSAT.code} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Unidad" value={item.billData.productUnitSAT.name} InputLabelProps={{ shrink: true }} />
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card>
							<CardHeader title="Datos de envío" />
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12} md={6} lg={6}>
										<TextField fullWidth disabled label="Nombre contacto:" value={item.billData.contactName} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={6}>
										<TextField fullWidth disabled label="Correo electrónico" value={item.billData.eMail} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={6}>
										<TextField fullWidth disabled label="Puesto" value={item.billData.position} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={6}>
										<TextField fullWidth disabled label="Télefono" value={item.billData.phoneNumber} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Portal / Link" value={item.billData.portalLink} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Usuario" value={item.billData.portalUser} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12} md={6} lg={4}>
										<TextField fullWidth disabled label="Contraseña" value={item.billData.portalPassword} InputLabelProps={{ shrink: true }} />
									</Grid>
									<Grid item className={classes.item} xs={12}>
										<TextField
											fullWidth
											disabled
											label="Observaciones"
											value={item.billData.comments}
											InputLabelProps={{ shrink: true }}
											multiline
											rows={6}
											variant="outlined"
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</form>
		);
	};

	const renderContacts = () => {
		return (
			<form className="contacts-form" key={clientId}>
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 10 }}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12}>
										<Table
											title="SERVICIO INDUSTRIAL COMEDORES"
											items={item.contacts.filter(x => x.type == 1)}
											columns={columns}
											options={{
												paging: false,
												fixedColumns: {
													left: 1,
													right: 1
												}
											}}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12}>
										<Table
											title="CLIENTE"
											items={item.contacts.filter(x => x.type == 2)}
											columns={columns}
											options={{
												paging: false,
												fixedColumns: {
													left: 1,
													right: 0
												}
											}}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</form>
		);
	};

	const renderExpenses = () => {
		return (
			<form className="expenses-form" key={clientId}>
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 10 }}>
					<Grid item xs={12}>
						<Card>
							<CardHeader title="ACUERDOS GASTOS" />
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12}>
										<Table
											title="ACUERDOS GASTOS"
											items={item.expenses}
											columns={expensesColumns}
											options={{
												paging: false,
												search: false,
												toolbar: false,
												showTitle: false,
												tableLayout: 'fixed',
											}}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</form>
		);
	};

	const renderDocuments = () => {
		return (
			<form className="documents-form" key={clientId}>
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 10 }}>
					<Grid item xs={12}>
						<Card>
							<CardHeader title="DOCUMENTOS FISCALES" />
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12}>
										<Table
											items={item.documents}
											columns={documentsColumns}
											options={{
												paging: false,
												search: false,
												toolbar: false,
												showTitle: false,
												tableLayout: 'fixed',
											}}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</form>
		);
	};

	const renderDaysCard = () => {
		if (!item || !item.loaded) {
			return null;
		}

		let days = 0;

		console.log('priceRevisionDate', item.priceRevisionDate);

		if (item.priceRevisionDate) {
			days = moment(item.priceRevisionDate).diff(moment(), 'day');
			if (days < 0)
				days = 0;
		}

		return (
			<Grid item xs={12} md={6}>
				<Card>
					<CardContent style={{ height: 200, padding: 0 }}>
						<Grid container className={classes.root} spacing={2} style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', alignContent: 'center', height: '100%' }}>
							<Typography variant="h3" gutterBottom style={{ color: days > 0 ? "green" : '#ff9292' }}>
								Dias pendientes para revisión de precios {item.priceRevisionDate && `(${moment(item.priceRevisionDate).format('DD/MM/YYYY')})`}
							</Typography>
							<Typography variant="h2" gutterBottom style={{ color: days > 0 ? "green" : '#ff3030' }}>
								{
									days
								}
							</Typography>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		);
	};

	const renderSells = () => {
		const salesReportColumns = getSellReportColumns();

		console.log(item.salesReport);

		if (!item)
			return <CircularProgress />;

		return (
			<form className="sales-form">
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 20 }}>
					<Grid item xs={12} md={6}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12} md={4}>
										<InputLabel>Año</InputLabel>
										<Select
											isClearable
											value={year}
											onChange={(selected) => setYear(selected.value)}
											options={[2022, 2023].map(x => ({ value: x, label: x.toString() }))}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={4}>
										<InputLabel>Unidad</InputLabel>
										<Select
											isClearable
											value={lunchRoomId}
											onChange={(selected) => setLunchRoomId(selected.value)}
											options={item.lunchRooms.map(x => ({ value: x.lunchRoomId, label: x.lunchRoomName }))}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12}>
										<Table
											key={item.itemPricesReport.length}
											items={item.itemPricesReport}
											columns={[
												{
													field: 'lunchRoomName',
													title: 'Unidad'
												},
												{
													field: 'className',
													title: 'Clase'
												},
												{
													field: 'itemName',
													title: 'Concepto',
												},
												{
													field: 'unitPrice',
													title: 'Precio Unitario',
													align: 'right',
													render: r => <span style={{ width: '100%', textAlign: 'right' }}>{r.unitPrice ? formatCurrency(r.unitPrice) : "-"}</span>,
												},
												{
													field: 'modifiedDate',
													title: 'Ultima actualización',
													align: 'center',
													render: r => <span style={{ width: '100%', textAlign: 'center' }}>{r.modifiedDate ? moment(r.modifiedDate).format("DD/MM/YYYY") : "-"}</span>,
												},
											]}
											options={{
												paging: false,
												search: false,
												toolbar: false,
												showTitle: false,
												padding: 'dense',
											}}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>

					{
						renderDaysCard()
					}

					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12}>
										{loadingSalesReport && <CircularProgress />}
										{
											!loadingSalesReport &&
											<Table
												key={salesReportColumns.length}
												items={item.salesReport}
												columns={salesReportColumns}
												options={{
													paging: false,
													search: false,
													toolbar: false,
													showTitle: false,
													rowStyle: rowData => ({
														backgroundColor: (rowData.className === 'Total $') ? '#bcffbc' : 'inherit',
														// color: (rowData.className === 'Total $') ? 'white' : 'inherit'
													})
												}}
											/>
										}
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</form >
		);
	};

	const renderMenu = () => {
		return (
			<form className="contacts-form" key={clientId}>
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 10 }}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12}>
										<Table
											key={clientId}
											title="Esquema de menu"
											items={item.menu.menuItems}
											columns={menuItemsColumns}
											options={{
												paging: false,
												maxBodyHeight: 350,
											}}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6}>
										<TextField
											key={clientId + '' + item.menu.agreements}
											id="outlined-multiline-static"
											label="Acuerdos"
											multiline
											rows={3}
											value={item.menu.agreements || ""}
											inputProps={{
												readonly: true,
												readOnly: true,
											}}
											variant="outlined"
											fullWidth
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={6}>
										<TextField
											key={`${clientId}-${item.businessName}`}
											id="outlined-multiline-static"
											label="Garantías"
											multiline
											rows={3}
											value={item.menu.guarantee || ""}
											inputProps={{
												readonly: true,
												readOnly: true,
											}}
											variant="outlined"
											fullWidth
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12}>
										<Table
											key={`${clientId}-${item.businessName}`}
											title="Horarios servicios"
											items={item.service.menuShifts}
											columns={menuShiftsColumns}
											options={{
												paging: false,
												maxBodyHeight: 300,
											}}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12}>
										<TextField
											key={`${clientId}-${item.businessName}`}
											id="outlined-multiline-static"
											label="Acuerdos"
											multiline
											rows={3}
											value={item.service.agreement}
											inputProps={{
												readonly: true,
												readOnly: true,
											}}
											variant="outlined"
											fullWidth
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</form>
		);
	};

	const renderHistoricalPriceChart = () => {
		if (!item || !item.loaded || !item.historicalPrice || !item.historicalPrice.length || !item.saleItemId) {
			return null;
		}

		return (
			<Grid item xs={12} md={6}>
				<Card>
					<CardContent style={{ height: 400, padding: 0 }}>
						<Grid container className={classes.root} spacing={2} style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', alignContent: 'center', height: '100%' }}>
							<HistoricalPriceChart key={item.saleItemId} data={item.historicalPrice} />
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		);
	};

	const renderHistoricalPrice = () => {
		const columns = getHistoricalPriceTableColumns();

		if (!item)
			return <CircularProgress />;

		return (
			<form className="sales-form">
				<Grid container className={classes.root} spacing={2} style={{ paddingTop: 20 }}>
					<Grid item xs={12} md={6}>
						<Card>
							<CardContent>
								<Grid container className={classes.root} spacing={1}>
									<Grid item className={classes.item} xs={12} md={4}>
										<InputLabel>Grupo</InputLabel>
										<Select
											isClearable
											value={item.clientGroupId}
											onChange={(selected) => setItem({ ...item, clientGroupId: selected.value, saleItemId: null })}
											options={item.clientGroups.map(x => ({ value: x.key, label: x.value }))}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12} md={8}>
										<InputLabel>Artículo</InputLabel>
										<Select
											isClearable
											key={item.clientGroupId}
											value={item.saleItemId}
											onChange={(selected) => setItem({ ...item, saleItemId: selected.value })}
											options={(item.saleItems || []).map(x => ({ value: x.key, label: x.value }))}
											styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											menuPosition={'absolute'}
											menuPlacement={'bottom'}
										/>
									</Grid>
									<Grid item className={classes.item} xs={12}>
										{loadingHistoricalPriceReport && <CircularProgress />}
										{
											!loadingHistoricalPriceReport &&
											<Table
												key={columns.length}
												items={item.historicalPrice}
												columns={columns}
												options={{
													paging: false,
													search: false,
													toolbar: false,
													showTitle: false,
													tableLayout: "fixed",
												}}
											/>
										}
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					{
						renderHistoricalPriceChart()
					}
				</Grid>
			</form >
		);
	};

	const renderTabs = () => {
		return (
			<div>
				<AppBar position="static" color="default">
					<Tabs
						key={clientId + "-" + item.businessName}
						value={tab}
						onChange={(_, tab) => setTab(tab)}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab label="Facturación" icon={<Receipt />} {...tabProps(0)} />
						<Tab disabled={!item || !item.loaded} label="Ventas" icon={<MonetizationOn />} {...tabProps(1)} />
						<Tab disabled={!item || !item.loaded} label="Menú" icon={<MenuBook />} {...tabProps(2)} />
						<Tab disabled={!item || !item.loaded} label="Gastos" icon={<List />} {...tabProps(3)} />
						<Tab disabled={!item || !item.loaded} label="Documentos" icon={<InsertDriveFile />} {...tabProps(4)} />
						<Tab disabled={!item || !item.loaded} label="Contactos" icon={<ContactPhone />} {...tabProps(5)} />
						<Tab disabled={!item || !item.loaded} label="Precio Historico" icon={<Money />} {...tabProps(6)} />
					</Tabs>
				</AppBar>
				<TabPanel value={tab} index={0} boxStyle={{ paddingTop: '0px' }}>
					{renderBillData()}
				</TabPanel>
				<TabPanel value={tab} index={1} boxStyle={{ paddingTop: '0px' }}>
					{renderSells()}
				</TabPanel>
				<TabPanel value={tab} index={2} boxStyle={{ paddingTop: '0px' }}>
					{renderMenu()}
				</TabPanel>
				<TabPanel value={tab} index={3} boxStyle={{ paddingTop: '0px' }}>
					{renderExpenses()}
				</TabPanel>
				<TabPanel value={tab} index={4} boxStyle={{ paddingTop: '0px' }}>
					{renderDocuments()}
				</TabPanel>
				<TabPanel value={tab} index={5} boxStyle={{ paddingTop: '0px' }}>
					{renderContacts()}
				</TabPanel>
				<TabPanel value={tab} index={6} boxStyle={{ paddingTop: '0px' }}>
					{renderHistoricalPrice()}
				</TabPanel>
			</div>
		);
	};

	const componentLayout = () => (
		<Portlet className={classes.root}>
			<PortletContent noPadding>
				{
					loading
						? <CircularProgress />
						: renderTabs()
				}
			</PortletContent>
		</Portlet>
	);

	if (title != null) {
		return componentLayout();
	}

	return (
		<DashboardLayout
			title={"Contratos / Clientes"}
		>
			<div className={classes.root}>
				<div className={classes.content}>{componentLayout()}</div>
			</div>
		</DashboardLayout>
	);
}

ClientsContractsForm.propTypes = {
	classes: PropTypes.object.isRequired,
	id: PropTypes.number,
	title: PropTypes.string,
	onCreated: PropTypes.func,
	onBack: PropTypes.func
};

ClientsContractsForm.defaultProps = {
	id: null,
	onBack: () => { },
	onCreated: () => { },
	title: null
};

export default WithNotifications(withRouter(withStyles(styles)(ClientsContractsForm)));
