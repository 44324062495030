export default {
	body: {
		emptyDataSourceMessage: "No hay datos para mostrar",
		addTooltip: "Agregar",
		deleteTooltip: "Borrar",
		editTooltip: "Editar",
		filterRow: {
			filterTooltip: "Filtrar"
		},
		editRow: {
			deleteText: "¿ Seguro que desea eliminar el registro ?",
			cancelTooltip: "Cancelar",
			saveTooltip: "Confirmar"
		}
	},
	grouping: {
		placeholder: "Arrastra columas aqui"
	},
	header: {
		actions: "Acciones"
	},
	pagination: {
		labelDisplayedRows: "{from}-{to} de {count}",
		labelRowsSelect: "filas",
		labelRowsPerPage: "Filas por pagina",
		firstAriaLabel: "Primera página",
		firstTooltip: "Primera página",
		previousAriaLabel: "Anterior",
		previousTooltip: "Anterior",
		nextAriaLabel: "Siguiente",
		nextTooltip: "Siguiente",
		lastAriaLabel: "Ultima página",
		lastTooltip: "Ultima página"
	},
	toolbar: {
		addRemoveColumns: "Agregar o quitar columnas",
		nRowsSelected: "{0} seleccionados",
		showColumnsTitle: "Mostrar columnas",
		showColumnsAriaLabel: "Mostrar columnas",
		exportTitle: "Exportar",
		exportAriaLabel: "Exportart",
		exportName: "Exportar CSV",
		searchTooltip: "Buscar",
		searchPlaceholder: "Buscar"
	}
};