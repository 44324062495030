import Api from './Api';

class NoticiesService extends Api {
  constructor(controller) {
    super(controller);
  }

  getRecent = model => {
    return this.API.get(`${this.controller}/recent`, model);
  };
}

let service = new NoticiesService('news');

export default service;
