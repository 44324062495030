import API from "./Api";

class UserService extends API {

    constructor(controller) {
        super(controller);
    }

    recoverPassword = (user) => {
        return this.API.post(`${this.controller}/recover-password`, { user });
    }

    updatePassword = (password) => {
        return this.API.put(`${this.controller}/updatePassword`, { password });
    }

    search = (search) => {
        return this.API.get(`${this.controller}/search/${search}`);
    }

    getSettings = () => {
        return this.API.get(`company-settings`);
    }
}

let userService = new UserService("users");

export default userService;