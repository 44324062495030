import Api from "./Api";

class ItemPresentationsService extends Api {
	constructor(controller) {
		super(controller);
	}

	save = model => {
		return this.API.post(`${this.controller}`, model);
	};

	getSupplierItems = (supplierId) => {
		return this.API.get(`${this.controller}/${supplierId}/GetItems`);
	};

	getAllSupplierItems = (supplierId, itemId) => {
		return this.API.get(`items-external/${supplierId}/items-best-price/${itemId}`);
	};

	getItems = () => {
		return this.API.get(`items`);
	};

	getGroups = () => {
		return this.API.get(`items/groups`);
	};

	getMeasurementUnits = () => {
		return this.API.get(`items/measurement-units`);
	};

	getUnitKeys = () => {
		return this.API.get(`items/unit-keys-sat`);
	};

	upsert = (model) => {
		return this.API.post(`items`, model);
	};

	getItemById = (id) => {
		return this.API.get(`items/${id}`);
	};
}

let service = new ItemPresentationsService("item-presentations");

export default service;