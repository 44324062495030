import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import userService from "services/userService";
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';
import { Redirect } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core';
import schema from './schema';
import {
    Grid,
    Button,
    CircularProgress,
    TextField,
    Typography,
    Divider
} from '@material-ui/core';
import Swal from 'sweetalert2';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import styles from './styles';

class SignIn extends Component {
    state = {
        values: {
            email: '',
            password: ''
        },
        touched: {
            email: false,
            password: false
        },
        errors: {
            email: null,
            password: null
        },
        isValid: false,
        isLoading: false,
        submitError: null,
        isAuthenticated: false,
        authError: null,
        recoverDialogOpen: false,
        recoverUser: ""
    };

    componentDidMount = () => {

    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.isAuthenticated !== this.props.isAuthenticated) {
            this.setState({ isAuthenticated: nextProps.isAuthenticated });
        }

        if (nextProps.authError !== this.props.authError && nextProps.authError) {
            this.props.enqueueSnackbar(nextProps.authError, { variant: 'error' });
            this.setState({ authError: nextProps.authError });
            this.userInput.focus();
        }

        return true;
    }

    handleBack = () => {
        const { history } = this.props;

        history.goBack();
    };

    validateForm = _.debounce(() => {
        const { values } = this.state;

        const newState = { ...this.state };
        const errors = validate(values, schema);

        newState.errors = errors || {};
        newState.isValid = errors ? false : true;

        this.setState(newState);
    }, 300);

    handleFieldChange = (field, value) => {
        const newState = { ...this.state };

        newState.submitError = null;
        newState.touched[field] = true;
        newState.values[field] = value;

        this.setState(newState, this.validateForm);
    };

    handleSignIn = async () => {
        try {
            const { login } = this.props;
            const { values } = this.state;
            login({ username: values.email, password: values.password });
        } catch (error) {
            this.setState({
                // isLoading: false,
                serviceError: error
            });
        }
    };

    recoverPassword = async () => {
        const {
            recoverUser
        } = this.state;

        this.setState({ recoverDialogOpen: false, recoverUser: "" });

        userService.recoverPassword(recoverUser).then((response) => {

            if (response.status != 200) {
                Swal.fire(
                    'Error',
                    'Error reestableciendo contraseña',
                    'error'
                );
            }
            else {
                Swal.fire(
                    'Correcto',
                    'Se envió contraseña al correo registrado del usuario solo si existe',
                    'success'
                );
            }
        });
    }

    renderRecoverPasswordDialog = () => {
        const {
            recoverDialogOpen,
            recoverUser
        } = this.state;

        return (
            <Dialog
                open={recoverDialogOpen}
                onClose={() => {
                    this.setState({ recoverDialogOpen: false });
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Recuperar contraseña</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Escribe tu nombre de usuario o correo electronico para enviarte una nueva contraseña
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Usuario o email"
                        type="text"
                        fullWidth
                        value={recoverUser}
                        onChange={event => this.setState({ recoverUser: event.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            this.setState({ recoverDialogOpen: false });
                        }}
                        color="primary"
                    >
                        Cancelar
                  </Button>
                    <Button
                        disabled={!recoverUser || recoverUser.length < 4}
                        onClick={this.recoverPassword}
                        color="primary"
                    >
                        Enviar
                  </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        const { classes, isLoading, isAuthenticated, user, enqueueSnackbar } = this.props;
        const {
            values,
            touched,
            errors,
            isValid,
            submitError
        } = this.state;

        const showEmailError = touched.email && errors.email;
        const showPasswordError = touched.password && errors.password;

        if (isAuthenticated) {
            if (!user.needChangePassword) {
                return <Redirect to='/' />;
            }
            else {
                enqueueSnackbar('Se necesita ingresar nueva contraseña', { variant: 'warning' });
                return <Redirect to='/change-password' />;
            }
        }

        return (
            <div className={classes.root}>
                {this.renderRecoverPasswordDialog()}
                <Grid
                    className={classes.grid}
                    container
                >
                    <Grid
                        className={classes.quoteWrapper}
                        item
                        lg={5}
                    >
                        <div className={classes.quote}>
                            <div className={classes.quoteInner}>
                                <Typography
                                    className={classes.quoteText}
                                    variant="h1"
                                >
                                    Sistema Empresarial Alimentro
								</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        className={classes.content}
                        item
                        lg={7}
                        xs={12}
                    >
                        <div className={classes.content}>
                            <div className={classes.contentImage}>
                                <div className={classes.quote}>
                                    <div className={classes.quoteInner}>
                                        <Typography
                                            className={classes.quoteText}
                                            variant="h1"
                                        >
                                            SEA
										</Typography>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.contentBody}>

                                <form className={classes.form}>
                                    <Typography
                                        className={classes.title}
                                        variant="h2"
                                    >
                                        Iniciar sesión
									</Typography>
                                    <div className={classes.fields}>
                                        <TextField
                                            inputRef={i => this.userInput = i}
                                            autoFocus
                                            className={classes.textField}
                                            label="Usuario"
                                            name="email"
                                            onChange={event =>
                                                this.handleFieldChange('email', event.target.value)
                                            }
                                            type="text"
                                            value={values.email}
                                            variant="outlined"
                                        />
                                        {showEmailError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.email[0]}
                                            </Typography>
                                        )}

                                        <div className={classes.fakefields}>
                                            <input type="password" name='fake-password' autoComplete='new-password' tabIndex='-1' style={{ opacity: 0, float: 'left', border: 'none', height: '0', width: '0' }} />
                                        </div>

                                        <TextField
                                            ref={i => this.passwordInput = i}
                                            className={classes.textField}
                                            label="Contraseña"
                                            name="password"
                                            onChange={event =>
                                                this.handleFieldChange('password', event.target.value)
                                            }
                                            type="password"
                                            value={values.password}
                                            variant="outlined"
                                            onKeyPress={e => {
                                                if (e.key == "Enter" && isValid) {
                                                    this.handleSignIn();
                                                }
                                            }}
                                        />
                                        {showPasswordError && (
                                            <Typography
                                                className={classes.fieldError}
                                                variant="body2"
                                            >
                                                {errors.password[0]}
                                            </Typography>
                                        )}
                                    </div>
                                    {submitError && (
                                        <Typography
                                            className={classes.submitError}
                                            variant="body2"
                                        >
                                            {submitError}
                                        </Typography>
                                    )}
                                    {
                                        isLoading
                                            ? (
                                                <CircularProgress className={classes.progress} />
                                            )
                                            : (
                                                <Button
                                                    className={classes.signInButton}
                                                    color="primary"
                                                    disabled={!isValid}
                                                    onClick={this.handleSignIn}
                                                    size="large"
                                                    variant="contained"
                                                >
                                                    Enviar
											</Button>
                                            )
                                    }
                                    <Grid container justify="center">
                                        <Button
                                            color="primary"
                                            className={classes.recoverButton}
                                            onClick={() => this.setState({ recoverDialogOpen: true })}
                                        >
                                            Recuperar contraseña
                                        </Button>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        className={classes.footer}
                        item
                        xs={12}
                    >
                        <Divider />
                        <Grid container justify="center" alignItems="center" className={classes.footerGrid}>
                            <Typography className={classes.text} variant="body1">Powered By</Typography>
                            <img alt="MADS" className={classes.logoImage} src="/images/logos/mads-logo.png" />
                        </Grid>
                    </Grid>
                </Grid>
            </div >
        );
    }
}

SignIn.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withSnackbar,
    withStyles(styles)
)(SignIn);