export default {
	email: {
		presence: { allowEmpty: false, message: 'El campo es requerido' },
		email: false,
		length: {
			maximum: 64
		}
	},
	password: {
		presence: { allowEmpty: false, message: 'El campo es requerido' },
		length: {
			maximum: 64
		}
	}
};