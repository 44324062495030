import { takeLatest, put, call } from 'redux-saga/effects';
import {
  AttachmentConfigTypes,
  AttachmentConfigCreators
} from 'redux/AttachmentConfigRedux';
import attachmentConfigService from 'services/attachmentConfigService';
import AttachmentConfig from 'contract/responses/AttachmentConfig';
import { MessageOperation } from '../constants';
import { getAll as getAllsubCategories } from './subCategorySagas';
import { subCategoryAttachmentGetByAttachmentConfigId as getSubCategoryAttachmentConfigs } from './subCategoryAttachmentConfigSagas';

export function* getAttachmentConfigs() {
  yield put(AttachmentConfigCreators.setLoading(true));
  yield* getAllsubCategories(false);
  yield* getAll(false);

  yield put(AttachmentConfigCreators.setLoading(false));
}

export function* getAll(showLoading) {
  if (showLoading) yield put(AttachmentConfigCreators.setLoading(true));

  const response = yield call(attachmentConfigService.getAll);

  if (response.ok) {
    yield put(
      AttachmentConfigCreators.attachmentConfigListSuccess(response.data.data)
    );
  } else {
    yield put(
      AttachmentConfigCreators.attachmentConfigSetMessage(
        MessageOperation.error
      )
    );
  }

  if (showLoading) yield put(AttachmentConfigCreators.setLoading(false));
}

export function* getById({ id }) {
  yield put(AttachmentConfigCreators.setLoading(true));
  const response = yield call(attachmentConfigService.getById, id);

  if (response.ok) {
    let attachmentConfig = new AttachmentConfig(response.data.data);
    yield* getSubCategoryAttachmentConfigs({ id: id });
    yield put(
      AttachmentConfigCreators.attachmentConfigGetSuccess(attachmentConfig)
    );
  } else {
    yield put(
      AttachmentConfigCreators.attachmentConfigSetMessage(
        MessageOperation.error
      )
    );
  }

  yield put(AttachmentConfigCreators.setLoading(false));
}

export function* saveMultiple({ attachmentConfig }) {
  yield put(AttachmentConfigCreators.setLoading(true));

  const response = yield call(
    attachmentConfigService.saveMultiple,
    attachmentConfig
  );

  if (response.ok) {
    let attachmentConfig = new AttachmentConfig(response.data.data);
    yield put(
      AttachmentConfigCreators.attachmentConfigCreateSuccess(
        attachmentConfig,
        MessageOperation.success
      )
    );
    yield* getById({ id: attachmentConfig.id });
  } else {
    yield put(
      AttachmentConfigCreators.attachmentConfigSetMessage(
        MessageOperation.error
      )
    );
  }

  yield put(AttachmentConfigCreators.setLoading(false));
}

export function* create({ attachmentConfig }) {
  yield put(AttachmentConfigCreators.setLoading(true));

  const response = yield call(attachmentConfigService.create, attachmentConfig);

  if (response.ok) {
    let attachmentConfig = new AttachmentConfig(response.data.data);
    yield put(
      AttachmentConfigCreators.attachmentConfigCreateSuccess(
        attachmentConfig,
        MessageOperation.success
      )
    );
    yield* getById({ id: attachmentConfig.id });
  } else {
    yield put(
      AttachmentConfigCreators.attachmentConfigSetMessage(
        MessageOperation.error
      )
    );
  }

  yield put(AttachmentConfigCreators.setLoading(false));
}

export function* update({ attachmentConfig }) {
  yield put(AttachmentConfigCreators.setLoading(true));

  const response = yield call(attachmentConfigService.update, attachmentConfig);

  if (response.ok) {
    yield put(
      AttachmentConfigCreators.attachmentConfigUpdateSuccess(
        attachmentConfig,
        MessageOperation.success
      )
    );
    yield* getById({ id: attachmentConfig.id });
  } else {
    yield put(
      AttachmentConfigCreators.attachmentConfigSetMessage(
        MessageOperation.error
      )
    );
  }

  yield put(AttachmentConfigCreators.setLoading(false));
}

export function* deleteItem({ id }) {
  yield put(AttachmentConfigCreators.setLoading(true));

  const response = yield call(attachmentConfigService.delete, id);

  if (response.ok) {
    yield put(
      AttachmentConfigCreators.attachmentConfigDeleteSuccess(
        id,
        MessageOperation.success
      )
    );
  } else {
    yield put(
      AttachmentConfigCreators.attachmentConfigSetMessage(
        MessageOperation.error
      )
    );
  }

  yield put(AttachmentConfigCreators.setLoading(false));
}

/*
	CompanyLocationSagas
*/
function* AttachmentConfigSagas() {
  yield takeLatest(
    AttachmentConfigTypes.ATTACHMENT_CONFIG_LIST_REQUEST,
    getAttachmentConfigs
  );
  yield takeLatest(
    AttachmentConfigTypes.ATTACHMENT_CONFIG_GET_REQUEST,
    getById
  );
  yield takeLatest(
    AttachmentConfigTypes.ATTACHMENT_CONFIG_CREATE_REQUEST,
    create
  );
  yield takeLatest(
    AttachmentConfigTypes.ATTACHMENT_CONFIG_SAVE_MULTIPLE_REQUEST,
    saveMultiple
  );
  yield takeLatest(
    AttachmentConfigTypes.ATTACHMENT_CONFIG_UPDATE_REQUEST,
    update
  );
  yield takeLatest(
    AttachmentConfigTypes.ATTACHMENT_CONFIG_DELETE_REQUEST,
    deleteItem
  );
}

export default AttachmentConfigSagas;
