import * as React from 'react';
import Modal from 'react-modal';
import { CloseRounded } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

const defaultStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		padding: '0px',
		transform: 'translate(-50%, -50%)'
	}
};

const ModalComponent = ({ title, children, style, ...props }) => {

	const modalStyles = {
		...defaultStyles,
		content: {
			...defaultStyles.content,
			...style
		}
	};

	return (
		<Modal
			{...props}
			style={modalStyles}
		>
			<div
				style={{
					display: 'flex',
					height: '40px',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: '20px 0px 20px 15px',
					borderBottom: '1px solid #ddd',
				}}
			>
				<h4 style={{ marginRight: '20px' }}>{title}</h4>
				<IconButton
					onClick={() => {
						if (props.onRequestClose) {
							props.onRequestClose();
						}
					}}
				>
					<CloseRounded fontSize="large" />
				</IconButton>
			</div>
			{children}
		</Modal >
	);
};

export default ModalComponent; 