import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import WorkItemTask from 'contract/requests/WorkItemTask';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    setLoading: ['isLoading'],
    workItemTaskGetListRequest: ['id'],
    workItemTaskGetListSuccess: ['workItemTasks'],
    workItemTaskGetListFailure: ['error'],
    workItemTaskGetRequest: ['id'],
    workItemTaskGetSuccess: ['workItemTask'],
    workItemTaskGetFailure: ['error'],
    workItemTaskGetByWorkItemIdRequest: ['id', 'subCategoryId'],
    workItemTaskGetByWorkItemIdSuccess: ['workItemTasks'],
    workItemTaskGetByWorkItemIdFailure: ['error'],
    workItemTaskCreateRequest: ['workItemTask'],
    workItemTaskCreateSuccess: ['workItemTask', 'messageOperation'],
    workItemTaskCreateFailure: ['error'],
    workItemTaskUpdateRequest: ['workItemTask'],
    workItemTaskUpdateSuccess: ['workItemTask', 'messageOperation'],
    workItemTaskUpdateFailure: ['error'],
    workItemTaskDeleteRequest: ['id'],
    workItemTaskDeleteSuccess: ['id', 'messageOperation'],
    workItemTaskDeleteFailure: ['error'],
    workItemTaskSetMessage: ['messageOperation']
  },
  {}
);

export const WorkItemTaskTypes = Types;
export const WorkItemTaskCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  workItemTasks: [],
  isLoading: false,
  messageOperation: null,
  tasks: []
});

export const onWorkItemTaskGetListSuccess = (state, { workItemTasks }) =>
  state.merge({ workItemTasks: workItemTasks });
export const onWorkItemTaskGetSuccess = (state, { workItemTask }) =>
  state.merge({ workItemTask: workItemTask });
export const onWorkItemTaskGetByWorkItemIdSuccess = (
  state,
  { workItemTasks }
) => state.merge({ workItemTasks: workItemTasks });
export const onWorkItemTaskCreateSuccess = (
  state,
  { workItemTask, messageOperation }
) =>
  state.merge({
    workItemTasks: state.workItemTasks.concat(workItemTask),
    messageOperation,
    workItemTask: workItemTask
  });
export const onWorkItemTaskUpdateSuccess = (
  state,
  { workItemTask, messageOperation }
) =>
  state.merge({
    workItemTasks: state.workItemTasks.map(i =>
      i.id === workItemTask.id ? workItemTask : i
    ),
    workItemTask: workItemTask,
    messageOperation
  });
export const onWorkItemTaskDeleteSuccess = (
  state,
  { id, messageOperation }
) => {
  let item = state.workItemTasks.find(i => i.id == id);
  return state.merge({
    workItemTasks: state.workItemTasks.map(i =>
      i.id == item.id ? { ...item, isActive: false } : i
    ),
    messageOperation
  });
};

export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });
export const onWorkItemTaskSetMessage = (state, { messageOperation }) =>
  state.merge({ messageOperation });

//map our actions types to our reducer functions
export const HANDLERS = {
  [WorkItemTaskTypes.WORK_ITEM_TASK_GET_LIST_SUCCESS]: onWorkItemTaskGetListSuccess,
  [WorkItemTaskTypes.WORK_ITEM_TASK_GET_SUCCESS]: onWorkItemTaskGetSuccess,
  [WorkItemTaskTypes.WORK_ITEM_TASK_GET_BY_WORK_ITEM_ID_SUCCESS]: onWorkItemTaskGetByWorkItemIdSuccess,
  [WorkItemTaskTypes.WORK_ITEM_TASK_CREATE_SUCCESS]: onWorkItemTaskCreateSuccess,
  [WorkItemTaskTypes.WORK_ITEM_TASK_UPDATE_SUCCESS]: onWorkItemTaskUpdateSuccess,
  [WorkItemTaskTypes.WORK_ITEM_TASK_DELETE_SUCCESS]: onWorkItemTaskDeleteSuccess,
  [WorkItemTaskTypes.SET_LOADING]: onSetLoading,
  [WorkItemTaskTypes.WORK_ITEM_TASK_SET_MESSAGE]: onWorkItemTaskSetMessage
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectIsLoading = state => state.workItemTaskReducer.isLoading;
export const selectMessageOperation = state =>
  state.workItemTaskReducer.messageOperation;
export const selectWorkItemTasks = state =>
  Immutable.asMutable(state.workItemTaskReducer.workItemTasks, {
    deep: true
  });
export const selectWorkItemTask = state =>
  state.workItemTaskReducer.workItemTask;
export const selectSubCategoryTasks = state => {
  let tasksBySubCategory =
    state.taskReducer.taskSubCategories &&
    state.taskReducer.taskSubCategories.length > 0
      ? Immutable.asMutable(state.taskReducer.taskSubCategories, {
          deep: true
        }).map(
          item =>
            new WorkItemTask({
              taskId: item.id,
              task: item,
              isActive: item.isActive,
              childWorkItemId: 0
            })
        )
      : [];
  let foundItem;
  if (state.workItemTaskReducer.workItemTasks)
    state.workItemTaskReducer.workItemTasks.forEach(item => {
      foundItem = tasksBySubCategory.find(x => x.taskId == item.taskId);
      if (foundItem) {
        foundItem.isActive = item.isActive;
        foundItem.workItemId = item.workItemId;
        foundItem.id = item.id;
        foundItem.notes = item.notes;
        foundItem.realizedBy = item.realizedBy;
        foundItem.createdBy = item.createdBy;
        foundItem.createdDate = item.createdDate;
        foundItem.childWorkItemId = item.childWorkItemId;
      }
    });
  return tasksBySubCategory;
};
