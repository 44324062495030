import API from "./Api";

class ProductssService extends API {

    constructor(controller) {
        super(controller);
    }

    search = (itemName, storageId, classificationId) => {
        return this.API.post(`${this.controller}/search`, {
            itemName,
            storageId,
            classificationId,
            supplierId: 512
        });
    }
}

let productsService = new ProductssService("products");

export default productsService;