import { takeLatest, call, put } from 'redux-saga/effects';
import { EntityTypes, EntityTypeCreators } from 'redux/EntityTypeRedux';
import entityTypeService from 'services/entityTypeService';
import EntityType from 'contract/responses/EntityType';
import { MessageOperation } from '../constants';

export function* getAll(showLoading) {
  if (showLoading) yield put(EntityTypeCreators.setLoading(true));

  const response = yield call(entityTypeService.getAll);

  if (response.ok) {
    yield put(
      EntityTypeCreators.entityTypeGetListSuccess(
        response.data.data.map(item => new EntityType(item))
      )
    );
  } else {
    yield put(EntityTypeCreators.entityTypeSetMessage(MessageOperation.error));
  }

  if (showLoading) yield put(EntityTypeCreators.setLoading(false));
}

/**
 * sagas
 */
function* entityTypesSagas() {
  yield takeLatest(EntityTypes.ENTITY_TYPE_GET_LIST_REQUEST, getAll);
}

export default entityTypesSagas;
