import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions({
	stateListRequest: [],
	stateListSuccess: ['states'],
	stateListError: ['error'],
	setLoading: ["isLoading"]
}, {});

export const StateTypes = Types;
export const StateCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
	states: [],
	isLoading: false
});

export const onStateListSuccess = (state, { states }) => state.merge({ states });
export const onSetLoading = (state, { isLoading }) => state.merge({ isLoading });

//map our actions types to our reducer functions
export const HANDLERS = {
	[StateTypes.STATE_LIST_SUCCESS]: onStateListSuccess,
	[StateTypes.SET_LOADING]: onSetLoading
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectStates = state => Immutable.asMutable(state.stateReducer.states, { deep: true });
// export const selectRolesLookup = state => {
//   const { roles } =  state.rolesReducer;
//     return roles ? roles.filter(x => x.isActive).reduce((obj, item) => {
//         obj[item.id] = item.code
//         return obj
//       }, {}) : [];   
// }

export const getLoading = state => state.stateReducer.isLoading;