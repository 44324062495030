import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Chart from 'chart.js';

export default class HistoricalPriceChart extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
    };

    state = {};

    componentDidMount = () => {
        const {
            data,
        } = this.props;

        var ctx = document.getElementById('myChart').getContext('2d');
        this.chartInstance = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: data.map(i => moment(i.date).format('DD/MM/YYYY')),
                datasets: [{
                    label: 'Precio',
                    data: data.map(i => i.price),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    render() {
        return (
            <div>
                <canvas id="myChart" width="400" height="400" />
            </div>
        );
    }
}