import API from './Api';

class RolesService extends API {
  constructor(controller) {
    super(controller);
  }

  getValidEditRoleId = roleId => {
    return this.API.put(`${this.controller}/getvalideditroleid`, { roleId });
  };
}

let service = new RolesService('roles');

export default service;
