import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress,
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table } from 'components';

//Services
import vehiclesService from "services/vehiclesService";

//Assets
import styles from './styles';
import { WithNotifications } from 'hoc';

class Vehicles extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        items: [],
        date: Moment().startOf('day').toDate(),
        tab: 0,
        plasticBoxes: "",
        details: []
    };

    columns = [
        {
            title: "ID",
            field: "id",
            editable: 'never',
        },
        {
            title: "Marca",
            field: 'brand'
        },
        {
            title: "Modelo",
            field: 'model'
        },
        {
            title: "Año",
            field: 'year',
            type: 'numeric'
        },
        {
            title: "Placas",
            field: 'plate'
        },
        {
            title: "Num de camión",
            field: 'transportCode'
        },
        {
            title: "Tipo de transporte",
            field: 'transportType'
        },
        {
            title: "Aseguradora",
            field: 'insuranceCompany'
        },
        {
            title: "Póliza de seguro",
            field: 'insurancePolicy'
        },
        {
            title: "Permiso SCT",
            field: 'permitNo'
        },
    ];

    componentDidMount = () => {
        this.getData();
    }

    getData = async () => {
        const response = await vehiclesService.getAll();
        if (response.ok && response.data.status != "Fail") {
            this.setState({ items: response.data.data });
        }
    }

    previousFocus = (index) => {
        const prevFocus = index - 1;

        if (prevFocus < 0) {
            return;
        }

        if (this[`input${prevFocus}`] != null) {
            const input = this[`input${prevFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.previousFocus(prevFocus);
        }
    }

    nextFocus = (index) => {
        let {
            details
        } = this.state;

        let nextFocus = index + 1;

        if (nextFocus > details.length) {
            return;
        }

        if (this[`input${nextFocus}`] != null) {
            const input = this[`input${nextFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.nextFocus(nextFocus);
        }
    }

    getTitle = () => {
        return "Vehiculos";
    }

    save = async (newData) => {
        const response = await vehiclesService.save(newData);

        if (!response.ok) {
            this.props.addNotification({ message: 'No se pudo guardar información', options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: 'Información guardada', options: { variant: "success" } });
        this.getData();
    }

    update = async (newData) => {
        const response = await vehiclesService.save(newData);

        if (!response.ok) {
            this.props.addNotification({ message: 'No se pudo guardar información', options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: 'Información guardada', options: { variant: "success" } });
        this.getData();
    }

    delete = async (item) => {
        const response = await vehiclesService.delete(item.id);

        if (!response.ok) {
            this.props.addNotification({ message: 'No se pudo borrar', options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: 'Vehiculo borrado exitosamente', options: { variant: "success" } });
        this.getData();
    }

    renderTable = () => {
        const {
            items,
            loading
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        isLoading={loading}
                        columns={this.columns}
                        items={items}
                        options={{
                            pageSize: 10,
                            search: false
                            // selection: copiyng ? false : true
                        }}
                        editable={{
                            onRowAdd: this.save,
                            onRowUpdate: this.update,
                            onRowDelete: this.delete,
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {
                                    isLoading
                                        ? <CircularProgress />
                                        : this.renderTable()
                                }
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default WithNotifications(withStyles(styles)(Vehicles));