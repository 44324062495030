import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/**
 * Actions -Action Creators
 */
export const { Types, Creators } = createActions(
  {
    userGroupListRequest: [],
    userGroupListSuccess: ['userGroups'],
    userGroupListError: ['error'],
    setLoading: ['isLoading']
  },
  {}
);

export const UserGroupTypes = Types;
export const UserGroupCreators = Creators;

/**
 * Redux
 */
// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  userGroups: [],
  isLoading: false
});

export const onUserGroupListSuccess = (state, { userGroups }) =>
  state.merge({ userGroups });
export const onSetLoading = (state, { isLoading }) =>
  state.merge({ isLoading });

//map our actions types to our reducer functions
export const HANDLERS = {
  [UserGroupTypes.USER_GROUP_LIST_SUCCESS]: onUserGroupListSuccess,
  [UserGroupTypes.SET_LOADING]: onSetLoading
};

// Hookup reducers to types
export default createReducer(INITIAL_STATE, HANDLERS);

/**
 * Selectors
 */
export const selectUserGroups = state =>
  Immutable.asMutable(state.userGroupReducer.userGroups, { deep: true });
export const getLoading = state => state.userGroupReducer.isLoading;
