import EntityBase from '../EntityBase';

class Task extends EntityBase {
  constructor(obj) {
    if (!obj) obj = {};
    super(obj);
    this.subCategoryId = obj.subCategoryId ? obj.subCategoryId : undefined;
    this.taskTypeId = obj.taskTypeId ? obj.taskTypeId : undefined;
    this.taskTypeCode = obj.taskTypeCode ? obj.taskTypeCode : undefined;
    this.subCategoryCode = obj.subCategoryCode
      ? obj.subCategoryCode
      : undefined;
    this.isActive = obj.isActive ? obj.isActive : false;
    this.isRequired = obj.isRequired ? obj.isRequired : false;
    this.companyId = obj.companyId ? obj.companyId : undefined;
  }
}

export default Task;
