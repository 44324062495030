//import { create, SERVER_ERROR } from 'apisauce';
import { create } from 'apisauce';
import { ResponseStatus } from '../constants';
import store from 'stores';

class API {
	constructor(controller) {
		this.API = create({
			baseURL: window.env.API_URL
			//baseURL: 'http://localhost:65442/'
		});

		this.validResponse = response => {
			const result = response.status != null && response.status != ResponseStatus.fail;
			return result;
		};

		this.API.addResponseTransform(response => {
			if (response.status == 401 || (response.problem == "NETWORK_ERROR" && response.status == null)) {
				delete localStorage.appUser;
				window.location = '/sign-in';
			} else if (!this.validResponse(response)) {
				response.ok = false;
				response.problem = '';
			}
		});

		this.API.addRequestTransform(request => {
			const state = store ? store.getState() : null;
			if (state && state.userReducer.token && state.userReducer.token.token) {
				request.headers[
					'Authorization'
				] = `Bearer ${state.userReducer.token.token}`;
			}
		});

		this.controller = controller;

		this.getById = id => {
			return this.API.get(`${this.controller}/${id}`);
		};

		this.getAll = () => {
			return this.API.get(`${this.controller}`);
		};

		this.getAllSimple = () => {
			return this.API.get(`${this.controller}/all`);
		};

		this.create = item => {
			return this.API.post(`${this.controller}`, item);
		};

		this.update = item => {
			return this.API.put(`${this.controller}/${item.id}`, item);
		};

		this.delete = id => {
			return this.API.delete(`${this.controller}/${id}`);
		};
	}
}

export default API;
