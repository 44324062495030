import { takeLatest, call, put } from 'redux-saga/effects';
import { TaskTypes, TaskCreators } from 'redux/TaskRedux';
import taskService from 'services/taskService';
import Task from 'contract/responses/Task';
import { MessageOperation } from '../constants';
import { getAll as getTaskTypes } from './taskTypeSagas';
import { getByCategoryId as getSubcategories } from './subCategorySagas';
import { getAll as getCategories } from './categorySagas';

export function* getTasks() {
  yield put(TaskCreators.setLoading(true));
  yield* getCategories(false);
  yield* getTaskTypes(false);
  yield* getAll(false);
  yield put(TaskCreators.setLoading(false));
}

export function* getAll(showLoading) {
  if (showLoading) yield put(TaskCreators.setLoading(true));

  const response = yield call(taskService.getAll);

  if (response.ok) {
    yield put(
      TaskCreators.taskGetListSuccess(
        response.data.data.map(item => new Task(item))
      )
    );
  } else {
    yield put(TaskCreators.taskSetMessage(MessageOperation.error));
  }

  if (showLoading) yield put(TaskCreators.setLoading(false));
}

export function* getById({ id }) {
  yield put(TaskCreators.setLoading(true));

  const response = yield call(taskService.getById, id);
  if (response.ok) {
    let item = new Task(response.data.data);
    yield put(TaskCreators.taskGetSuccess(item));
  } else {
    yield put(TaskCreators.taskSetMessage(MessageOperation.error));
  }

  yield put(TaskCreators.setLoading(false));
}

export function* getByTaskTypeId({ id }) {
  yield put(TaskCreators.setLoading(true));
  const response = yield call(taskService.getByTaskTypeId, id);
  if (response.ok) {
    yield put(TaskCreators.taskGetByTaskTypeIdSuccess(response.data.data));
    yield put(TaskCreators.taskGetSuccess(response.data.data[0]));
  } else {
    yield put(TaskCreators.taskSetMessage(MessageOperation.error));
  }

  yield put(TaskCreators.setLoading(false));
}

export function* getSubCategories({ id, categoryId }) {
  yield put(TaskCreators.setLoading(true));
  yield* getSubcategories({ id: categoryId, showLoading: false });
  yield* getByCategoryId({ id, categoryId }, false);
  yield put(TaskCreators.setLoading(false));
}

export function* getBySubCategoryId({ id }, showLoading = true) {
  if (showLoading) yield put(TaskCreators.setLoading(true));
  const response = yield call(taskService.getBySubCategoryId, id);
  if (response.ok) {
    yield put(TaskCreators.taskGetBySubCategoryIdSuccess(response.data.data));
  } else {
    yield put(TaskCreators.taskSetMessage(MessageOperation.error));
  }
  if (showLoading) yield put(TaskCreators.setLoading(false));
}

export function* getByCategoryId({ id, categoryId }, showLoading = true) {
  if (showLoading) yield put(TaskCreators.setLoading(true));
  const response = yield call(taskService.getByCategoryId, { id, categoryId });
  if (response.ok) {
    yield put(TaskCreators.taskGetByCategoryIdSuccess(response.data.data));
  } else {
    yield put(TaskCreators.taskSetMessage(MessageOperation.error));
  }
  if (showLoading) yield put(TaskCreators.setLoading(false));
}

export function* create({ taskType }) {
  yield put(TaskCreators.setLoading(true));
  const response = yield call(taskService.create, taskType);
  if (response.ok) {
    let newTask = new Task(response.data.data);
    yield put(
      TaskCreators.taskCreateSuccess(newTask, MessageOperation.success)
    );
    yield* getById({ id: newTask.id });
  } else {
    yield put(TaskCreators.taskSetMessage(MessageOperation.error));
  }

  yield put(TaskCreators.setLoading(false));
}

export function* update({ taskType }) {
  yield put(TaskCreators.setLoading(true));

  const response = yield call(taskService.update, taskType);

  if (response.ok) {
    yield put(
      TaskCreators.taskUpdateSuccess(taskType, MessageOperation.success)
    );
    yield* getById({ id: taskType.id });
  } else {
    yield put(TaskCreators.taskSetMessage(MessageOperation.error));
  }

  yield put(TaskCreators.setLoading(false));
}

export function* deleteItem({ id }) {
  yield put(TaskCreators.setLoading(true));

  const response = yield call(taskService.delete, id);

  if (response.ok) {
    yield put(TaskCreators.taskDeleteSuccess(id, MessageOperation.success));
  } else {
    yield put(TaskCreators.taskSetMessage(MessageOperation.error));
  }

  yield put(TaskCreators.setLoading(false));
}

export function* saveMultiple({ task }) {
  yield put(TaskCreators.setLoading(true));

  const response = yield call(taskService.saveMultiple, task);
  if (response.ok) {
    yield put(
      TaskCreators.taskSaveMultipleSuccess(task, MessageOperation.success)
    );
    yield* getByTaskTypeId({ id: task.taskTypeId });
  } else {
    yield put(TaskCreators.taskSetMessage(MessageOperation.error));
  }
  yield put(TaskCreators.setLoading(false));
}

/**
 * sagas
 */
function* taskSagas() {
  yield takeLatest(TaskTypes.TASK_SAVE_MULTIPLE_REQUEST, saveMultiple);
  yield takeLatest(TaskTypes.TASK_GET_BY_TASK_TYPE_ID_REQUEST, getByTaskTypeId);
  yield takeLatest(TaskTypes.TASK_GET_BY_CATEGORY_ID_REQUEST, getSubCategories);
  yield takeLatest(TaskTypes.TASK_GET_LIST_REQUEST, getTasks);
  yield takeLatest(TaskTypes.TASK_GET_REQUEST, getById);
  yield takeLatest(TaskTypes.TASK_CREATE_REQUEST, create);
  yield takeLatest(TaskTypes.TASK_UPDATE_REQUEST, update);
  yield takeLatest(TaskTypes.TASK_DELETE_REQUEST, deleteItem);
}

export default taskSagas;
