import { takeLatest, call, put } from 'redux-saga/effects';
import { DeparmentTypes, DeparmentCreators } from '../redux/DeparmentRedux';
import deparmentService from '../services/deparmentService';
import Deparment from '../contract/responses/Deparment';

export function* getAll(displayMessage = true) {
  if (displayMessage) yield put(DeparmentCreators.setLoading(true));

  const response = yield call(deparmentService.getAll);

  if (response.ok) {
    yield put(
      DeparmentCreators.deparmentListSuccess(
        response.data.data.map(item => new Deparment(item))
      )
    );
  } else {
    yield put(DeparmentCreators.deparmentListFailure(response.problem));
  }

  if (displayMessage) yield put(DeparmentCreators.setLoading(false));
}

/**
 * sagas
 */
function* deparmentSagas() {
  yield takeLatest(DeparmentTypes.DEPARMENT_LIST_REQUEST, getAll);
}

export default deparmentSagas;
