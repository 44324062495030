import API from "./Api";

class PriorityService extends API {
    constructor(controller){
        super(controller);
    }
}

let service = new PriorityService("priorities");

export default service;