import API from './Api';

class WorkItemTaskService extends API {
  constructor(controller) {
    super(controller);
  }

  getByWorkItemId = id => {
    return this.API.get(`${this.controller}/workitem/${id}`);
  };
}

let service = new WorkItemTaskService('workitemtask');

export default service;
