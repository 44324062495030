import API from './Api';

class DeliveriesService extends API {
    constructor(controller) {
        super(controller);
    }

    search = (startDate, endDate, vehicleId, driverId, routeId) => {
        return this.API.get(`${this.controller}/search?startDate=${startDate.toJSON()}&endDate=${endDate.toJSON()}&vehicleId=${vehicleId || 0}&driverId=${driverId || 0}&routeId=${routeId == null ? '' : routeId}`);
    };

    save = model => {
        return this.API.post(`${this.controller}`, model);
    };

    updateDelivery = (id, signDate) => {
        return this.API.put(`${this.controller}/${id}`, { deliveryId: id, signDate });
    }
}

let service = new DeliveriesService('deliveries');

export default service;
