import { call, put } from 'redux-saga/effects';
import { subCategoryAttachmentConfigCreators } from 'redux/SubCategoryAttachmentConfigRedux';
import subCategoryAttachmentConfigService from 'services/subCategoryAttachmentConfigService';
import { MessageOperation } from '../constants';

export function* subCategoryAttachmentGetByAttachmentConfigId({ id }) {
  const response = yield call(
    subCategoryAttachmentConfigService.getByAttachmentConfigId,
    id
  );

  if (response.ok) {
    yield put(
      subCategoryAttachmentConfigCreators.subCategoryAttachmentConfigGetByAttachmentConfigIdSuccess(
        response.data.data
      )
    );
  } else {
    yield put(
      subCategoryAttachmentConfigCreators.subCategoryAttachmentConfigGetByAttachmentConfigIdFailure(
        MessageOperation.error
      )
    );
  }
}
