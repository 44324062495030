import API from './Api';

class EntityTypeService extends API {
  constructor(controller) {
    super(controller);
  }
}

let service = new EntityTypeService('entitytypes');

export default service;
