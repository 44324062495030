import { takeLatest, call, put, all } from "redux-saga/effects";
import { getAll as getCompanyLocations } from "./companyLocationSagas";
import { SubCategoryLocationCreators, SubCategoryLocationTypes } from "redux/SubCategoryLocationRedux";
import subCategoryLocationService from "services/subCategoryLocationService";
import { MessageOperation } from "../constants";

export function* subCategoryLocationCatalogs() {
    yield* getCompanyLocations();
}

export function* subCategoryLocationCreateMultiple({ items }) {
    if (!items.length)
        return;

    const subCategoryId = items[0].subCategoryId;

    yield put(SubCategoryLocationCreators.setLoading(true));
    const response = yield call(subCategoryLocationService.saveMultiple, items);

    if (response.ok) {
        yield subCategoryLocationGetBySubCategoryId({ subCategoryId });
        yield put(SubCategoryLocationCreators.subCategoryLocationCreateSuccess(MessageOperation.success));
    } else {
        yield put(SubCategoryLocationCreators.subCategoryLocationCreateFailure(response.problem));
    }

    yield put(SubCategoryLocationCreators.setLoading(false));
}

export function* subCategoryLocationGetByOwnerId({ subCategoryId, ownerId }) {
    yield put(SubCategoryLocationCreators.setOwnerLoading(true));
    const response = yield call(subCategoryLocationService.getByOwnerId, subCategoryId, ownerId);

    if (response.ok) {
        yield put(SubCategoryLocationCreators.subCategoryLocationGetByOwnerIdSuccess(response.data.data, ownerId));
    } else {
        yield put(SubCategoryLocationCreators.subCategoryLocationGetByOwnerIdFailure(response.problem));
    }

    yield put(SubCategoryLocationCreators.setOwnerLoading(false));
}

export function* subCategoryLocationGetBySubCategoryId({ subCategoryId }) {
    const response = yield call(subCategoryLocationService.getBySubCategoryId, subCategoryId);

    if (response.ok && response.data.data && Array.isArray(response.data.data)) {
        yield put(SubCategoryLocationCreators.subCategoryLocationGetBySubCategoryIdSuccess(response.data.data));
    } else {
        yield put(SubCategoryLocationCreators.subCategoryLocationGetBySubCategoryIdFailure(MessageOperation.error));
    }
}

/**
 * subCategoryLocationSagas 
 */
function* subCategoryLocationSagas() {
    yield all([
        takeLatest(SubCategoryLocationTypes.SUB_CATEGORY_LOCATION_CATALOGS_REQUEST, subCategoryLocationCatalogs),
        takeLatest(SubCategoryLocationTypes.SUB_CATEGORY_LOCATION_CREATE_REQUEST, subCategoryLocationCreateMultiple),
        takeLatest(SubCategoryLocationTypes.SUB_CATEGORY_LOCATION_GET_BY_OWNER_ID_REQUEST, subCategoryLocationGetByOwnerId),
        takeLatest(SubCategoryLocationTypes.SUB_CATEGORY_LOCATION_GET_BY_SUB_CATEGORY_ID_REQUEST, subCategoryLocationGetBySubCategoryId)
    ]);
}

export default subCategoryLocationSagas;