import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';


// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, TextField } from '@material-ui/core';

// Shared components
import {
	Portlet,
	PortletHeader,
	PortletLabel,
	PortletContent,
	PortletFooter
} from 'components';

// Component styles
import styles from './styles';

const states = [
	{
		value: 'alabama',
		label: 'Alabama'
	},
	{
		value: 'new-york',
		label: 'New York'
	},
	{
		value: 'san-francisco',
		label: 'San Francisco'
	}
];

class Account extends Component {
	static propTypes = {
		classes: PropTypes.object.isRequired,
		className: PropTypes.string,
		history: PropTypes.object,
	};

	state = {
		firstName: 'John',
		lastName: 'Doe',
		email: 'contact@devias.io',
		phone: '',
		state: 'Alabama',
		country: 'USA'
	};

	handleChange = e => {
		this.setState({
			state: e.target.value
		});
	};

	render() {
		const { classes, className, history, ...rest } = this.props;
		const { firstName, lastName, phone, state, country, email } = this.state;
		const rootClassName = classNames(classes.root, className);

		return (
			<Portlet
				{...rest}
				className={rootClassName}
			>
				<PortletHeader>
					<PortletLabel
						subtitle="The information can be edited"
						title="Profile"
					/>
				</PortletHeader>
				<PortletContent noPadding>
					<form
						autoComplete="off"
						noValidate
					>
						<div className={classes.field}>
							<TextField
								className={classes.textField}
								helperText="Please specify the first name"
								label="First name"
								margin="dense"
								required
								value={firstName}
								variant="outlined"
							/>
							<TextField
								className={classes.textField}
								label="Last name"
								margin="dense"
								required
								value={lastName}
								variant="outlined"
							/>
						</div>
						<div className={classes.field}>
							<TextField
								className={classes.textField}
								label="Email Address"
								margin="dense"
								required
								value={email}
								variant="outlined"
							/>
							<TextField
								className={classes.textField}
								label="Phone Number"
								margin="dense"
								type="number"
								value={phone}
								variant="outlined"
							/>
						</div>
						<div className={classes.field}>
							<TextField
								className={classes.textField}
								label="Select State"
								margin="dense"
								onChange={this.handleChange}
								required
								select
								SelectProps={{ native: true }}
								value={state}
								variant="outlined"
							>
								{
									states.map(option => (
										<option
											key={option.value}
											value={option.value}
										>
											{option.label}
										</option>
									))
								}
							</TextField>
							<TextField
								className={classes.textField}
								label="Country"
								margin="dense"
								required
								value={country}
								variant="outlined"
							/>
						</div>
					</form>
				</PortletContent>
				<PortletFooter className={classes.portletFooter}>
					<Button
						color="primary"
						variant="contained"
					>
						Guardar
					</Button>
					<Button
						color="secondary"
						variant="contained"
						style={{ marginLeft: '10px' }}
						onClick={() => history.push('/change-password')}
					>
						Cambiar contraseña
					</Button>
				</PortletFooter>
			</Portlet>
		);
	}
}

export default compose(
	withRouter,
	withStyles(styles)
)(Account);
