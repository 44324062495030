import { takeLatest, call, put, all } from 'redux-saga/effects';
import { SubCategoryTypes, SubCategoryCreators } from 'redux/SubCategoryRedux';
import subCategoriesService from 'services/subCategoriesService';
import { getAll as getPriorities } from 'sagas/prioritySagas';
import { getAll as getUserGroups } from 'sagas/userGroupSagas';
import { MessageOperation } from '../constants';

// eslint-disable-next-line no-unused-vars
export function* getSubcategoryCatalogs({ id }) {
  yield put(SubCategoryCreators.setLoading(true));
  yield* getUserGroups(false);
  yield* getPriorities();
  yield* getByCategoryId({ id, showLoading: false });
  yield put(SubCategoryCreators.setLoading(false));
}

export function* getByResponsible() {
  const response = yield call(subCategoriesService.getByResponsible);

  if (response.ok) {
    yield put(
      SubCategoryCreators.subCategoryGetByCategoryIdSuccess(response.data.data)
    );
  } else {
    yield put(
      SubCategoryCreators.subCategorySetMessage(MessageOperation.error)
    );
  }
}

export function* getByCategoryId({ id, showLoading }) {
  if (showLoading) yield put(SubCategoryCreators.setLoading(true));

  const response = yield call(subCategoriesService.getByCategoryId, id);
  if (response.ok) {
    yield put(
      SubCategoryCreators.subCategoryGetByCategoryIdSuccess(response.data.data)
    );
  } else {
    yield put(
      SubCategoryCreators.subCategorySetMessage(MessageOperation.error)
    );
  }
  if (showLoading) yield put(SubCategoryCreators.setLoading(false));
}

export function* getAll(showLoading = true) {
  if (showLoading) yield put(SubCategoryCreators.setLoading(true));

  //GET support catalogs for subcategry detail
  yield* getPriorities(false);

  const response = yield call(subCategoriesService.getAll);

  if (response.ok) {
    yield put(SubCategoryCreators.subCategoryListSuccess(response.data.data));
  } else {
    yield put(
      SubCategoryCreators.subCategorySetMessage(MessageOperation.error)
    );
  }
  if (showLoading) yield put(SubCategoryCreators.setLoading(false));
}

export function* getById({ id }) {
  yield put(SubCategoryCreators.setLoading(true));

  const response = yield call(subCategoriesService.getById, id);

  if (response.ok) {
    yield put(
      SubCategoryCreators.subCategoryGetSuccess(
        response.data.data,
        MessageOperation.success
      )
    );
  } else {
    yield put(
      SubCategoryCreators.subCategorySetMessage(MessageOperation.error)
    );
  }

  yield put(SubCategoryCreators.setLoading(false));
}

export function* create({ item }) {
  yield put(SubCategoryCreators.setLoading(true));

  const response = yield call(subCategoriesService.create, item);

  if (response.ok) {
    yield put(
      SubCategoryCreators.subCategoryCreateSuccess(
        { ...response.data.data, ownerName: item.ownerName },
        MessageOperation.success
      )
    );
  } else {
    yield put(
      SubCategoryCreators.subCategorySetMessage(MessageOperation.error)
    );
  }

  yield put(SubCategoryCreators.setLoading(false));
}

export function* update({ item }) {
  yield put(SubCategoryCreators.setLoading(true));

  const response = yield call(subCategoriesService.update, item);

  if (response.ok) {
    yield put(
      SubCategoryCreators.subCategoryUpdateSuccess(
        item,
        MessageOperation.success
      )
    );
  } else {
    yield put(
      SubCategoryCreators.subCategorySetMessage(MessageOperation.error)
    );
  }

  yield put(SubCategoryCreators.setLoading(false));
}

export function* deleteItem({ id }) {
  yield put(SubCategoryCreators.setLoading(true));

  const response = yield call(subCategoriesService.delete, id);

  if (response.ok) {
    yield put(
      SubCategoryCreators.subCategoryDeleteSuccess(id, MessageOperation.success)
    );
  } else {
    yield put(
      SubCategoryCreators.subCategorySetMessage(MessageOperation.error)
    );
  }

  yield put(SubCategoryCreators.setLoading(false));
}

/**
 * CategoriesSagas
 */
function* SubCategoriesSagas() {
  yield all([
    takeLatest(SubCategoryTypes.ALL_SUB_CATEGORIES_REQUEST, getAll),
    takeLatest(
      SubCategoryTypes.SUB_CATEGORY_GET_BY_CATEGORY_ID_REQUEST,
      getSubcategoryCatalogs
    ),
    takeLatest(
      SubCategoryTypes.RESPONSIBLE_SUB_CATEGORIES_REQUEST,
      getByResponsible
    ),
    takeLatest(SubCategoryTypes.SUB_CATEGORY_LIST_REQUEST, getAll),
    takeLatest(SubCategoryTypes.SUB_CATEGORY_GET_REQUEST, getById),
    takeLatest(SubCategoryTypes.SUB_CATEGORY_CREATE_REQUEST, create),
    takeLatest(SubCategoryTypes.SUB_CATEGORY_UPDATE_REQUEST, update),
    takeLatest(SubCategoryTypes.SUB_CATEGORY_DELETE_REQUEST, deleteItem)
  ]);
}

export default SubCategoriesSagas;
