import React, { Component, Fragment } from 'react';
import withAuth from 'hoc/WithAuth';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Badge,
  IconButton,
  Popover,
  Toolbar,
  Typography,
  Tooltip
} from '@material-ui/core';

// Material icons
import {
  Menu as MenuIcon,
  //Close as CloseIcon,
  NotificationsOutlined as NotificationsIcon,
  Input as InputIcon,
  SearchOutlined as SearchIcon
} from '@material-ui/icons';

// Custom components
import { NotificationList } from './components';
import { WithNotifications } from '../../../../hoc';

// Component styles
import styles from './styles';

class Topbar extends Component {
  signal = true;

  state = {
    notifications: [],
    notificationsEl: null
  };

  componentDidMount() {
    this.signal = true;
    //this.props.fetchNotifications();
  }

  componentWillUnmount() {
    this.signal = false;
  }

  hasPermission = businessFunctionId => {
    return (
      this.props.permissions &&
      this.props.permissions.find(x => x.bfId == businessFunctionId)
    );
  };

  handleSignOut = () => {
    const { history } = this.props;

    localStorage.removeItem('appUser');
    this.props.setSession(null);
    history.push('/sign-in');
  };

  handleShowNotifications = event => {
    this.setState({
      notificationsEl: event.currentTarget
    });
  };

  handleCloseNotifications = () => {
    this.setState({
      notificationsEl: null
    });
  };

  goToSearch = () => {
    const { history } = this.props;
    history.push('/search-tickets');
  };

  render() {
    const {
      classes,
      className,
      title,
      isSidebarOpen,
      onToggleSidebar,
      notifications
    } = this.props;
    const { notificationsEl } = this.state;

    const rootClassName = classNames(classes.root, className);
    const showNotifications = Boolean(notificationsEl);

    return (
      <Fragment>
        <div className={rootClassName}>
          <Toolbar className={classes.toolbar}>
            <Tooltip title={isSidebarOpen ? 'Cerrar menú' : 'Abrir menú'}>
              <IconButton
                className={classes.menuButton}
                onClick={onToggleSidebar}
                variant="text"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Typography className={classes.title} variant="h4">
              {title}
            </Typography>
            {this.hasPermission(13) ? (
              <Tooltip title="Buscar tickets" aria-label="search" >
                <IconButton
                  className={classes.searchButton}
                  onClick={this.goToSearch}
                >
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <div  className={classes.searchButton}/>
            )}
            <Tooltip title="Alertas" aria-label="alerts">
              <IconButton
                className={classes.notificationsButton}
                onClick={this.handleShowNotifications}
              >
                <Badge
                  badgeContent={notifications.length}
                  color="primary"
                  variant="dot"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Cerrar sesión" aria-label="sing-out">
              <IconButton
                className={classes.signOutButton}
                onClick={this.handleSignOut}
              >
                <InputIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </div>
        <Popover
          anchorEl={notificationsEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={this.handleCloseNotifications}
          open={showNotifications}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <NotificationList
            notifications={notifications}
            onSelect={this.handleCloseNotifications}
          />
        </Popover>
      </Fragment>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.node
};

Topbar.defaultProps = {
  onToggleSidebar: () => {}
};

export default compose(
  withAuth,
  withRouter,
  WithNotifications,
  withStyles(styles)
)(Topbar);
