import EntityBase from '../EntityBase';

class UserGroup extends EntityBase {
  constructor(obj) {
    if (!obj) obj = {};
    super(obj);
    this.companyId = obj.companyId ? obj.companyId : undefined;
    this.code = obj.code ? obj.code : '';
    this.detail = obj.detail ? obj.detail : '';
  }
}

export default UserGroup;
