import React from "react";

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { CircularProgress } from '@material-ui/core';

import styles from "./styles";

const Loader = props => {
	const { classes, className } = props;

	const rootClassName = classNames(classes.root, className);

	return (
		<div className={rootClassName}>
			<div className={classes.progressWrapper}>
				<CircularProgress />
			</div>
		</div>
	);
};

Loader.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	onChange: PropTypes.func
};

export default withStyles(styles)(Loader);